import { useEffect, useState } from 'react'

/* 
Gallery for showing thumbnails in a list
*/

//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { useLocation } from 'react-router-dom';

import * as THREE from 'three';
import { Material, Mesh, MeshBasicMaterial, OrthographicCamera, PlaneBufferGeometry, Renderer, Scene, ShaderMaterial, Texture, Vector2, Vector4, WebGLRenderer, WebGLRenderTarget } from 'three';
//@ts-ignore
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';


class PanZoomQuad {
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private renderer: THREE.WebGLRenderer;
    private controls: OrbitControls;
    private quad: THREE.Mesh;
    private raycaster: THREE.Raycaster;
    private mouse: THREE.Vector2;
    private isDragging: boolean;
  
    constructor() {
        console.log("constructor");
      this.scene = new THREE.Scene();
  
      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.camera.position.z = 5;
  
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      document.body.appendChild(this.renderer.domElement);
  
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load('./assets/images/logo_no_txt.png'); // Replace with the path to your texture
  
      const geometry = new THREE.PlaneGeometry(2, 2);
      const material = new THREE.MeshBasicMaterial({ map: texture });
      this.quad = new THREE.Mesh(geometry, material);
      this.scene.add(this.quad);
  
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableRotate = false; // Disable rotation
      this.controls.enableZoom = true;
      this.controls.enablePan = true;
  
      this.raycaster = new THREE.Raycaster();
      this.mouse = new THREE.Vector2();
      this.isDragging = false;
  
      window.addEventListener('resize', this.onWindowResize.bind(this), false);
      window.addEventListener('mousedown', this.onMouseDown.bind(this), false);
      window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
      window.addEventListener('mouseup', this.onMouseUp.bind(this), false);
  
      this.animate();
    }
  
    private onWindowResize(): void {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
  
    private onMouseDown(event: MouseEvent): void {
      this.updateMousePosition(event);
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObject(this.quad);
      if (intersects.length > 0) {
        this.isDragging = true;
        this.controls.enablePan = false; // Disable panning
      }
    }
  
    private onMouseMove(event: MouseEvent): void {
      if (this.isDragging) {
        this.updateMousePosition(event);
        
      }
      this.checkIntersection();
    }
  
    private onMouseUp(event: MouseEvent): void {
      this.isDragging = false;
      this.controls.enablePan = true; // Re-enable panning
    }
  
    private updateMousePosition(event: MouseEvent): void {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    }
  
    private checkIntersection(): void {
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObject(this.quad);
      if (intersects.length > 0) {
        const intersectPoint = intersects[0].point;
        console.log(`Mouse X: ${intersectPoint.x}, Mouse Y: ${intersectPoint.y}`);
      }
    }
  
    private animate(): void {
      requestAnimationFrame(this.animate.bind(this));
      this.renderer.render(this.scene, this.camera);
    }
  }
  
//   new PanZoomQuad();


function TestZoomPan() {
  const [count, setCount] = useState(0);
  const [gallery_items, setGalleryItems] = useState(new Array());


  //to get state passwed over from parents- they apparently added it back in react router v6
  const location = useLocation();
  const { state } = location;

  if(state)
  console.log(state);


  useEffect(() => {
    

    new PanZoomQuad();
    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount



  return (
    <>
    {/* Test */}
    
    
    </>
  )
}

export default TestZoomPan
