/**
 * For Projects to be displayed
 * Image/ background on top, more info on bottom when hovered
 */

import React from 'react';
import { Card, CardMedia, CardContent, Typography, Avatar, CardActionArea, IconButton, Box } from '@mui/material';
import { styled } from '@mui/system';
import { Delete } from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width:"300px",
  height: '300px', // Adjust height as needed  
  '&:hover .an_overlay': {
    opacity: 1,
  },
 
}));

const StyledCardMedia = styled(CardMedia)({
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  padding: theme.spacing(2),
  opacity: 0,
  transition: 'opacity 0.3s ease',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(1),
}));




function ProjectCard({ project, onClick, DeleteClicked }) {
  return (
    
    <StyledCard>
        
        
        <StyledCardMedia
            image={project.image_path} // Specify the image URL here
            title={project.title}
            onClick={onClick}
            sx={{cursor:"pointer"}}
        >
            {
                (project.image_path.substring(project.image_path.length-4)=="null")&&(
                    <svg style={{width:"95%", padding:"5%"}} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <linearGradient x1="50" y1="40" x2="50" y2="100" id="Gradient1" gradientUnits="userSpaceOnUse">
                        <stop style={{ stopColor: '#ccc', stopOpacity: 1 }} offset="0" />
                        <stop style={{ stopColor: '#000000', stopOpacity: 1 }} offset="0.7" />
                      </linearGradient>
                      <linearGradient x1="50" y1="0" x2="50" y2="60" id="Gradient2" gradientUnits="userSpaceOnUse">
                        <stop style={{ stopColor: '#301D00', stopOpacity: 1 }} offset="0.2" />
                        <stop style={{ stopColor: '#FFAB00', stopOpacity: 1 }} offset="1" />
                      </linearGradient>
                    </defs>
                    <path style={{ fill: '#eee', stroke: '#999', strokeWidth: 1 }} d="M 2,23 77,6 94,75 18,94 z" />
                    <path style={{ fill: 'url(#Gradient1)', stroke: '#444', strokeWidth: 1 }} d="M 8,27 73,12 88,71 22,88 z" />
                    <path style={{ fill: '#eee', stroke: '#999', strokeWidth: 1 }} d="m 19,12 77,0 0,74 -77,0 z" />
                    <path style={{ fill: 'url(#Gradient2)', stroke: '#444' }} d="m 25,18 65,0 0,62 -65,0 z" />
                    <path style={{ fill: 'url(#Gradient1)', stroke: '#666' }} d="m 25,48 0,32 65,0 0,-45 C 90,35 84,51 77,54 68,58 64,52 61,46 57,54 56,58 50,60 44,62 38,45 36,38 32,43 29,47 25,48 z" />
                    <path style={{ fill: '#FF3D3D', fillOpacity: 0.6, stroke: '#730000', strokeWidth: 2 }} d="M 53,44 44,53 61,71 44,88 53,97 71,80 88,97 96,88 80,71 97,52 89,44 71,61 z" />
                  </svg>
                )
            }
            {/* <p>{project.image_path.substring(project.image_path.length-4)}</p> */}
           
        </StyledCardMedia>
        
        <Overlay className='an_overlay'
        sx={{pointerEvents:"none"}}
        >
            <Typography variant="h6" gutterBottom>
            {project.title}
            </Typography>
            <Typography variant="body2" component="p">
            {project.desc}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledAvatar src={project.author_avatar_url} alt={project.author} />
                <Typography variant="body2" component="p">
                    By {project.author}
                </Typography>


                <Box
                    sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    pointerEvents: 'auto', // Enable pointer events for the delete button
                    }}
                >
                    <IconButton color="secondary" aria-label="delete" onClick={()=>{console.log("delete clicked");DeleteClicked(project);}}>
                        <Delete fontSize='large'/>
                    </IconButton>
                </Box>
            </div>
        </Overlay>
        
      
    </StyledCard>
    
  );
}

export default ProjectCard;
