import { useEffect, useState } from 'react'

/* 
Gallery for showing thumbnails in a list
*/

//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { useLocation } from 'react-router-dom';
import HeaderAndFooter from '../components/Layouts/HeaderAndFooter';


function Profile() {
  const [count, setCount] = useState(0);
  const [gallery_items, setGalleryItems] = useState(new Array());


  //to get state passwed over from parents- they apparently added it back in react router v6
  const location = useLocation();
  const { state } = location;

  if(state)
  console.log(state);


  useEffect(() => {
    

    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount



  return (
    <>
    <HeaderAndFooter>
    
        Profile Route {JSON.stringify(state)}
    
    </HeaderAndFooter>
    
    
    </>
  )
}

export default Profile
