import * as THREE from 'three';
import { OrthographicCamera, Raycaster, Scene, Vector, Vector2 } from 'three';

import BrushSacing from './BrushSpacing';
import ZoomDisplay from './ZoomDisplay';
import Screen from './Screen';
import Input, { Touch } from './Input';
import Helpers from '../Helpers';
import MouseAndTouch from './MouseAndTouch';

/**
 * Based on the Unity version- and based on MouseAndTouch.ts
 */
export default class ZoomPanGeneral
{

    mouse_down_first:Vector2; //for how far we're panned

    mouse_wheel_zoom_in_amount:number = 1.001;
    mouse_wheel_zoom_out_amount:number = 0.999;

    mouse_down = false;
    panning_touch = false;

    zoom_speed_touch = .002;
    zooming_or_panning = false; //from draw_basic

    flip_mouse = false;//for tex2d- seems to need to be flipped for panning
    zoom_fit_screen = 1; //for making it snap near the fit to screen size

    zoom_display:ZoomDisplay;

    width_scaled = 1;
    height_scaled = 1;

    s = 1;

    get tex_scale()
    {
        return this.s;
    }
    set tex_scale(value)
    {

        this.s = value;
        //also should adjust the rect drawn
        this.width_scaled = this.canvas_size_x * this.s;
        this.height_scaled = this.canvas_size_y * this.s;

        if (this.scaleChangedDelegate != null)
            this.scaleChangedDelegate(this.s);

    }





    tex_off:Vector2 = new Vector2(0, 0);

    get tex_offset(){ return this.tex_off; }
    set tex_offset(value)
    {

        this.tex_off.x = value.x;
        this.tex_off.y = value.y;

        if (this.offsetChangedDelegate != null)
            this.offsetChangedDelegate(this.tex_off);

    }

    //should have delegates that fire when the tex scale or tex offsets change

    //for letting whoever know that the color has changed, so they can do whatever needs to be done
    //from http://www.unitygeek.com/delegates-events-unity/

    //for typescript- don't have to have delegates- can just assign functions as if they were variables
    
    //will be a function that calls all the functions registered / waiting on it 
    scale_changed_callbacks = new Array<any>();
    scaleChangedDelegate(scale)
    {
        this.scale_changed_callbacks.forEach(callback => {
            callback(scale);
        });
    }

    offset_changed_callbacks = new Array<any>();
    offsetChangedDelegate(tex_offset)
    {
        this.offset_changed_callbacks.forEach(callback => {
            callback(tex_offset);
        });
    }

    AddScaleChangedDelegate(a_callback, self)
    {
        this.scale_changed_callbacks.push(a_callback.bind(self));
    }
    AddOffsetChangedDelegate(a_callback,self)
    {
        this.offset_changed_callbacks.push(a_callback.bind(self));
    }

    canvas_size_x=100;
    canvas_size_y=100;

    constructor()
    {
        
    }
    New(canvas_size_x:number, canvas_size_y:number)
    {
        this.zoom_display = ZoomDisplay.GetInstance();
        this.zoom_display.AddZoomChangedDelegate(this.SetZoom);
        this.canvas_size_x = canvas_size_x;
        this.canvas_size_y = canvas_size_y;
        //should scale it up to take up 100% of the height or width, whichever one is smaller

        if (canvas_size_y > canvas_size_x)
        {
            let scale = (Screen.height) / (canvas_size_y);
            this.tex_scale = scale;

            //check if we scaled the wrong way/ for wide screens with square texture
            if (this.tex_scale * canvas_size_x > Screen.width)
            {
                scale = (Screen.width) / (canvas_size_x);
                this.tex_scale = scale;
            }
        }
        else
        {
            let scale = (Screen.width) / (canvas_size_x);
            this.tex_scale = scale;

            //check if we scaled the wrong way/ for wide screens with square texture
            if (this.tex_scale * canvas_size_y > Screen.height)
            {
                scale = (Screen.height) / (canvas_size_y);
                this.tex_scale = scale;
            }
        }

        this.zoom_fit_screen = this.tex_scale;
        //also center it
        // Debug.Log("setting square to centered, this width scaled: " + this.width_scaled);
        this.tex_offset = new Vector2(Screen.width * .5 - this.width_scaled * .5, Screen.height * .5 - this.height_scaled * .5);

        //tex_scale = 1;//to reset it to the default
    }

    Update()
    {
        //don't need to do this anymore, since threejs has built in zoom/ pan
        return;

        
        this.UpdateZoom();
        this.UpdatePan();
    }
    UpdateZoom()
    {
        this.UpdateZoomMouse();
        this.UpdateZoomPanTouch();
    }
    UpdateZoomMouse()
    {
        //Debug.Log(""+ Input.mouseScrollDelta.y);
        //check if the middle mouse button has been scrolled
        if (Input.mouseScrollDelta.y != 0) //mousescroll delta is either -1 or +1
        {
            //Debug.Log("UpdateZoomMouse Scroll should work");

            //also have to check if in the window- for some reason Unity works even if in a different window/ outside the game
            if (Input.mousePosition.y > 0 && Input.mousePosition.y < Screen.height && Input.mousePosition.x > 0 && Input.mousePosition.x < Screen.width)
            {
                // console.log(`Input.mouseScrollDelta.y: ${Input.mouseScrollDelta.y}, this.mouse_wheel_zoom_in_amount: ${this.mouse_wheel_zoom_in_amount}`);
                if (Input.mouseScrollDelta.y > 0)
                    this.Zoom(this.mouse_wheel_zoom_in_amount + .02 * Input.mouseScrollDelta.y, false);
                else
                    this.Zoom(this.mouse_wheel_zoom_out_amount + .02 * Input.mouseScrollDelta.y, false);
            }
        }
    }

    /*
     * for zooming in/ out of the texture- from draw basic
     */
    Zoom(diff:number, is_touch:boolean)
    {
        // console.log("ZOOM: need to uncomment zoom, and update without draw_surface to make it more general!");
        let center_of_screen = new Vector2(Screen.width * .5, Screen.height * .5);

        ////find the canvas spot thats on the center of the screen, compared to the canvas
        let  center_point1 = new Vector2((center_of_screen.x - this.tex_offset.x) / this.tex_scale, (center_of_screen.y - this.tex_offset.y) / this.tex_scale);

        if (this.tex_scale + diff <= 0)//make sure scale doesn't go below 0
            diff = 0;

        //actually scaling
        if (!is_touch)
        {
            this.tex_scale *= diff;
            this.tex_scale *= diff;
        }
        else
        {
            this.tex_scale += diff;
            this.tex_scale += diff;
        }

        //sticky zoom- got rid of because too aggressive
        // if (tex_scale > .95 && tex_scale < 1.05)//to have it snap to 100% when near it
        //     tex_scale = 1;

        // if (tex_scale > zoom_fit_screen - .01f && tex_scale < zoom_fit_screen + .01f)//to have it snap to fitted to screen when near it
        //     tex_scale = zoom_fit_screen;
        //Debug.Log("fit to screen: " + zoom_fit_screen);


        ////we know where the point is on the canvas, we just need to center it in the screen 
        let center_point2 = new Vector2(center_point1.x * this.tex_scale, center_point1.y * this.tex_scale);//where it is not on the screen
        this.tex_offset = new Vector2(center_of_screen.x - center_point2.x, center_of_screen.y - center_point2.y);

        let zoom = Math.round(this.tex_scale * 100);

        if (zoom != Math.round(this.zoom_fit_screen * 100))
        this.zoom_display.Open(zoom + "%");
        else
        this.zoom_display.Open(zoom + "% (fit)");

        // console.log("zoom: ", zoom, ", this.tex_scale: ",this.tex_scale);


        this.KeepCanvasOnScreen();
    }

    //for setting the zoom from zoomdisplay
    SetZoom(zoom)
    {
        //backwards of above
        this.tex_scale = zoom/100.0;
        if (zoom != Math.round(this.zoom_fit_screen * 100))
            this.zoom_display.Open(zoom + "%");
        else
            this.zoom_display.Open(zoom + "% (fit)");

        this.KeepCanvasOnScreen();
    }
    Pan(delta:Vector2)
    {
        //this.tex_offset.x += delta.x;

        //flip_mouse
        //this.tex_offset.y -= delta.y;

        this.tex_offset = new Vector2(this.tex_offset.x + delta.x, this.tex_offset.y - delta.y);
        this.KeepCanvasOnScreen();
    }

    KeepCanvasOnScreen()
    {
        let width = this.canvas_size_x * this.tex_scale;
        let height = this.canvas_size_y * this.tex_scale;

        //float percent_max = .1f;//so this was for percent of the texture- should do it based on pizels instead?
        let max_width = 10;
        let max_height = 10;

        let offset_x = this.tex_offset.x;
        let offset_y = this.tex_offset.y;

        if (this.tex_offset.x + width < max_width)
            offset_x = -width + max_width;
        else if (this.tex_offset.x + max_width > Screen.width)
            offset_x = Screen.width - max_width;

        if (this.tex_offset.y + height < max_height)
            offset_y = -height + max_height;
        else if (this.tex_offset.y + max_height > Screen.height)
            offset_y = Screen.height - max_height;


            this.tex_offset = new Vector2(offset_x, offset_y);
    }

    UpdateZoomPanTouch()
    {
        if (Input.touchCount == 2)
        {
            this.zooming_or_panning = true;
            // Store both touches.
            let touchZero:Touch = Input.GetTouch(0);
            let touchOne:Touch = Input.GetTouch(1);

            // Find the position in the previous frame of each touch.
            // let touchZeroPrevPos:Vector2 = touchZero.position - touchZero.deltaPosition;
            // let touchOnePrevPos:Vector2 = touchOne.position - touchOne.deltaPosition;
            //have to do this differently in typescript, since no operator overloading/ can't use minus
            let touchZeroPrevPos:Vector2 = touchZero.position.clone().sub(touchZero.deltaPosition);
            let touchOnePrevPos:Vector2 = touchOne.position.clone().sub(touchOne.deltaPosition);

            // Find the magnitude of the vector (the distance) between the touches in each frame.
            let prevTouchDeltaMag:number = Helpers.Magnitude(touchZeroPrevPos.clone().sub(touchOnePrevPos) );
            let touchDeltaMag:number = Helpers.Magnitude(touchZero.position.clone().sub(touchOne.position) );

            // Find the difference in the distances between each frame.
            let deltaMagnitudeDiff:number = prevTouchDeltaMag - touchDeltaMag;



            //FeedBackText.feedback.Open("UpdateZoomPanTouch:deltaMagnitudeDiff: " + deltaMagnitudeDiff+", ex:"+(1.1/ deltaMagnitudeDiff));
            //draw_basic.Zoom(-deltaMagnitudeDiff * zoom_speed_touch, true);
            let max_distance:number = 350.0;
            if (deltaMagnitudeDiff < 0)
                //draw_basic.Zoom(mouse_wheel_zoom_in_amount, false);
                this.Zoom(1 + (-deltaMagnitudeDiff / max_distance), false);
            else
                this.Zoom(1 - (deltaMagnitudeDiff / max_distance), false);

            let mouse_pos:Vector2 = new Vector2(touchZero.position.x + touchOne.position.x / 2, touchZero.position.y + touchOne.position.y / 2);
            if (!this.panning_touch)
            {
                this.panning_touch = true;
                this.mouse_down_first = mouse_pos;
            }

            let delta:Vector2 = mouse_pos;
            // delta -= mouse_down_first;
            delta.sub(this.mouse_down_first);
            this.Pan(delta);
            this.mouse_down_first = mouse_pos;

            //float min_delta = 10.0f;

            //if (deltaMagnitudeDiff > min_delta || deltaMagnitudeDiff < -min_delta)
            //    draw_basic.Zoom(-deltaMagnitudeDiff * zoom_speed_touch);
            //else
            //{
            //    going to pan instead
            //    use the mid point betyween fingers as the "mouse position

            //    Vector2 mouse_pos = new Vector2(touchZero.position.x + touchOne.position.x / 2, touchZero.position.y + touchOne.position.y / 2);
            //    if (!panning_touch)
            //    {
            //        panning_touch = true;
            //        mouse_down_first = mouse_pos;
            //    }

            //    Vector2 delta = mouse_pos;
            //    delta -= mouse_down_first;
            //    draw_basic.Pan(delta);
            //    mouse_down_first = mouse_pos;
            //}

            //if the delta magnitude is low, we should pan instead
            //lets put debug info on screen for the deltamagnitude
            //draw_basic.feedback.text = "DeltaMag: " + deltaMagnitudeDiff;
        }
        else
        {
            this.panning_touch = false;
            this.zooming_or_panning = false;
        }
    }



    UpdatePan()
    {
        this.UpdatePanMouse();
    }
    UpdatePanMouse()
    {
        if (Input.GetMouseButtonDown(2))//if first down, record the mouse position
        {
            //so record where we first pushed down, and we'll pan it based on the delta
            this.mouse_down_first = Input.mousePosition;
            this.mouse_down = true;
        }

        if (Input.GetMouseButton(2)) //mousescroll delta is either -1 or +1
        {
            //going to have to pan
            let delta:Vector2 = Input.mousePosition;
            delta.sub(this.mouse_down_first);
            this.Pan(delta);
            this.mouse_down_first = Input.mousePosition;
            //Debug.Log()
            //so now we have a delta we can render it off of- right now it won't remember the overall delat though
        }
        else
        {
            if (this.mouse_down)
            {
                //Vector2 delta = Input.mousePosition;
                //delta -= mouse_down_first;
                //offset += delta;
                //draw_basic.Pan(delta);
                this.mouse_down = false;
            }
        }
    }

     GetTextureCoordsFromScreenCoords(mouse_touch:MouseAndTouch):Vector2
    {
        //if mouse if off the surface- just set it to -1
        let tex_coord:Vector2 = new Vector2(-1, -1);

        //check if off texture, and find actual points
        //if (mouse_touch.GetMouseorTouchX() >= this.RectDrawn().x && mouse_touch.GetMouseorTouchX() <= this.RectDrawn().x + this.RectDrawn().width)
        //    if (mouse_touch.GetMouseorTouchY() >= -this.RectDrawn().y && mouse_touch.GetMouseorTouchY() <= -this.RectDrawn().y + this.RectDrawn().height)
        {
            tex_coord.x = mouse_touch.GetMouseorTouchX();
            tex_coord.y = mouse_touch.GetMouseorTouchY();

            //y is flipped- so have to minus the height from the height of the window
            tex_coord.y -= (Screen.height - this.height_scaled);

            //do offset
            tex_coord.x = tex_coord.x - this.tex_offset.x;
            tex_coord.y = tex_coord.y + this.tex_offset.y;

            //scale them to the height and width of the original image size
            tex_coord.x /= this.tex_scale;
            tex_coord.y /= this.tex_scale;
        }
        return tex_coord;
    }
}