import Main from "../main";

export default class Modal
{
    static instance;
    static GetInstance():Modal
    {
        if(Modal.instance==undefined)
        {
            Modal.instance  = new Modal();
            console.log("creating new Modal!!!!")
        }
            

        return Modal.instance;
    }
    element:HTMLElement;

    //for showing loading info
    loading:boolean=false;
    num_dots_shown:number=0;
    max_dots:number=3;
    dot_update_delay:number=200;
    dots_timer:number=0;
    text:HTMLElement;
    original_text:string;
    dots_txt:string="";



    constructor()
    {
        Modal.instance = this;
        this.element= (document.querySelector("#modal")  as HTMLElement);
    }
    Prompt(text)
    {
        this.ShowOneOption(text, "Ok", undefined);
    }
    ShowOneOption(text, btn_text?, callback?)
    {
        this.OpenModal();
        let modal = this.element;

        modal.classList.add("modal_one_btn");

        if(btn_text==undefined)
            btn_text="Ok";

        modal.querySelector("p").innerHTML=text;
        let btn =  modal.querySelector(".btns button:nth-of-type(2)");
        btn.innerHTML=btn_text;
        console.log("button: ", btn);

        if(callback==undefined)
            (btn as HTMLButtonElement).onclick = ()=>{this.CloseModal();}
        else
            (btn as HTMLButtonElement).onclick = ()=>{this.CloseModal();callback();}
        
    }

    ShowTwoOptions(text, btn_yes_txt, btn_no_txt, callback_yes, callback_no)
    {
        this.OpenModal();
        let modal = this.element;

        modal.classList.remove("modal_one_btn");

        modal.querySelector("p").innerHTML=text;

        let btn_no =  modal.querySelector(".btns button:nth-of-type(1)");
        let btn_yes =  modal.querySelector(".btns button:nth-of-type(2)");
        btn_no.innerHTML=btn_no_txt;
        btn_yes.innerHTML=btn_yes_txt;


        if(callback_no==undefined)
            (btn_no as HTMLButtonElement).onclick = ()=>{this.CloseModal();}
        else
            (btn_no as HTMLButtonElement).onclick = ()=>{callback_no();}

        (btn_yes as HTMLButtonElement).onclick = ()=>{this.CloseModal();callback_yes();}
    }
    //to reset stuff when opening modal again
    Reset()
    {
        if(this.loading)
            this.LoadingFinished();
    }
    ShowComfirmation(text, btn_yes_txt, callback_yes)
    {
        this.OpenModal();
        let modal = this.element;

        modal.classList.remove("modal_one_btn");

        modal.querySelector("p").innerHTML=text;

        let btn_no =  modal.querySelector(".btns button:nth-of-type(1)");
        let btn_yes =  modal.querySelector(".btns button:nth-of-type(2)");
        btn_no.innerHTML="Cancel";
        btn_yes.innerHTML=btn_yes_txt;


        (btn_no as HTMLButtonElement).onclick = ()=>{this.CloseModal();}
        (btn_yes as HTMLButtonElement).onclick = ()=>{this.CloseModal();callback_yes();}
    }

    ShowMustBeLoggedIn(callback_yes)
    {
        Modal.GetInstance().ShowTwoOptions("You must be logged in to save online!", "Login","Cancel", callback_yes, undefined);
    }
    ShowLoading(text)
    {
        console.log("Modal: ShowLoading");
        //should show modal showing that something is being done, and an animation to show it is working (such as spinning circle or dots...)

        //should show modal, but hide both buttons so they can't close it while it's doing it's thing
        this.OpenModal();
        this.loading=true;

        
        let modal = this.element;

        this.text=modal.querySelector("p");
        this.original_text=text;
        
        this.text.innerHTML=text;

        //hide both buttons
        // modal.querySelectorAll(".btns button").forEach(e => {
        //     let btn = (e as HTMLElement);
        //     btn.style.display="none";            
        // });
        modal.classList.add("modal_no_btns");

        //add callback to update to Main
        this.update_callback = this.UpdateModal.bind(this);
        Main.GetInstance().AddUpdateCallback(this.update_callback);
    }
    //have to store this, or javascript won't allow us to remove it fromt he main function
    update_callback:any;
    LoadingFinished()
    {
        if(this.loading)
        {
            this.loading=false;
            this.element.classList.remove("modal_no_btns");
            console.log("Loading finished, this: ", this);
            Main.GetInstance().RemoveUpdateCallback(this.update_callback);
            this.CloseModal();
        }
    }
    UpdateModal(delta_ms)
    {
        this.dots_timer+=delta_ms;
        if(this.dots_timer>this.dot_update_delay)
        {
            this.dots_timer=0;
            this.num_dots_shown++;
            this.dots_txt=this.dots_txt+".";
            if(this.num_dots_shown>this.max_dots)
            {
                this.num_dots_shown=0;
                this.dots_txt="";
            }
        }
        
        this.text.innerHTML=(this.original_text+this.dots_txt);
    }

    OpenModal()
    {
        this.Reset();
        //@ts-ignore
        ShowWindows();

        // let modal = (document.querySelector("#modal")  as HTMLElement);
        this.element.style.display="block";
        this.element.style.zIndex="999";
    }
    CloseModal()
    {
        console.log("Modal closed!");
        //@ts-ignore
        HideWindows();
        Modal.GetInstance().element.style.display="none";
    }
}