//just a class for holding information on the current project/ image

export default class Project
{
    image;
    title;
    timestamp;
    desc;
    nsfw;
    constructor(img_obj?:any)
    {
        //from Storage. LoadImageLocally()
        //["title", "desc","nsfw", "timestamp"]);
        if(img_obj!==undefined)
        {
            this.image = img_obj.image;
            this.SetProperties(img_obj.title, img_obj.desc, img_obj.nsfw);
            this.timestamp = img_obj.timestamp;
            console.log("project timestamp : "+this.timestamp);
        }
    }
    SetTimeStamp()
    {
        this.timestamp=Date.now();
    }
    SetProperties(title, desc, nsfw)
    {
        this.title = title;
        this.desc = desc;
        this.nsfw = nsfw;
        if(this.timestamp==undefined)
            this.SetTimeStamp();
    }
}