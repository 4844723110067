import { useEffect, useState } from 'react'

/* 
Gallery for showing thumbnails in a list
*/

//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { useLocation } from 'react-router-dom';
import GoogleSignIn from '../components/GoogleSignIn';
import { Navigate } from 'react-router-dom';
import { userStore } from '../App';



function Login() {
  const [count, setCount] = useState(0);
  const [gallery_items, setGalleryItems] = useState(new Array());


  //to get state passwed over from parents- they apparently added it back in react router v6
  const location = useLocation();
  const { state } = location;

  if(state)
    console.log(state);

//zustland
const userZust = userStore((state) => state.user);


  useEffect(() => {
    

    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount

    
    if(userZust&&userZust.token)
        return (
        <Navigate
        to="/Home" 
        state={{data:{ id: 1, name: 'Example' }}}
        />);



  return (
    <>

    <div className='wrapper'>
        Login Route 
        <GoogleSignIn/>
        {/* {JSON.stringify(state)} */}
    </div>
    
    
    </>
  )
}

export default Login
