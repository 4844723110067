import Storage from "./filesystem/Storage";

export default class UserData
{
    static instance:UserData;
    token:string;
    static GetInstance()
    {
        if(UserData.instance==undefined)
            UserData.instance  = new UserData();

        return UserData.instance;
    }

    logged_in:boolean=false;

    constructor()
    {
        this.token = Storage.GetInstance().LoadValue("token");
        if(this.token==undefined)
        {
            console.log("user not logged in ");
            this.logged_in=false;
        }
        else
        {
            console.log("user IS logged in ");
            this.logged_in=true;
        }
    }
    Login(token)
    {
        this.token=token;
        console.log("user IS logged in ");
        this.logged_in=true;
        Storage.GetInstance().SaveValue("token", token);
    }
}