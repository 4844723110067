import React, { useEffect, useState } from 'react';
import { userStore } from '../App';
import { URL_BACKEND, URL_FRONTEND } from '../CONSTANTS';


//SERVER INFO
// let server = "http://localhost:5000";
/*
    from: https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow#gapi-gis
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function oauthSignIn() 
{
    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
  
    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);
  
    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {'client_id': '828738798751-fpq2tefjs2ed09ru3a0s5au343fopock.apps.googleusercontent.com',
                  'redirect_uri': URL_FRONTEND,//this takes it back to this page, so would be useful for single page apps
                  //brings back: http://localhost:5173/#state=pass-through%20value&access_token=ya29.a0AfB_byCa-JvT2QhaqNHqd5dAfzlyJNKopuZEmmuuNwos5ZkM8KWldpMVob887J3xCLf2ExKvUvPHbTR1PMdOKMXrJhDoyU33tSmwU83gnCnrHsGpAQMWKw5IvjTTDTOC-YWIBUePat_xB57gxXD7VEKom8FBBZELuAaCgYKAdISARESFQHGX2MirQ2z2uMZr4CHxbKftKYPXA0169&token_type=Bearer&expires_in=3599&scope=email%20profile%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile%20openid&authuser=0&prompt=consent
                  // 'redirect_uri': 'http://localhost:5000/GoogleSignIn', //no real advantage to handling it on the server- they also see the get response/ token. Might as well just handle the response on the client, then send the info to the server
                  'response_type': 'token',
                    'scope': 'email profile openid',
                  'include_granted_scopes': 'true',
                  'state': 'pass-through value'};
  
    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }
  
    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }

//create a function that gets called when this component gets mounted
//handles get response from google- which gets placed into the url after we login
function HandleGetResponse()
{
  var fragment = window.location.hash.substring(1); // Exclude leading '#'
  console.log("fragment: ",fragment);
  if(!fragment)
    return;

  var params = {};
  var pairs = fragment.split('&');
  
  for (var i = 0; i < pairs.length; i++) 
  {
      var pair = pairs[i].split('=');
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  let access_token = params["access_token"];
  console.log("access_token is: ", access_token);

  //lets send the access token to our server to create/ login the user
  SendTokenToServer(access_token);

// Print fragment parameters to console
  /* for (var key in params) 
  {
    if (params.hasOwnProperty(key)) 
    {
        console.log(key + ': ' + params[key]);
    }
  } */
}
function SendTokenToServer(access_token)
{
	fetch(URL_BACKEND+"/GoogleSignIn/", 
	{
		method: 'POST',
		headers: {
			'Accept': 'application/json',
      		'Content-Type': 'application/json',
			},
		body: JSON.stringify({ JWT:access_token, test:"some test"})
	})
	.then(response => 
	{
		console.log("response: ", response);
    // Handle response from the backend
    response.json().then(data=>{
      console.log("json response from server: ", data);

      if(data.code==200) //only login if they got the go ahead
      {
        console.log("user is logged in from getting response from google, and then getting token from server");
        setUserZust2(data);
        // userZust.
        
      }
    });
	})
	.catch(error => 
	{
	// Handle error
		console.log("error: ", error);
	});
}

let setUserZust2;

//zustland



function GoogleSignIn() {

    const [count, setCount] = useState(0)
    setUserZust2 = userStore((state) => state.setUser);

    //zustland
    // const user_interface = userStore((state) => state.user_interface);

    useEffect(() => {
      console.log("GOOGLE SIGN IN CALLED!!!!");
    // This function will be called when the component mounts
    // oauthSignIn();
    // SignedIn2=user_interface.SignedIn;

    HandleGetResponse();
    }, []); // Empty array means this effect runs once on mount and clean up on unmount

    return (
        <>
          <div>
            <button onClick={()=>{oauthSignIn()}} >Sign in With Google</button>
           </div>
        </>
      )

}

export default GoogleSignIn;
