import { useEffect, useState } from 'react'
import styles from "./Challenges.module.css";

/* 
Gallery Item- one thumbnail in the gallery
*/
import { SUB_FOLDER, URL_BACKEND } from '../../CONSTANTS';
//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import { Chip, Paper, darken, lighten, useTheme } from '@mui/material';

/*
item comes with properties:
avatar: "imgs/avatar_anon.jpg"
created_at: "2019-07-16T00:40:38.000Z"
desc: "Angry strawberry"
height: 1024
id: 37
name: "Angry strawberry"
nsfw: false
path: "uploads/images/meseeks/6471474.png"
time_elapsed_s: 5
type: 0
updated_at: "2019-07-16T01:03:46.000Z"
user_name: "meseeks"
width: 512
*/
export interface ChallengeItemInterface
{
    challenge_tags:Array<any>

    avatar: "imgs/avatar_anon.jpg"
    created_at: "2019-07-16T00:40:38.000Z"
    desc: "Angry strawberry"
    height: 1024
    id: "37"
    name: "Angry strawberry"
    nsfw: false
    path: "uploads/images/meseeks/6471474.png"
    time_elapsed_s: 5
    type: 0
    updated_at: "2019-07-16T01:03:46.000Z"
    user_name: "meseeks"
    width: 512

}
function ChallengeItem({item}:{item:ChallengeItemInterface}) {
  const [count, setCount] = useState(0);

  const theme = useTheme();

//   const { item } = props;


  useEffect(() => {

    console.log("Challenge item created with item: ", item);
    }, []); // Empty array means this effect runs once on mount and clean up on unmount



  return (
    <>
    <Link to={SUB_FOLDER+`Challenge/${item.id}`} state={{ item: item }}>
    <Paper 
    sx={{maxWidth:"30ch", padding:"20px", boxSizing:"content-box", position:"relative", transition:".2s all", border:1, borderColor:darken(theme.palette.secondary.main, 0.1) ,"&:hover":{transform:"scale(1.01)", border:2, borderColor:darken(theme.palette.secondary.main, 0.1)}, }} elevation={10}>
    {/* go to a route for this challenge, that should show this challenge info + Join or edit challenge btn,  and show a gallery of people's challenges*/}
    {/* <div className={styles.item}> */}
        
            <div >
                <h1>{item.name}</h1>
                <p>{item.desc}</p>
                {
                    item.challenge_tags.map((tag)=>
                    {
                        // material design button instead of html one
                        // return <Button variant="contained" data-id={tag.id} key={tag.id} sx={{margin:"10px"}}>{tag.name}</Button>
                        //material chip/ for tags 
                        return <Chip variant="outlined" label={tag.name} data-id={tag.id} key={tag.id}  onClick={(e)=>{
                            console.log("clicked");
                            // e.stopPropagation(); 
                            e.preventDefault();
                            // e.bubbles=false;

                            return false; }}
                            // sx={{"&:hover":{color:"red", borderColor}}}
                             />
                    })
                }
            </div>
            
        
        {/* <img src={URL_BACKEND+item.path} /> */}
    {/* </div> */}
    </Paper>
    </Link>
    {/* <p>{JSON.stringify(props.item)}</p> */}
    {
      

    }
    
    </>
  )
}

export default ChallengeItem
