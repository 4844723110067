import * as THREE from 'three';
import { Material, Mesh, MeshBasicMaterial, OrthographicCamera, PlaneBufferGeometry, Renderer, Scene, ShaderMaterial, Texture, Vector2, Vector4, WebGLRenderer, WebGLRenderTarget } from 'three';
import Color from './screen_interaction/Color';
import StartThreeJS from './StartThreeJS';
import MainMenu from './UI/MainMenu';
import Rect from './screen_interaction/Rect';

// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
//@ts-ignore
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';


let fragShaderFillTexture = `
uniform vec2 res;//The width and height of our screen
uniform vec4 color_fill;

void main() {
    vec2 pixel = gl_FragCoord.xy / res.xy;
    // gl_FragColor = texture2D( bufferTexture, pixel );
    gl_FragColor=color_fill;
}
`;

let fragShaderOverwriteTexture = `
uniform sampler2D bufferTexture;//Our input texture
uniform vec2 res;//The width and height of our screen
uniform vec4 color_fill;

void main() {
    vec2 pixel = gl_FragCoord.xy / res.xy;
    gl_FragColor = texture2D( bufferTexture, pixel );
    // gl_FragColor.r=0.5;
    // gl_FragColor.g=0.0;
    // gl_FragColor.b=0.0;
}
`;




//from: E:\working_dir\Unity\TheHinterLands Oasis 2019\Assets\Resources\TextureBrushShader2019_03.shader
let fragShader1= `
uniform vec2 res;//The width and height of our screen
uniform sampler2D bufferTexture;//Our input texture
uniform vec2 mouse_pos;

//WAS 200 9/19/21
uniform float _Points_x[26]; //MAX 224 or doesn't work!!!!!
uniform float _Points_y[26]; //MAX 224 or doesn't work!!!!!


//uniform int _Points_Length;
//FOR Older OPENGL ES (on ASUS EPE121) - they require for loops to have a max length to be constant, but allows you to break out early
//https://stackoverflow.com/questions/38986208/webgl-loop-index-cannot-be-compared-with-non-constant-expression
const int _Points_Length =26;

uniform vec4 _BrushColor;
uniform sampler2D _BrushTex;
uniform sampler2D _BrushTex2;

const int BRUSH_SQUARE = 0;
const int BRUSH_CIRCLE = 1;
const int BRUSH_TEXTURE = 2;

uniform int _Brush_type;//brush type- 0 for square, 1, round, 2 from texture, 3- fill whole texture


int _Brush_hardness = 1;//brush type- 0 for square, 1, round, 2 from texture, 3- fill whole texture

//for knowing the aspect ratio of the canvas for making the circle look correctly
uniform float _BrushSizeUV_x_half;
uniform float _BrushSizeUV_y_half;//brush size- in percent of total UV
uniform float _aspect_ratio;
uniform float _brush_aspect_over_canvas_aspect;
uniform float _brush_aspect_ratio;
uniform int _Using_eraser; //0 false, 1 true


uniform float _lock_transparency;//0 false, 1 true

//for testing

uniform float delta;


//from: https://stackoverflow.com/questions/28900598/how-to-combine-two-colors-with-varying-alpha-values
//a01 = (1 - a0)·a1 + a0
//r01 = ((1 - a0)·a1·r1 + a0·r0) / a01
//c0 = new color, c1 = old color
vec4 GetColorCombined(vec4 col_original, vec4 col_brush)
{ 
    float r_new = col_brush.r;
    float g_new = col_brush.g;
    float b_new = col_brush.b;
    float a_new = col_brush.a;

    float one_minus_brush_a = (1.0 - col_brush.a);

    //transparency that works decently, but not well with brushes with alpha values over previous layers
    float alpha_combined = one_minus_brush_a *  col_original.a + col_brush.a;
    r_new = (one_minus_brush_a*col_original.a * col_original.r + col_brush.a*col_brush.r) / alpha_combined;
    g_new = (one_minus_brush_a*col_original.a * col_original.g + col_brush.a*col_brush.g) / alpha_combined;
    b_new = (one_minus_brush_a*col_original.a * col_original.b + col_brush.a*col_brush.b) / alpha_combined;

    a_new = alpha_combined;

    return vec4(r_new, g_new, b_new, a_new);
}


//for drawing multiple points, using a texture as the brush
void DrawMultiPointsFromCircleBrush(inout vec4 col, vec2 i)
{
    float x = i.x;
    float y = i.y;

    // // fixed4 col_brush= _BrushColor;

    vec2 this_pos = vec2(x, y);
    // this_pos.y /= _aspect_ratio; //right size, but wrong position

    for (int i = 0; i < _Points_Length; i++)
    {
        vec2 brush_pt = vec2(_Points_x[i], _Points_y[i]);

        float dist = distance(brush_pt, this_pos);

        if(dist< _BrushSizeUV_x_half)
            {
                
                    
                if (_Brush_hardness == 1)
                {
                    //if manually blending
                    vec4 col_new = GetColorCombined(col, _BrushColor);
                    col.r = col_new.r;
                    col.g = col_new.g;
                    col.b = col_new.b;
                    col.a = col_new.a;

                    

                    // col.r = 1.0;
                    // col.g =  1.0;
                    // col.b =  1.0;
                    // col.a =  1.0;
                }
        //         else
        //         {
        //             float percent = (dist / _BrushSizeUV_x_half);
                    
        //             //for when manually blending 
        //             float4 col_new = GetColorCombined(col, _BrushColor, percent);
        //             col.r = col_new.r;
        //             col.g = col_new.g;
        //             col.b = col_new.b;
        //             col.a = col_new.a;								
        //         }
            }

    }

                    
}



void DrawTexture(inout vec4 col, vec2 i)
{
    float x = i.x;
    float y = i.y;



    //-------then scale it correctly-----------

    
    //so 
    // float brush_aspect =_brush_aspect_ratio;
    // float canvas_aspect = _aspect_ratio;

    float div = _brush_aspect_over_canvas_aspect;

    //2021-10-04- setting it to staticly 1- doesn't seem to need it for aspect ratio scaling like it did before....
    div=1.0;

    // div=0.5;


    for (int i = 0; i < _Points_Length; i++)
    {

        vec2 brush_pt = vec2(_Points_x[i], _Points_y[i]);

        //-----FOR DEBUGGING:
        // if(x>brush_pt.x-_BrushSizeUV_x_half && x< brush_pt.x+_BrushSizeUV_x_half)
        //     if(y>brush_pt.y-_BrushSizeUV_y_half && y< brush_pt.y+_BrushSizeUV_y_half)
        //     {
        //         col.r=1.0;
        //         col.g=1.0;
        //         col.b=1.0;
        //         col.a=1.0;
        //     }

        
        //2021-10-04- commented out to try to get more actual brush position
        // brush_pt.y *= _aspect_ratio;

        brush_pt.x=-(brush_pt.x-_BrushSizeUV_x_half);
        brush_pt.y=-(brush_pt.y-_BrushSizeUV_y_half);

        vec2 offset_uv = vec2(brush_pt.x, brush_pt.y);
    
        vec2 this_pos = vec2(x, y);
        this_pos.x=this_pos.x+offset_uv.x;
        this_pos.y=this_pos.y+offset_uv.y;

        
        
        vec2 uv_brush = vec2(this_pos.x, this_pos.y*div);
        uv_brush.x=uv_brush.x/(_BrushSizeUV_x_half*2.0);
        uv_brush.y=uv_brush.y/(_BrushSizeUV_y_half*2.0);

            
        
        
        if(uv_brush.x>0.0 && uv_brush.x<1.0)
            if(uv_brush.y>0.0 && uv_brush.y<1.0)
            {
                


                vec4 col_brush = texture2D(_BrushTex, uv_brush);

                if (_Using_eraser==1)
                {
                    col_brush.a=1.0-col_brush.a;
                    col.a*=col_brush.a;

                    
                }
                else if(_lock_transparency==1.0 && col.a <0.25)
                {
                    
                    
                }
                else
                {
                    //so it's actually getting here now

                    col_brush.r=_BrushColor.r;
                    col_brush.g=_BrushColor.g;
                    col_brush.b=_BrushColor.b;

                    col_brush.a*=_BrushColor.a;

                    // //gets rid of black alpha mistakes
                    if(col.a!=0.0)
                        col_brush = GetColorCombined( col, col_brush);



                    col=col_brush;


                // col.r=1.0;
                // col.a=uv_brush.x;
                    

                }

                
            }
	
    }

    // col.r=1.0;
    // col.g=1.0;
    // col.b=1.0;
}





void main() 
{
    gl_FragColor.a=1.0;//it has issues drawing if you don't have this initially

    

	// gl_FragColor.r=1;

    vec2 pixel = gl_FragCoord.xy / res.xy;
    gl_FragColor = texture2D( bufferTexture, pixel );

	// gl_FragColor.r += 0.01;

	// if(pixel.x<.5)
	// 	gl_FragColor.g=1.0;
	// if(pixel.y<.5)
	// 	gl_FragColor.b=1.0;





    //for circle brush
    // if (_Brush_type == BRUSH_CIRCLE)
    // {
    //     DrawMultiPointsFromCircleBrush(gl_FragColor, pixel);
    //     // gl_FragColor.r=0.5;
    //     // gl_FragColor.g=0.0;
    //     // gl_FragColor.b=0.0;
    // }

	// 	float brush_radius=.015;



    // gl_FragColor.r=delta;

    // if(_Points_x[0]==0.5)
    //     gl_FragColor.g=1.0;


    // gl_FragColor.r=0.0;
    // gl_FragColor.g=0.0;
    // gl_FragColor.b=0.0;
    // gl_FragColor.a=1.0;

    
    vec4 col_brush = texture2D(_BrushTex, pixel);
    // gl_FragColor=col_brush;


    //for testing a background color for blending- looks cool
    // gl_FragColor.g=col_brush.a;
    // gl_FragColor.r=pixel.x;


    // gl_FragColor.b=col_brush.a;
    // gl_FragColor.r=col_brush.a;
    

    

    

    

    // gl_FragColor.r=1.0;
    // gl_FragColor.g=1.0;
    // gl_FragColor.b=1.0;


    // gl_FragColor.a=pixel.y;


    // gl_FragColor.r=pixel.y;


        // for (int i = 0; i < _Points_Length; i++)
        // {
        //     float pt_x=_Points_x[i];
        //     float pt_y=_Points_y[i];

        //     if(pt_x!=-1.0)
        //         if(pixel.y<pt_y+_BrushSizeUV_y_half && pixel.y>pt_y-_BrushSizeUV_y_half)
        //         if(pixel.x<pt_x+_BrushSizeUV_x_half && pixel.x>pt_x-_BrushSizeUV_x_half)
        //         {
        //             gl_FragColor.r=0.5;
        //             gl_FragColor.g=0.0;
        //             gl_FragColor.b=0.5;
        //         }
        // }

		// if(mouse_pos.y!=-1.0)
		// 	if(pixel.y<mouse_pos.y+brush_radius && pixel.y>mouse_pos.y-brush_radius)
		// 	if(pixel.x<mouse_pos.x+brush_radius && pixel.x>mouse_pos.x-brush_radius)
		// 	{
		// 		gl_FragColor.r=0.5;
		// 		gl_FragColor.g=0.0;
		// 		gl_FragColor.b=0.5;
		// 	}


	// if(mouse_pos.y!=-1)
	// 	if(pixel.y<mouse_pos.y)
	// 	{
	// 		// gl_FragColor.r=0.0;
	// 		// gl_FragColor.g=0.0;
	// 		// gl_FragColor.b=0.0;
	// 	}

    //DRAW TEXTURE BRUSH
    DrawTexture(gl_FragColor, pixel);

 }


`;

let vertShader= `
varying vec3 vUv; 
varying vec2 uv2; 

void main() {
  vUv = position; 
  uv2=uv;

  vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
  
  gl_Position = projectionMatrix * modelViewPosition; 

//   gl_Position.x-=0.3;
  
}
`;

export default class RenderSurface
{

    //2024-07-30 new stuff from Unity to try to get scale/ pan/ working, but not yet implemented below
    private aspect_ratio: number = 1.0; // width/height for the texture, for using in the shader, since it goes from 0-1, even when the texture is a different size
    // so if the texture is 2x as wide as tall, it will still go from 0-1 for both directions, but then that will make something .1% tall different heights and widths

    private xx: number=0;
    private yy: number=0;
    public get x(): number { return this.xx; }
    public set x(value: number) { this.xx = value; this.rect_drawn.x = value + this.offset_x * this.scale; }
    public get y(): number { return this.yy; }
    public set y(value: number) { this.yy = value; this.rect_drawn.y = value + this.offset_y * this.scale; }

    private w: number=512;
    private h: number=512;
    public get width(): number { return this.w; }
    private set width(value: number) { this.w = value; this.aspect_ratio = this.width / this.height; }
    public get height(): number { return this.h; }
    private set height(value: number) { this.h = value; this.aspect_ratio = this.width / this.height; }

    public aspect_ratio_tex_w_over_h: number = 1;

    private rect_drawn: Rect = new Rect(0,0,50, 50); //cannot for the life of me figure out where rect gets initialized in the original C# code

    public RectDrawn(): Rect { return this.rect_drawn; }

    // scaled and positioned
    private s: number = 1;

    // for moving the texture around on screen, so we can position them differently
    private offset_xx: number;
    private offset_yy: number;

    public get offset_x(): number { return this.offset_xx; }
    public set offset_x(value: number) 
    {
        this.offset_xx = value;
        this.rect_drawn.x = this.x + value * this.scale;
    }

    public get offset_y(): number { return this.offset_yy; }
    public set offset_y(value: number) 
    {
        this.offset_yy = value;
        this.rect_drawn.y = this.y + value * this.scale;
    }

    // int transparency_pixel_lock = 0; // for only being able to draw on pixels with alpha >0
    public get scale(): number { return this.s; }
    public set scale(value: number) 
    {
        this.s = value;

        // also should adjust the rect drawn
        this.rect_drawn.width = this.width * this.s;
        this.rect_drawn.height = this.height * this.s;

        // this.rect_drawn.x = this.x + this.offset_x;
        // this.rect_drawn.y = this.y + this.offset_y;
    }

    public SetRectDrawnWidth(width: number): void 
    {
        console.error(`setting RectDrawn: ${width}, and likewise width: ${width / this.s}, width was: ${width} scale: ${this.scale}`);
        this.width = width / this.s;

        this.rect_drawn.width = this.width * this.s;
    }

    public SetWidth(width: number): void 
    {
        this.width = width;
        this.rect_drawn.width = this.width * this.s;
    }

    public SetHeight(height: number): void 
    {
        this.height = height;
        this.rect_drawn.height = this.height * this.s;
    }










    public static BRUSH_SQUARE:number = 0;
    public static BRUSH_CIRCLE_SOFT:number = 1;
    public static BRUSH_TEXTURE:number = 2;
    //new temp one
    public static BRUSH_CIRCLE_HARD:number = 3;
    public static SET_CANVAS_COLOR:number = 5;

    // width:number=512;
    // height:number=512;

    // aspect_ratio_tex_w_over_h:number;
    camera_material:OrthographicCamera;
    bufferScene:Scene;
    textureA:WebGLRenderTarget;
    textureB:WebGLRenderTarget;
    bufferMaterial:ShaderMaterial;
    fillMaterial:ShaderMaterial;
    overwriteTextureMaterial:ShaderMaterial;

    prevMaterial:Material | Material[];//for storing the previous material when switching them temportatrily (like to white out), to switch back to afterwards

    plane:PlaneBufferGeometry;
    bufferObject:Mesh;
    finalMaterial:MeshBasicMaterial;
    quad:Mesh;

    main_scene:Scene;
    renderer:WebGLRenderer;

    mouse_pos:Vector2;



    //for layers
    visible:boolean=true;
    id:number=0;
    using_eraser:number=0;// to set on all the drawing surfaces, since they each individually have it set - 0 for no ,1 for yes
    name:string = "Layer 00";
    num_draws:number=0;//for undo/ redo- for knowing if it has been drawn on before- is it hasn't, store the state before drawing
    modified:boolean=false;//for undo/redo to know if we have drawn on this surface, and it hasn't saved th state yet

    brush_size:number = 10;

    //for if we don't have square shaped brushes
    brush_width:number=10;
    brush_height:number=10;
    brush_width_half:number=5;
    brush_height_half:number=5;
    brush_aspect_ratio_w_over_h:number=1;

    brush_aspect_over_canvas_aspect:number=1;
    tex_brush:Texture;


    //for storing the mouse points in an array of points, and feeding to the shader when ready
    pts_to_send_to_gpu_x:Array<number>;
    pts_to_send_to_gpu_y:Array<number>;

    //actual points to buffer up- as many as sent by the mouse
    //- would help with lag outs if we had a max we filled up, but for now we'll just add to it, then take away from it
    //-or maybe we'll start out with an expanding one, and then start reusing points when mouse up- so that we don't have to keep allocating + removing it
    pts_buffer_x:Array<number>= new Array<number>();
    pts_buffer_y:Array<number>= new Array<number>();


    curr_point:number=0;//for storing what point we're on when recording
    last_point:number=0;//last point we did put in the buffer- where we should start grabbing them from
    using_dynamic_array=true;//for using a dynamic instead of static array to store points in- using it will be easier, but require it to make a new array for every point it gets

    //for keeping track of if a rendertex has been changed since we grabbed the tex2d from it- if so, grab the tex2d from it again
    render_texture_changed_since_grabbing_tex2d:boolean = true;
    //Color color_cached;
    //int size_cached;

    //just for saving out to disk. so we can track for file saving if it has changed since being saved, to prompt to ask about saving again
    saved:boolean = false;//just for keeping track of whether it has been saved or not - so we know whether to

    pt_color:Vector4 = new Vector4(1, 1, 0, 1);

    brush_type:number = 0;//brush type- 0 for square, 1, round, 2 from texture
    brush_type_last:number = 0; //just for fill rect/ change canvas color- for being able to set back to what brush type was selected prior
    brush_hardness:number = 1;//hardness for square and circle brushes

    filling_canvas:boolean=false;
    fill_color:Color;
    fill_texture:boolean=false;
    overwrite_texture:boolean=false;

    //for the max points we'll send to the shader at a time- can be up to 240 on mobile, but right now it goes slow when you send too many
    // public static int MAX_POINTS_BUFFERED = 50;//100 with 1px size is slow with current shader (6fps), 25=20fps
    // static MAX_POINTS_BUFFERED:number = 25;//2019-08-14- 50 was slow when drawing with a 5px brush, it was down to <20fps
     static  MAX_MATERIAL_VECTOR_SIZE_ACTUAL:number = 26;//keeps going over, so lets try a much smaller number


    // finished_setup:boolean=false;//for knowing when it is finished being setup, so we can start accessing it


    constructor(start_threejs:StartThreeJS, main_scene:Scene, width:number = 512, height:number = 512,)
    {
        this.width=width; this.height=height;
        

        this.camera_material  = new THREE.OrthographicCamera( this.width / - 2, this.width / 2, this.height / 2, this.height / - 2, 1, 1000 );
        this.camera_material.position.z = 2;

        this.main_scene=main_scene;
        this.BufferTextureSetup();

        start_threejs.AddRenderCallBack(this.Render, this);
        this.renderer = start_threejs.renderer as WebGLRenderer;

        this.SetupPanZoomForCamera();

        
        

        //works here, but not after image loaded
        // this.AddTestData();
        // this.SetSize(100);
    }
    SetupPanZoomForCamera()
    {
        // Add OrbitControls
        // const controls = new OrbitControls(this.camera_material, this.renderer.domElement);
        // controls.enableRotate = false; // Disable rotation
        // controls.enableZoom = true; // Enable zooming
        // controls.enablePan = true; // Enable panning

        //seems to remove ability to draw on surface + zoom doesn't seem to work right
    }
    // Zoom - should be something like 0.1
    Zoom(amount:number) 
    {
        this.camera_material.zoom += amount;
        this.camera_material.updateProjectionMatrix();
    }

    // Pan function
    Pan(deltaX, deltaY) 
    {
        const panOffset = new THREE.Vector3(deltaX, deltaY, 0);
        panOffset.applyMatrix4(this.camera_material.matrix);
        this.camera_material.position.add(panOffset);
    }


    //for main to call, after getting paint on surface general setup
    // MainSaysTexturesReady()
    // {
    //     this.finished_setup = true;
    //     console.log("RenderSurface finished being setup!");
    // }
    BufferTextureSetup()
    {
        this.mouse_pos = new Vector2(-1,-1);

        const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
        
        //Create buffer scene
        this.bufferScene = new THREE.Scene();
        //Create 2 buffer textures
        this.textureA = new THREE.WebGLRenderTarget( this.width, this.height, { minFilter: THREE.LinearFilter, magFilter: THREE.NearestFilter});
        this.textureB = new THREE.WebGLRenderTarget( this.width, this.height, { minFilter: THREE.LinearFilter, magFilter: THREE.NearestFilter} );
        //Pass textureA to shader

        //create empty array vectors for shader
        this.pts_to_send_to_gpu_x= new Array();
        this.pts_to_send_to_gpu_y = new Array();
        for (let i = 0; i < RenderSurface.MAX_MATERIAL_VECTOR_SIZE_ACTUAL; i++)
        {
            this.pts_to_send_to_gpu_x.push(-1.0);
            this.pts_to_send_to_gpu_y.push(-1.0);
        }
        // this.AddTestData();
        // console.log("setting up test brush points in RenderSurface: BufferTextureSetup, length: "+this.pts_to_send_to_gpu_x.length, this);



        // this.brush_width=100;

        console.log("b w, h: "+this.brush_width+", "+this.brush_height+", this w, h: "+this.brush_width+", "+this.brush_height)


        this.aspect_ratio_tex_w_over_h = this.width / this.height;

        let uniforms = 
        {
            bufferTexture: { type: "t", value: this.textureA.texture },
            res : {type: 'v2',value:new THREE.Vector2(this.width, this.height)},//Keeps the resolution
            mouse_pos: {type: 'v2',value:this.mouse_pos},
            _BrushSizeUV_x_half: {value:(this.brush_width/this.width )*.5},
            _BrushSizeUV_y_half: {value:(this.brush_height/this.height )*.5},
            _BrushTex: {  value: this.tex_brush },
            _brush_aspect_ratio: {  value: this.brush_aspect_ratio_w_over_h },
            _brush_aspect_over_canvas_aspect: {  value: this.brush_aspect_over_canvas_aspect },
            _Points_x:{value: this.pts_to_send_to_gpu_x},
            _Points_y:{value: this.pts_to_send_to_gpu_y},
            // _Points_Length: {value:this.pts_cached_x.length}, //should be set to "MAX_MATERIAL_VECTOR_SIZE_ACTUAL" the first time, or the shader will limit it to the original size
            _Brush_type: {value: 1},
            _BrushColor: {value:new THREE.Vector4(1,1,1,1)},
            _aspect_ratio : {value: this.aspect_ratio_tex_w_over_h},
            delta: {value:0.0}

        };
        
        

        // this.bufferMaterial.uniforms._BrushTex = tex;
        // // @ts-ignore:
        // this.bufferMaterial.uniforms._brush_aspect_ratio = this.brush_aspect_ratio_w_over_h;

        // // @ts-ignore:
        // this.bufferMaterial.uniforms._brush_aspect_over_canvas_aspect = this.brush_aspect_over_canvas_aspect;

        this.bufferMaterial = new THREE.ShaderMaterial( {
            uniforms: uniforms,
            vertexShader: vertShader,
            fragmentShader: fragShader1,
            // transparent:true
        } );




        let uniforms2 = {
            color_fill: {value:new THREE.Vector4(1,0,0,1)},
            res : {type: 'v2',value:new THREE.Vector2(this.width, this.height)},//Keeps the resolution
        }
        
        this.fillMaterial = new THREE.ShaderMaterial( {
            uniforms: uniforms2,
            vertexShader: vertShader,
            fragmentShader: fragShaderFillTexture,
            // transparent:true
        } );

        let uniforms3 = {
            bufferTexture: {type: "t", value:null},
            res : {type: 'v2',value:new THREE.Vector2(this.width, this.height)},//Keeps the resolution
        }
        
        this.overwriteTextureMaterial = new THREE.ShaderMaterial( {
            uniforms: uniforms3,
            vertexShader: vertShader,
            fragmentShader: fragShaderOverwriteTexture,
            // transparent:true
        } );

        

        

        this.plane = new THREE.PlaneGeometry( this.width, this.height, 10, 10 );
        this.bufferObject = new THREE.Mesh( this.plane, this.bufferMaterial );
        this.bufferScene.add(this.bufferObject);

        

        // plane.scale(window_aspect_ratio,1,1);
        

        //Draw textureB to screen 
        this.plane = new THREE.PlaneGeometry( this.width,this.height, 10, 10 );

        //TODO: it was having issues with map
        // @ts-ignore: typescript doesn't like the map property
        this.finalMaterial =  new THREE.MeshBasicMaterial({map: this.textureB});
        this.quad = new THREE.Mesh( this.plane, this.finalMaterial );
        this.main_scene.add(this.quad);

        // console.log("added quad",this.quad)
        // quad.translateX(-200)
        


        //for testing aspect ratio:
        // const geometry = new THREE.PlaneGeometry( tex_width,tex_height );
        
        // const plane2 = new THREE.Mesh( geometry, material );
        // plane2.translateX(100)
        // this.main_scene.add( plane2 );
        
        console.log("RenderSurface: BufferTextureSetup COmplete!")
        
        
    }

    FillTexture(color:Vector4)
    {
        console.log("switching to fill texture");
        this.prevMaterial=this.bufferObject.material;

        this.fill_texture=true;
        this.fillMaterial.uniforms.color_fill.value=color;
        this.bufferObject.material=this.fillMaterial;
        
        
    }
    // AddTestData()
    // {
    //     console.log("2setting up test brush points in RenderSurface: BufferTextureSetup, length: "+this.pts_to_send_to_gpu_x.length+", this: ",this);

    //     this.bufferMaterial.uniforms._BrushColor.value = new Vector4(0,0,1,1);
    //     // this.pts_cached_x.push(0.5);
    //     // this.pts_cached_y.push(0.5);

    //     // this.pts_cached_x.push(0.7);
    //     // this.pts_cached_y.push(0.7);

    //     // this.pts_cached_x.push(0.9);
    //     // this.pts_cached_y.push(0.9);
    //     console.log("adding test data");

    //     this.pts_to_send_to_gpu_x[0] = 0.25;
    //     this.pts_to_send_to_gpu_y[0] = 0.25;

    //     this.pts_to_send_to_gpu_x[1] = 0.5;
    //     this.pts_to_send_to_gpu_y[1] = 0.5;

    //     this.pts_to_send_to_gpu_x[2] = 0.75;
    //     this.pts_to_send_to_gpu_y[2] = 0.75;

    //     this.curr_point=3;


    //     this.bufferMaterial.uniforms._Points_x.value= this.pts_to_send_to_gpu_x;
    //     this.bufferMaterial.uniforms._Points_y.value= this.pts_to_send_to_gpu_y;
    // }


    count=0;
    //need to run before main renderer runs
    Render() 
    {
        if(this.count==0)
        {
            // console.log("RenderSurface: this: ", this,"pts cached x: ",this.pts_to_send_to_gpu_x)
            // console.log("RenderSurface: x half:" +this.bufferMaterial.uniforms._BrushSizeUV_x_half.value)
        }

        if(this.count===0)
        {
            // console.log("RenderSurface: this:" ,this, "filling with color white");
            this.FillTexture(new Vector4(1,1,1,1));

            // console.log("RenderSurface: x half:" +this.bufferMaterial.uniforms._BrushSizeUV_x_half.value)

            // console.log("RenderSurface: _aspect_ratio: " +this.bufferMaterial.uniforms._aspect_ratio.value)


            // // const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
            // // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00, map: this.bufferMaterial.uniforms._BrushTex.value } );
            // const material = new THREE.MeshBasicMaterial( {  map: this.tex_brush } );

            // const geometry = new THREE.BoxGeometry( 100, 100, 1 );
            
            // const cube = new THREE.Mesh( geometry, material );
            // this.main_scene.add( cube );
            // console.log("added test material!");
            
        }
        //don't seem to need this anymore, because switching back to brush/ prev material after fill texture is run
        // else if(this.count===1)
        // {   
        //     //so this might be overwriting the copy texture material sometime - since that assigns this.bufferObject.material, this might be re-assigning it too fast
        //         // yeah- this was causing issues with auto-loading the material from last save- since it was overwriting the copy material
        //         //but this is also needed to allow drawing....
        //     this.bufferObject.material=this.bufferMaterial;
        // }
        // if(this.count===2)
        // {
        //     // this.AddTestData();
        // }
        this.count++;

        //have to set the material here- for above, when count==1 overwrites the material, and gets rid of loading the texture over this. So have to set the material again here since it could have gotten unset/ changed to default material when count==1
        if(this.overwrite_texture)
            this.bufferObject.material=this.overwriteTextureMaterial;
        




        //fill up points to send to GPU from buffer points
        
        this.PushBufferPointsIntoShaderPoints();
        

        

        // console.log("RenderSurface: this.pts_cached_x[0]: "+this.bufferMaterial.uniforms._Points_x.value[0]);
        //for debugging
        this.bufferMaterial.uniforms.delta.value+=.001;
        if(this.bufferMaterial.uniforms.delta.value>1)
            this.bufferMaterial.uniforms.delta.value=1;

        // this.bufferMaterial.uniforms._Points_x.value= this.pts_cached_x;
        // this.bufferMaterial.uniforms._Points_y.value= this.pts_cached_y;
        // console.log("setting _Points_x to : "+this.pts_cached_x.length)

        // console.log("mouse pos: ", this.mouse_pos);
        this.bufferMaterial.uniforms.mouse_pos.value= this.mouse_pos;
         //Draw to textureB

         //TODO: fix for typescript error of map not existing on boolean
         this.renderer.setRenderTarget(this.textureB, true as any);//MAIN CHANGES

         this.renderer.render(this.bufferScene,this.camera_material);
         this.renderer.setRenderTarget(null);//MAIN CHANGES
          
        //Swap textureA and B
        var t = this.textureA;
        this.textureA = this.textureB;
        this.textureB = t;

        //TODO: fix for typescript error of map not existing on material
        (this.quad.material as any).map = this.textureB.texture;

        this.bufferMaterial.uniforms.bufferTexture.value = this.textureA.texture;
        this.ResetPoints();


        //hmm, this code doesn't seem to do anything...
        //-actually, it turns back on the brush, after copying texture- so need it to re-enable brush

        // if(this.count<1)
        // if(this.count>1)
        if(this.overwrite_texture)
        {
            // console.log("RenderSurface: OVERWRITING texture, count: "+this.count);
            console.log("render, finished overwriting texture");

            this.overwrite_texture=false;
            this.bufferObject.material=this.bufferMaterial;
        }
        if(this.fill_texture)
        {
            this.bufferObject.material=this.prevMaterial;
            this.fill_texture=false;
        }
        // if (this.count<20)
        // {
        //     console.log("testing");
        // }
      }
      GetImageDataURL()
      {
        //have to resize the renderer temporarily to render the texture full screen
        this.renderer.setSize( this.width, this.height);
        
        this.renderer.render(this.bufferScene,this.camera_material);
        let data= this.renderer.domElement.toDataURL()

        //set the renderer back to full size
        this.renderer.setSize( window.innerWidth, window.innerHeight );

          return data;
        //   return this.textureA;
      }
      GetImagePNG(name_for_png:string = "")
      {

        //to allow names to be passed in:
        if(!name_for_png)
            name_for_png = MainMenu.GetInstance().project.timestamp;

        //have to resize the renderer temporarily to render the texture full screen
        this.renderer.setSize( this.width, this.height);
        
        this.renderer.render(this.bufferScene,this.camera_material);
        let img_base64= this.renderer.domElement.toDataURL("image/png");
        // let img  = this.dataURItoBlob(img_base64, MainMenu.GetInstance().project.timestamp+".png");
        let img  = this.dataURItoBlob2(img_base64, name_for_png+".png");
        // let img= this.renderer.domElement.toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');

        //fom: https://stackoverflow.com/questions/11112321/how-to-save-canvas-as-png-image
        //document.getElementById("downloader").download = "image.png";
        //document.getElementById("downloader").href = document.getElementById("canvas").toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');

        //from: https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
        //for converting the image to an actual file to send:

        // let img_base64= this.renderer.domElement.toDataURL();
        // const img = new File([img_base64], "file.png    ",{ type: "image/png" })

        // var img = new Image;
        // img.src = img_base64;
        


        //set the renderer back to full size
        this.renderer.setSize( window.innerWidth, window.innerHeight );

          return img;
      }

      dataURItoBlob2(dataURI, filename) 
      {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        //Old Code
        //write the ArrayBuffer to a blob, and you're done
        //var bb = new BlobBuilder();
        //bb.append(ab);
        //return bb.getBlob(mimeString);
    
        //New Code
        var bb =  new Blob([ab], {type: mimeString});
        var file = new File([bb], filename);

        return file;
    
    
    }
      //from https://stackoverflow.com/questions/28636294/how-to-decode-dataimage-pngbase64-to-a-real-image-using-javascript
      dataURItoBlob(dataURI, filename) 
      {
          let type= "image/png";
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab], { type: type });
        var file = new File([bb], filename);

        return file;
    }

      //from: https://pqina.nl/blog/how-to-prompt-the-user-to-download-a-file-instead-of-navigating-to-it/
      downloadFile(file) 
      {
        // Create a link and set the URL using `createObjectURL`
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = URL.createObjectURL(file);
        link.download = file.name;
      
        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link);
        link.click();
      
        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
          URL.revokeObjectURL(link.href);
          link.parentNode.removeChild(link);
        }, 0);
      }
      
      OverwriteWithTexture(texture:Texture)
      {
        //so need to render over the current texture with the new one, passing it in
        console.log("switching to overwrite texture, overwrite texture material: ", this.overwriteTextureMaterial);
        this.overwrite_texture=true;
        
        this.overwriteTextureMaterial.uniforms.bufferTexture.value=texture;
        // console.log("this.overwriteTextureMaterial.uniforms.bufferTexture.value: ", this.overwriteTextureMaterial.uniforms.bufferTexture.value);
        this.bufferObject.material=this.overwriteTextureMaterial;

        // console.log("OverwriteWithTexture texture, material: ", texture, this.bufferObject.material);
      }
          

      //for static array of points
      PushBufferPointsIntoShaderPoints()
      {
        if(!this.using_dynamic_array)
        {
            let count_pts_buffered=0;
            for (let i = this.last_point; i < this.curr_point && count_pts_buffered<this.pts_to_send_to_gpu_x.length; i++) 
            {
                console.log("pushing buffered points to send to GPU, pt: "+this.pts_buffer_x[i]+", "+this.pts_buffer_y[i]);

                this.pts_to_send_to_gpu_x[count_pts_buffered]= this.pts_buffer_x[i];
                this.pts_to_send_to_gpu_y[count_pts_buffered]= this.pts_buffer_y[i];

                this.last_point=i+1;
                count_pts_buffered++;

                if(i==this.pts_to_send_to_gpu_x.length-1)
                    this.curr_point=0;
                
            }
        }
        else
        {
            //start putting in as many points as we can from the buffer array
          
            for (let i = 0; i <  this.pts_to_send_to_gpu_x.length && this.pts_buffer_x.length>0; i++)
            {
                // console.log("pushing buffered points to send to GPU, pt: "+this.pts_buffer_x[i]+", "+this.pts_buffer_y[i]);

                this.pts_to_send_to_gpu_x[i] = this.pts_buffer_x[0];
                this.pts_to_send_to_gpu_y[i] = this.pts_buffer_y[0];

                // console.log("putting in point: "+this.pts_to_send_to_gpu_x[i]+", "+this.pts_to_send_to_gpu_y[i])
                
                this.pts_buffer_x.splice(0,1);
                this.pts_buffer_y.splice(0,1);
            }
        }
      }


      ResetPoints()
      {
          for (let i = 0; i < this.pts_to_send_to_gpu_x.length; i++) 
          {
              if(this.pts_to_send_to_gpu_x[i]==-1)
                break;
              else
            {
                this.pts_to_send_to_gpu_x[i]=-1;
                this.pts_to_send_to_gpu_y[i]=-1;
            }
              
          }
          
      }
      SendPointsToGPU()
      {
          //so need to take the points that paint on surface sent over, and send them over to the shader
          if(this.filling_canvas)
          {
            //   mat_fill_canvas.SetColor("fill_color", this.fill_color);
            //   FinalBlit(mat_fill_canvas);
              this.filling_canvas=false;
  
              this.render_texture_changed_since_grabbing_tex2d = true;
          }
          else if (this.pts_to_send_to_gpu_x.length > 0)
          {
              //Debug.Log(" Have cached points we need to send to the GPU- up to the max amount each frame here- starting with the first one, taking it out, and up to the maximum amount, and sending the whole array to the shader count: " + pts_cached_x.Count);
  

            //   List<float> pts_x = new List<float>();
            //   List<float> pts_y = new List<float>();
            //   //so go through the list, removing them up to the max number of points
            //   let num = 0;
            //   while (num < RenderSurface.MAX_POINTS_BUFFERED && this.pts_cached_x.length > 0)
            //   {
            //       pts_x.Add(ConvertPosXToUV(pts_cached_x[0]));
            //       pts_y.Add(ConvertPosYToUV(pts_cached_y[0]));
  
                  
            //       //need to do this so the pts y position is scaled based on aspect ratio, since we change the pts in the shader based on the aspect ratio- so this makes it match up
            //       if(this.brush_type== DrawingSurface.BRUSH_CIRCLE_SOFT|| this.brush_type == DrawingSurface.BRUSH_CIRCLE_HARD)//we pass the relative brush height and width for square brushes, so don't need to do that for this
            //           pts_y[pts_y.Count-1] /= this.aspect_ratio;
  
            //       pts_cached_x.RemoveAt(0);
            //       pts_cached_y.RemoveAt(0);
            //       num++;
            //   }
            //   SendPointsToGPUNow(pts_x, pts_y);

            // SendPointsToGPUNow(pts_x, pts_y);
  
              this.render_texture_changed_since_grabbing_tex2d = true;
            }
      }

      SetBrushTexture(tex:Texture)
      {
        
        if(tex==this.tex_brush)
            return;
            console.log("Setting Texture1 brush to: "+tex.image+", width and height: "+tex.image.width+", "+tex.image.height);
            
        // Debug.Log("Setting Texture brush to: "+tex);
        // if(tex!=this.tex_brush)
        //     TextureTools.ReleaseTexture(this.tex_brush);

        // Debug.Log(" tex should be null: "+this.tex_brush);
        
        this.tex_brush=tex;

        tex.magFilter = THREE.NearestFilter
        tex.minFilter = THREE.NearestFilter

        // tex.filterMode = FilterMode.Point;

        this.bufferMaterial.uniforms._BrushTex.value = tex;

        this.bufferMaterial.uniforms._brush_aspect_ratio.value = this.brush_aspect_ratio_w_over_h;



        this.brush_aspect_over_canvas_aspect = this.brush_aspect_ratio_w_over_h / this.aspect_ratio_tex_w_over_h;

        console.log("this.brush_aspect_over_canvas_aspect: "+this.brush_aspect_over_canvas_aspect);

        this.bufferMaterial.uniforms._brush_aspect_over_canvas_aspect.value = this.brush_aspect_over_canvas_aspect;


        // material.SetTexture("_BrushTex", tex);
        this.brush_aspect_ratio_w_over_h=tex.image.width/tex.image.height;



        // console.log("Aspect Ratios: _brush_aspect_ratio: "+this.bufferMaterial.uniforms._brush_aspect_ratio.value+
        // ", _brush_aspect_over_canvas_aspect: "+this.bufferMaterial.uniforms._brush_aspect_over_canvas_aspect.value+
        // ", _aspect_ratio: "+this.bufferMaterial.uniforms._aspect_ratio.value+
        // ", width, height: "+this.width+", "+this.height
        // );

        // _brush_aspect_ratio: {  value: this.brush_aspect_ratio_w_over_h },
        // _brush_aspect_over_canvas_aspect: {  value: this.brush_aspect_over_canvas_aspect },
        // _aspect_ratio : {value: this.aspect_ratio_tex_w_over_h},



        this.SetSize(this.brush_size);

        //--------------end of brush texture
      }
      /// <summary>
    /// To start recording points that will be drawn- to set the color and size for them
    /// </summary>
    StartRecordingPoints(color:Vector4, size:number, brush_type:number, brush_hardness:number)
    {
        // Debug.Log("starting pt color: " + color);
        this.pt_color = color;
        this.bufferMaterial.uniforms._BrushColor.value = this.pt_color;
        // this.pt_size = size;
        // Debug.LogWarning("ERROR: need to change this to set brush size correctly now with brush textures!");
        this.SetSize(size);

        this.brush_type = brush_type;
        this.brush_hardness = brush_hardness;
    }
    SetSize(size:number)
    {
        console.log("SETTING SIZE TO:" +size);
        this.brush_size=size;
        //now will we use width, height, or the largest one for brush size? 
        //seems photoshop uses whatever the largets direction is
        
        if(this.tex_brush.image.width>this.tex_brush.image.height)
        {
            this.brush_width=this.brush_size;
            this.brush_height=this.brush_size/ this.brush_aspect_ratio_w_over_h;
        }
        else
        {
            this.brush_width=this.brush_size;
            this.brush_height=this.brush_size/ this.brush_aspect_ratio_w_over_h;            
        }

        

        this.brush_width_half=this.brush_width*.5;
        this.brush_height_half=this.brush_height*.5;


        this.bufferMaterial.uniforms._BrushSizeUV_x_half.value = (this.brush_width/this.width )*.5;

        this.bufferMaterial.uniforms._BrushSizeUV_y_half.value = (this.brush_height/this.height )*.5;

        console.log("this brush width: "+this.brush_width+", height: "+this.brush_height)
        console.log("_BrushSizeUV_x_half "+((this.brush_width/this.width )*.5)+", height: "+((this.brush_height/this.height )*.5))


        // material.SetFloat("_BrushSizeUV_x_half", (this.brush_width/this.width )*.5);
        // material.SetFloat("_BrushSizeUV_y_half", (this.brush_height/this.height )*.5);
    }

    /// <summary>
    /// To start recording points that will be drawn- to set the color and size for them
    /// </summary>
    RecordPointV(point:Vector2)
    {
        this.RecordPoint(point.x, point.y);
    }
    //for inserting points between two points
    // public Vector2 RemoveLastPoint()
    // {
    //     pts_cached_x. 
    // }
    RecordPoint(x:number, y:number)
    {
        if(this.using_dynamic_array)
            this.RecordPointWithDynamicArray(x,y);
        else
            this.RecordPointWithStaticArray(x,y);

        // Debug.Log("Recording points: "+x+", "+y);
        // this.pts_cached_x.push(x);
        // this.pts_cached_y.push(y);

        // this.pts_to_send_to_gpu_x[this.curr_point] = x;
        // this.pts_to_send_to_gpu_y[this.curr_point] = y;
        

        // console.log("RenderSurface: RecordPoint: Saving points: "+x+", "+y)


        //just for making sure the file system knows this has been changed, and needs to prompt that it needs to be saved
        this.saved = false;
    }

    //easier- array grows and shrinks as we add points, but might cause slow downs since growing + shrinking array so much
    RecordPointWithDynamicArray(x:number, y:number)
    {
        this.pts_buffer_x.push(x);
        this.pts_buffer_y.push( y);
        // this.curr_point++;
    }

    //harder- have to have it go through the existing array, marking off where it is, but more potential for messing one up
    RecordPointWithStaticArray(x:number, y:number)
    {
        this.pts_buffer_x[this.curr_point] = x;
        this.pts_buffer_y[this.curr_point] = y;
        this.curr_point++;
    }

}