import LocalStorageInterFace from "../filesystem/LocalStorage";
import Main from "../main";
import * as THREE from 'three';
import RenderSurface from "../RenderSurface";
import Storage from "../filesystem/Storage";
import Modal from "./Modal";
import { ShowLogin } from "./windows/Login";
import UserData from "../UserData";
import Server from "../Server/Server";
import { SaveProject } from "./windows/SaveProject";
import Project from "../filesystem/Project";
import ColorChange from "./ColorChange";
import { Vector4 } from "three";
import DexieJSInterface from "../filesystem/DexieJS";

/*
Main menu functions- save, load, etc
*/
export default class MainMenu
{
    //for using in react- going to have an option to not auto load from localstorage, if the image is newer on the server
    static AUTO_LOAD=true;

    static instance:MainMenu;
    static GetInstance()
    {
        if(MainMenu.instance==undefined)
            console.error("Oh no, requesting main menu before we had a chance to create it!");
            // MainMenu.instance  = new MainMenu();

        return MainMenu.instance;
    }

    // local_storage_interface:LocalStorageInterFace;
    storage:Storage;
    modal:Modal;

    changing_brush_size:boolean=false;


    //brush size options 
    BRUSH_SIZE_MIN = 1;
    BRUSH_SIZE_MAX = 1024;

    brush_size_down_starting_x:number=0;
    brush_size_down_starting_y:number=0;
    brush_size_start:number=15;// the size of the brush when mouse down on button
    brush_size_btn_txt:HTMLElement;

    brush_size:number=15;
    // bs=15;
    // get brush_size()
    // {
    //     return this.bs;
    // }
    // set brush_size(size)
    // {
    //     console.warn("brush size being set to: ", size);
    //     console.log("brush size being set to: ", size);
    //     this.bs=size;
    // }

    project:Project;

    constructor()
    {
        MainMenu.instance=this;

        this.project = new Project();

        let save = (document.querySelector("#save") as HTMLElement)

        if(save)
        save.onclick = ()=>{SaveProject.GetInstance().Open()};//have to do arrow functions, or, since it's JS, it forgets what "this" is
       
        let load = document.querySelector("#load")as HTMLElement;
        if(load)
        load.onclick = ()=>
        {
            Modal.GetInstance().ShowComfirmation("Are you sure you want to load, and dispose the current project?", "Load", ()=>{this.LoadLocallyClicked()});
        };

        let new_btn = document.querySelector("#new")as HTMLElement;
        new_btn&&(new_btn.onclick = ()=>
        {
            Modal.GetInstance().ShowComfirmation("Are you sure you want to create a new project, and dispose the current project?", "New Project", ()=>{this.NewClicked()});
        });

        // (document.querySelector("#save_locally") as HTMLElement).onclick = ()=>{this.SaveClicked()};//have to do arrow functions, or, since it's JS, it forgets what "this" is
        // (document.querySelector("#save_remotely") as HTMLElement).onclick = ()=>{this.SaveRemotelyClicked()};
        // (document.querySelector("#load") as HTMLElement).onclick = ()=>{this.LoadClicked()};

        // (document.querySelector("#load_locally") as HTMLElement).onclick = ()=>{this.LoadLocallyClicked()};

        

        this.storage = Storage.GetInstance();

        this.SetupBrushSizeButton();

        this.InitNeededClasses();

        //for the color change stuff related to changing the color on the brush
        ColorChange.GetInstance();
    }
    InitNeededClasses()
    {
        SaveProject.GetInstance();
    }

    //called from Main after ThreeJS has been initialized, and the Brush textures loaded
    ThreeJSInitialized()
    {
        //should autoload if possible what they were working on LastTime
        console.log("Auto loading project");
        this.LoadLocallyClicked();


        //for testing loading modal
        // Modal.GetInstance().ShowLoading("Loading site");
        // setTimeout(()=>{Modal.GetInstance().LoadingFinished();}, 500)
    }

    SetupBrushSizeButton()
    {
        this.brush_size_btn_txt= document.querySelector("#brush_size");
        // console.log("Main Menu this: ", this);
        (document.querySelector("#brush_size") as HTMLElement).onmousedown = (e)=>{this.BrushSizeDown(e)};

        // (document.querySelector("#brush_size") as HTMLElement).onmouseup = (e)=>{this.BrushSizeUp(e)};
        // (document.querySelector("#brush_size") as HTMLElement).onmousemove = (e)=>{this.BrushSizeMove(e)};

        this.brush_size = parseInt(Storage.GetInstance().LoadValueOrDefault("brush_size", this.brush_size));
        if(isNaN(this.brush_size))
        {
            console.warn("Brush size loaded is NaN! Somehow saved wrong (probably when clicking on brush size button)");
            this.brush_size=30;
        }
        console.log("loaded brush size as: ", this.brush_size);
        

        //now to actually set the brush size
        Main.GetInstance().RunIfReady(()=>{
            Main.GetInstance().paint_on_surf.SetBrushSize(this.brush_size);
            MainMenu.instance.SetBrushSizeText(this.brush_size);
            // console.log("SetupBrushSizeButton: brush_size: ", this.brush_size);
        });
    }

    SaveClicked()
    {
        console.log("save clicked!", this)

        //have to grab current threeJS texture, and send it to get saved to local storage
        let image_data_url = Main.GetInstance().GetCurrentImageDataURL();
        console.log("texture image: ",image_data_url);
        // window.open(texture, "image");

        //for 
    //    this.AddImageDataURLToScreen(image_data_url);
        
       this.storage.SaveImageDataURLToLocalStorage(image_data_url);

       Modal.GetInstance().ShowOneOption("Image Saved locally!", "Ok", undefined);
    }
    async SaveDexieJSEntry(id_entry, id_challenge, updated_at, id_image=null, name="", desc="", nsfw="")
    {
        console.log("save to dexiejs!", this)
        //have to grab current threeJS texture, and send it to get saved to local storage
        let image_data_url = Main.GetInstance().GetCurrentImageDataURL();
        console.log("texture image: ",image_data_url);

        //could await, but don't really need to wait on it
        (await DexieJSInterface.GetInstance()).SaveLocalEntry(id_entry, id_challenge, updated_at, image_data_url, id_image, name, desc, nsfw)
    }
    //for non entries- just normal projects
    async SaveDexieJSProject(updated_at, id_image=null, name="", desc="", nsfw="")
    {
        console.log("save to dexiejs project!", this)
        //have to grab current threeJS texture, and send it to get saved to local storage
        let image_data_url = Main.GetInstance().GetCurrentImageDataURL();
        console.log("texture image: ",image_data_url);

        //could await, but don't really need to wait on it
        (await DexieJSInterface.GetInstance()).SaveLocalProject(updated_at, image_data_url, id_image, name, desc, nsfw)
    }
    async GetEntryDexieJS(id_entry)
    {
        return await (await DexieJSInterface.GetInstance()).GetLocalEntry(id_entry);
    }
    static async GetProjectDexieJS(id_project)
    {
        return (await DexieJSInterface.GetInstance()).GetLocalProject(id_project);
    }

    SaveRemotelyClicked(title="A title", desc="a desc", nsfw="true", timestamp="0")
    {
        console.log("save remotely clicked!", this, UserData.GetInstance().logged_in)

        //have to grab current threeJS texture, and send it to get saved to local storage
        // let image_data_url = Main.GetInstance().GetCurrentImageDataURL();

        if(UserData.GetInstance().logged_in)
        {
            console.log("Gonig to submit image to be saved remotely");
            //so on Server, send image
            // Server.GetInstance().SendImageToServer(Main.GetInstance().GetCurrentImagePNG())
            // console.warn("need to grab actual title, desc, nsfw, just using testing right now!");

            // let title="A title";
            // let desc="a desc"; 
            // let nsfw="false";
            // let timestamp="0";
            let image = Main.GetInstance().GetCurrentImagePNG();
            console.log("image: ", image)
            Server.GetInstance().SendImageFormData(image, title, timestamp, desc, nsfw);

        }
        else
            this.ShowMustBeLoggedIn();
    }
    ShowMustBeLoggedIn()
    {
        Modal.GetInstance().ShowMustBeLoggedIn(()=>{console.log("login clicked for modal!");ShowLogin()});
    }

    //for testing if we're getting the actual image
    AddImageDataURLToScreen(image_data_url)
    {
        var img = new Image();
        img.src = image_data_url;
        document.body.appendChild(img);
    }
    LoadClicked()
    {
        console.log("load clicked!")
        let image_data_url = this.storage.LoadImageDataURLFromLocalStorage();
        // this.AddImageDataURLToScreen(image_data_url);

        //from: https://jsfiddle.net/2pha/njetLLz7/

        //have to actually put it into threeJS
        const loader = new THREE.TextureLoader();
        loader.load(image_data_url, (texture)=>
        {
            console.log("texture loaded")
            //so put this over our image
            Main.GetInstance().OverwriteWithTexture(texture);
        });

        
        
        // let texture = THREE.ImageUtils.loadTexture( imageData );

        //now just need to feed into our render surface
        /*
            var dataURL = localStorage.getItem(canvasName);
        var img = new Image;
        img.src = dataURL;
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
        */
    }

    
    
    LoadLocallyClicked()
    {
        
        
        let img_obj = this.storage.LoadImageLocally() as any;

        console.log("load locally clicked! img_obj: ", img_obj);
        if(img_obj==null)
        {
            console.log("No Saved Image found, not auto loading");
        }
        else
        {
            this.LoadImage(img_obj["image"]);
            SaveProject.GetInstance().SetImageProperties(img_obj.title, img_obj.desc, img_obj.nsfw)

            /* const loader = new THREE.TextureLoader();
            loader.load(img_obj["image"], (texture)=>
            {
                // console.log("texture loaded, img obj: ", img_obj);
                // console.log("texture loaded, texture: ", texture.image);
                this.project = new Project(img_obj);

                //so put this over our image
                Main.GetInstance().OverwriteWithTexture(texture);

                //for testing- doing it again after a delay
                //it does work- which means this area is working finer, but whatever it's trying to do is not working
                // window.setTimeout(()=>{

                //     console.log("overwriting texture again, with 5s delay to see if it works");
                //     Main.GetInstance().OverwriteWithTexture(texture);
                // }, 5000);

                //lets populate SaveProject TextFields with everything
                SaveProject.GetInstance().SetImageProperties(img_obj.title, img_obj.desc, img_obj.nsfw)
                
            }); */
        }
    }
    LoadImage(image_data_url)
    {
        const loader = new THREE.TextureLoader();
        loader.load(image_data_url, (texture)=>
        {
            // console.log("texture loaded, img obj: ", img_obj);
            // console.log("texture loaded, texture: ", texture.image);
            this.project = new Project(image_data_url);

            //so put this over our image
            Main.GetInstance().OverwriteWithTexture(texture);

            //for testing- doing it again after a delay
            //it does work- which means this area is working finer, but whatever it's trying to do is not working
            // window.setTimeout(()=>{

            //     console.log("overwriting texture again, with 5s delay to see if it works");
            //     Main.GetInstance().OverwriteWithTexture(texture);
            // }, 5000);

            //lets populate SaveProject TextFields with everything
            
            
        });
    }


    NewClicked()
    {
        console.log("new clicked!")
        //should white out texture like default
        Main.GetInstance().render_surface.FillTexture(new Vector4(1,1,1,1));

        SaveProject.GetInstance().NewImage();

        // let img_obj = this.storage.LoadImageLocally() as any;
        // if(img_obj==null)
        // {
        //     console.log("No Saved Image found, not auto loading");
        // }
        // else
        // {
        //     const loader = new THREE.TextureLoader();
        //     loader.load(img_obj["image"], (texture)=>
        //     {
        //         // console.log("texture loaded, img obj: ", img_obj);
        //         // console.log("texture loaded, texture: ", texture.image);
        //         this.project = new Project(img_obj);

        //         //so put this over our image
        //         Main.GetInstance().OverwriteWithTexture(texture);
        //         SaveProject.GetInstance().SetImageProperties(img_obj.title, img_obj.desc, img_obj.nsfw)
                
        //     });
        // }
    }

    //an issue with this changing, so using bind which fixes it, but then the event listener isn't the same one
    event_lister_up;
    event_lister_move;
    BrushSizeDown(event)
    {
        console.log("BrushSizeDown: ", event)

        this.brush_size_start = Main.GetInstance().paint_on_surf.GetBrushSize();
        console.log("BrushSizeDown: this.brush_size_start", this.brush_size_start, "this.brush_size: ");
        console.log(this.brush_size, "this: ",this)


        
        this.changing_brush_size=true;

        this.brush_size_down_starting_x=event.clientX;
        this.brush_size_down_starting_y=event.clientY;
        console.log("BrushSizeDown: ", this.brush_size_down_starting_x, this.brush_size_down_starting_y)

        //it won't capture outside this event, so have to capture for the full window:
        this.event_lister_up=MainMenu.instance.BrushSizeUp.bind(this);
        this.event_lister_move=MainMenu.instance.BrushSizeMove.bind(this);
        window.addEventListener("mouseup", this.event_lister_up, true);
        window.addEventListener("mousemove", this.event_lister_move, true);
        
        // (document.querySelector("#brush_size") as HTMLElement).onmousemove = (e)=>{this.BrushSizeMove(e)};
        console.log("BrushSizeDown: this.brush_size: ", this.brush_size);

    }
    BrushSizeUp(event)
    {
        console.log("BrushSizeUp: this.brush_size: ", this.brush_size);
        console.log("BrushSizeUp: ", event, "this.brush_size: ", this.brush_size)
        console.log(this.brush_size, "this: ",this)

        window.removeEventListener("mouseup", this.event_lister_up, true);
        window.removeEventListener("mousemove", this.event_lister_move, true);

        //now to actually set the brush size
        Main.GetInstance().paint_on_surf.SetBrushSize(this.brush_size);

        //also save out the brush size to reload on startup
        Storage.GetInstance().SaveValue("brush_size", this.brush_size);
        console.log("saving out brush size as: "+this.brush_size);
    }
    BrushSizeMove(event)
    {
        // console.log("BrushSizeMove: this.brush_size: ", this.brush_size);
        
        // console.log("BrushSizeMove: ", event)
        let x=event.clientX;
        let y=event.clientY;
        let x_diff= x-MainMenu.instance.brush_size_down_starting_x;
        let y_diff= y-MainMenu.instance.brush_size_down_starting_y;
        y_diff=-y_diff;//flip to make going down go negative

        let distance = (x_diff + y_diff)/10;

        let new_size = parseInt((MainMenu.instance.brush_size_start+distance).toFixed());
        if(new_size<MainMenu.instance.BRUSH_SIZE_MIN)
            new_size = MainMenu.instance.BRUSH_SIZE_MIN;
        else if(new_size>MainMenu.instance.BRUSH_SIZE_MAX)
            new_size = MainMenu.instance.BRUSH_SIZE_MAX;

        // console.log("BrushSizeMove: ", new_size)

        MainMenu.instance.SetBrushSizeText(new_size);
        this.brush_size=new_size;

        // //now to actually set the brush size
        // Main.GetInstance().paint_on_surf.SetBrushSize(new_size);
    }
    SetBrushSizeText(new_size)
    {
        MainMenu.instance.brush_size_btn_txt.innerHTML = new_size+" px";
    }
}