/* To control the save dialog class*/

import Project from "../../filesystem/Project";
import Storage from "../../filesystem/Storage";
import Main from "../../main";
import Server from "../../Server/Server";
import UserData from "../../UserData";
import MainMenu from "../MainMenu";
import Modal from "../Modal";

export class SaveProject
{
    static instance:SaveProject;
    static GetInstance()
    {
        if(SaveProject.instance==undefined)
            SaveProject.instance  = new SaveProject();

        return SaveProject.instance;
    }

    element:HTMLElement;
    error_message:HTMLElement;

    title_ele:HTMLInputElement;
    desc_ele:HTMLTextAreaElement;
    // nsfw_ele:HTMLInputElement;


    constructor()
    {
        this.element = document.querySelector("#SaveProject");
        console.log("Save dialog constructor ran!", this.element);

        this.error_message  =(this.element.querySelector(".error_message") as HTMLElement);

        this.title_ele  =(this.element.querySelector("input[type='text']") as HTMLInputElement);
        this.desc_ele  =(this.element.querySelector("textarea") as HTMLTextAreaElement);
        // this.nsfw_ele  =(this.element.querySelector('input[name="nsfw"]:checked') as HTMLInputElement);


        this.HideFeedback();

        (this.element.querySelector(".btn_back") as HTMLElement).onclick = this.Close.bind(this); 
        (this.element.querySelector(".btn_save") as HTMLElement).onclick = this.SaveLocally.bind(this, true); 

        (this.element.querySelector(".btn_upload") as HTMLElement).onclick = this.Upload.bind(this); 
    }
    Close()
    {
        //@ts-ignore
        HideWindows();
        this.element.style.display="none";
    }
    Open()
    {
        //@ts-ignore
        ShowWindows();
        this.element.style.display="block";
        

        // if(UserData.GetInstance().logged_in)
        //     (this.element.querySelector(".btn_upload") as HTMLElement).style.display="block";
        // else
        //     (this.element.querySelector(".btn_upload") as HTMLElement).style.display="none";
        if(UserData.GetInstance().logged_in)
            (this.element.querySelector(".btn_upload") as HTMLElement).innerHTML="Upload To Server"
        else
            (this.element.querySelector(".btn_upload") as HTMLElement).innerHTML="Upload To Server (Must Login First)";
    }
    ShowFeedback(error)
    {
        this.error_message.style.display="block";
        this.error_message.innerHTML = error;
    }
    HideFeedback()
    {
        this.error_message.style.display="none";
    }
    SaveLocally(show_modal)
    {
        let saved=false;

        //grab the input field values
        let project = MainMenu.GetInstance().project;
        project.SetProperties(this.title_ele.value, this.desc_ele.value, (this.element.querySelector('input[name="nsfw"]:checked') as HTMLInputElement).value);
        // let title = this.title_ele.value;
        // let desc = this.desc_ele.value;
        // let nsfw = (this.element.querySelector('input[name="nsfw"]:checked') as HTMLInputElement).value;

        if(project.title.trim().length<1)
        {
            this.ShowFeedback("You must have a title");
        }
        else
        {
            this.HideFeedback();

            let image_data_url = Main.GetInstance().GetCurrentImageDataURL();
            console.log("texture image: ",image_data_url);
            
            //Storage.GetInstance().SaveImageDataURLToLocalStorage(image_data_url);
            Storage.GetInstance().SaveImageLocally(image_data_url, project.title, project.desc, project.nsfw, project.timestamp);

           //also have to store image data- title, desc, nsfw, and reload it


            if(show_modal)
            {
                this.Close();
                Modal.GetInstance().ShowOneOption("Image Saved locally!", "Ok", undefined);
            }

            saved=true;
        }
        console.log("save clicked, with title: "+project.title+", desc: "+project.desc+", nsfw: "+project.nsfw);

        return saved;
    }
    Upload()
    {
        console.log("SaveProject: Upload");
        //first save locally before uploading
        //should make sure they are logged in, and if not prompt them
        if(!UserData.GetInstance().logged_in)
        {
            console.log("user not logged in");
            this.Close();
            MainMenu.GetInstance().ShowMustBeLoggedIn()
        }
        else if(this.SaveLocally(false))
        {
            
            console.log("goign to upload to server");

            let project:Project = MainMenu.GetInstance().project;

             

            //now send to server

            //lets first grab the PNG
            let render_surf = Main.GetInstance().GetCurrentRenderSurface();
            let img = render_surf.GetImagePNG()

            console.log("img: ", img);
            //another resource:  for sending a image from canvashttps://stackoverflow.com/questions/13198131/how-to-save-an-html5-canvas-as-an-image-on-a-server


            //grab the input field values
            project.title = this.title_ele.value;
            project.desc = this.desc_ele.value;
            project.nsfw = (this.element.querySelector('input[name="nsfw"]:checked') as HTMLInputElement).value;
            project.image=img;

            this.Close();

            Modal.GetInstance().ShowLoading("Uploading Image to Server");

            //to test delay in server time
            // setTimeout(()=>{
            //     Server.GetInstance().SendImageFormData(UserData.GetInstance().token, project.image, project.title, project.timestamp, project.desc, project.nsfw);
            // }, 2500)
            
            Server.GetInstance().SendImageFormData(project.image, project.title, project.timestamp, project.desc, project.nsfw);

            // var newTab = window.open('about:blank','image from canvas');
            // newTab.document.write("<img src='" + URL.createObjectURL(img) + "' alt='from canvas'/>");
            // newTab.document.write("<img src='" + img + "' alt='from canvas'/>");
            //lets prompt to download too

            //THIS FINALLY DOWNLOADS THE IMAGE!!!!
            // render_surf.downloadFile(img);
        }
    }
    /**
     * NEW- for uploading a challenge entry
     * should take an entry id + project and user id
     */
    /* UploadChallenge(user, challenge, entry)
    {

        console.log("goign to upload challenge to server");

        // let project:Project = MainMenu.GetInstance().project;

        //now send to server

        //lets first grab the PNG
        let render_surf = Main.GetInstance().GetCurrentRenderSurface();
        let img = render_surf.GetImagePNG()

        console.log("img: ", img);
        //another resource:  for sending a image from canvashttps://stackoverflow.com/questions/13198131/how-to-save-an-html5-canvas-as-an-image-on-a-server


        //grab the input field values
        project.title = this.title_ele.value;
        project.desc = this.desc_ele.value;
        project.nsfw = (this.element.querySelector('input[name="nsfw"]:checked') as HTMLInputElement).value;
        project.image=img;

        this.Close();

        Modal.GetInstance().ShowLoading("Uploading Image to Server");
        Server.GetInstance().SendImageFormData(project.image, project.title, project.timestamp, project.desc, project.nsfw);
    } */

    /**
     * 
     * NEW for challenges - just to get the image, so I could submit it to the server through the frontend/ react
     */
    GetImage(name_for_png:string = "")
    {
        //lets first grab the PNG
        let render_surf = Main.GetInstance().GetCurrentRenderSurface();
        let img = render_surf.GetImagePNG(name_for_png);
        return img;
    }
    
    SetImageProperties(title, desc, nsfw)
    {
        //just because changing from yes/ no to true/ false
        if(nsfw==="no")
            nsfw="false";
        else if(nsfw==="yes")
            nsfw="true";

        this.title_ele.value = title;
        this.desc_ele.value = desc;
        let nsfw_active = (this.element.querySelector('input[name="nsfw"][value="'+nsfw+'"]') as HTMLInputElement);
        console.log("setting nsfw element: ", nsfw_active);
        nsfw_active.checked=true;
    }
    NewImage()
    {
        this.SetImageProperties("No title", "No description", "false");
    }
}