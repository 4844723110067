export default class LocalStorageInterFace
{
    static instance;
    static GetInstance():LocalStorageInterFace
    {
        if(LocalStorageInterFace.instance==undefined)
            LocalStorageInterFace.instance  = new LocalStorageInterFace();

        return LocalStorageInterFace.instance;
    }
    constructor()
    {
        LocalStorageInterFace.instance = this;        
    }
    
    //from: https://stackoverflow.com/questions/20507534/how-to-save-and-load-html5-canvas-to-from-localstorage
    SaveImageDataURLToLocalStorage(data_url)
    {
        localStorage.setItem("image", data_url);
    }
    LoadImageDataURLFromLocalStorage()
    {
        return localStorage.getItem("image");


        // var img = new Image();
        // img.src = image_data_url;
        // document.body.appendChild(img);
        /*
        var dataURL = localStorage.getItem(canvasName);
        var img = new Image;
        img.src = dataURL;
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
        };
        */
    }
    SaveValue(name, value)
    {
        //save the value
        localStorage.setItem(name, value);
    }
    LoadValue(name)
    {
        //return the value
        return localStorage.getItem(name);
    }
    //object such as: {"title": "a title", "name": "a name"}
    SaveValues(object)
    {
        //save the values
        for (var item in object) 
        {
            localStorage.setItem(item, object[item]);
            console.log("Settign local storage for name: "+item+", value: "+object[item]);
        }
        
    }
    //array of names, such as ["title", "desc"]
    LoadValues(array)
    {
        let image = {};
        //return the value
        array.forEach(item => {
            image[item] = localStorage.getItem(item);
        });
        return image;
    }
    DeleteValue(name)
    {
        localStorage.removeItem(name);
    }
    DeleteValues(array)
    {
        array.forEach(item => {
            localStorage.removeItem(item);
        });
        
    }

}