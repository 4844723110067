
/**
 * Theme- for having the dark/ light mode theme working across the app.
 * Orignally in headerAndFooter, but some spotts (like the drawing app) don't use the header and footer.
 */
import React, { useState } from 'react';
import NavBar from '../NavBar/NavBar';
import { Routes } from 'react-router-dom';
import { Box, CssBaseline, GlobalStyles, Paper, ThemeProvider, createTheme } from '@mui/material';
import { lightBlue } from '@mui/material/colors';



  const themeLight = createTheme({
    palette: {
      background: {
        default: "#ffffff"
      },
      text: {
        primary: "#222222"
      }
    },
  });

  //have to define the theme in 2 steps if you want to self reference colors: 
  //https://mui.com/material-ui/customization/theming/
  //Theme composition: using theme options to define other options
  let primary_dark = "#222222;"
  let themeDark = createTheme({
    palette: {
        mode: 'dark',
        background: {
        default: primary_dark
      },
      primary: {
        main: '#1976d2', 

      },
      //from the tool: https://m2.material.io/inline-tools/color/ for getting similar colors
      secondary:{
        main:"#d27619"
      },
      
      
      text: {
        primary: "#ffffff"
      },
      
    }});

    themeDark = createTheme(themeDark, {
    components: {
        MuiPaper: {
          styleOverrides: {
            root: {
                // backgroundColor: primary_dark,
              },
          },
        },
        MuiLink: {
            styleOverrides: {
              root: {
                textDecoration: 'none', // Remove underline from all links
                color:"#ffffff",
                '&:hover': {
                    textDecoration: 'underline', // Add underline on hover
                  },
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                margin: "5px",
                // backgroundColor:"#0000ff",
                // color: themeDark.palette.primary.main,
                '&:hover': {
                    // color:  themeDark.palette.primary.main, // Add underline on hover
                    // borderColor: themeDark.palette.primary.main,
                    backgroundColor: themeDark.palette.primary.main +" !important"
                  },
              },
            },
          },
         
    },
    
  });

const Theme = ({ children }) => {

    const [theme, setTheme] = useState(themeDark);
  return (
   
    <ThemeProvider theme={theme}>
    <GlobalStyles styles={{ 
        body: {
          backgroundColor: theme.palette.background.default,
          // Add any other body styles here
        },
      }} />
  <CssBaseline/>{/* for material UI to do CSS resets */}

    
   
            
    {children}

    
    </ThemeProvider>
    
  );
};

export default Theme;