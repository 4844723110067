import * as THREE from 'three';
import { OrthographicCamera, Raycaster, Scene, Vector, Vector2 } from 'three';
export default class ConnectTheDots
{
    static spacing:number = 10;

    static SetSpacingPercent(spacing_percent:number, brush_size:number)
    {
        ConnectTheDots.spacing = spacing_percent * (brush_size);
        //this for if in pizels
        // if (ConnectTheDots.spacing < 1)
        //     ConnectTheDots.spacing = 1;
    }
    static GetPoints(x1:number, y1:number, x2:number, y2:number)
    {
        return this.GetPointsV(new Vector2(x1, y1), new Vector2(x2, y2));
    }
    GetPointsTesting(pt1:Vector2, pt2:Vector2)
    {
        let pts:Array<Vector2> = new Array<Vector2>();
        pts.push(pt2);
        //just adding the one point, it slows down to 50fps

        return pts;
    }
    /// <summary>
    /// for sending in 2 points, and getting an array of them back, with equal spacing
    /// don't send back the first point, since it has already been drawn
    /// </summary>
    static GetPointsV(pt1:Vector2, pt2:Vector2)
    {
        // console.log("2 points are: ", pt1, pt2);
        //we have the start and end point
        let start:Vector2 = pt1;
        let end:Vector2 = pt2;

        //float distance = Mathf.Sqrt(Mathf.Pow(mouse_pt.x - last_drawn_pos_x, 2) + Mathf.Pow(mouse_pt.y - last_drawn_pos_y, 2));
        
        // let distance:number = Vector2.Distance(pt1, pt2);
        let distance:number = pt1.distanceTo(pt2);

        //Vector2.an
        // let angle:number = Mathf.Atan2(end.y - start.y, end.x - start.x);
        let angle:number = Math.atan2(end.y - start.y, end.x - start.x);
        

        let cos_angle = Math.cos(angle);
        let sin_angle = Math.sin(angle);

        let spacing = ConnectTheDots.spacing;

        // console.log("angle: "+angle+", cos: "+cos_angle+", sin: "+sin_angle+", angle degrees: "+(angle * (180/Math.PI))+", distance: "+distance+", dist/spac: "+(distance / spacing));

        
        

        let steps = Math.floor(distance / spacing) + 1;

        // console.log("spacing of points is: "+spacing+", steps are: "+steps)

        //now move towards the end spot until we get past it (don't draw past it)- lets draw in red to be safe
        //Vector2 curr_pos = new Vector2(start.x + brush_spacing_pixels * cos_angle, start.y + brush_spacing_pixels * sin_angle);
        

        // Debug.Log("Drawing from mouse: " + start + ", and :" + end + ", steps: " + steps + ", spacing: " + spacing);
        //SKIPPING THE FIRST POINT BECAUSE IT"S THE MOUSE POINT< AND IT SEEMS TO DRAW 2 points there...

        let spacing_cos_angle = (spacing * cos_angle);
        let spacing_sin_angle = (spacing * sin_angle);

        let pts = new Array<Vector2>();

        //normally we won't draw the last position- unless there is only one position, then we should
        // int start_pos=1;
        // if(steps==1)
        //     start_pos=0;

        if(steps==1)//if only 1 point, add it
            pts.push(end);
        else
        {
            for (let i = 1; i < steps; i++)
            {
                let curr_pos = new Vector2();
                //curr_pos.x = start.x + spacing_cos_angle * i;
                //curr_pos.y = start.y + spacing_sin_angle * i;
                // if (i==1)
                if (i==1)
                {
                    curr_pos.x = start.x + spacing_cos_angle;
                    curr_pos.y = start.y + spacing_sin_angle;
                }
                else
                {

                    curr_pos.x =  pts[pts.length - 1].x + spacing_cos_angle;
                    curr_pos.y = pts[pts.length - 1].y + spacing_sin_angle;
                }
                // console.log("adding curr pos: ", curr_pos);

                pts.push(curr_pos);
                // Debug.Log("Drawing pts: "+curr_pos);
            }
        }
        // pts.forEach( (ele, i)=> {console.log("pts["+i+"] = "+ele.x+", "+ele.y)})

        //interpolate between the 2 points, so go from the first point, slowly move to the second point
        return pts;
    }
}