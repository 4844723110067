/**
 * A page for showing a users drawings, or create a new drawing- gotten to from navbar
 */

import { useEffect, useState } from 'react'
import styles from "./Challenges.module.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';



//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { SUB_FOLDER, URL_BACKEND } from '../CONSTANTS';

import HeaderAndFooter from './Layouts/HeaderAndFooter';

import { GET, MyFetchRequest, POST } from '../Helpers';
import ProjectCard from './ProjectCard';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Slide, Snackbar } from '@mui/material';
import { userStore } from '../App';
import { UserData } from '../User';
import { TransitionGroup } from 'react-transition-group';
import MainMenu from '../PaintProgram/3_brush_controls/UI/MainMenu';
import Main from '../PaintProgram/3_brush_controls/main';



function MyProjects() 
{
    //from zustand
    const user = userStore((state) => state.user);

    const [delete_open, SetDeleteModal] = useState(false);
    const [modal_project, SetDeleteModalProject] = useState({title:"this project"} as any);

    //for little popup when deleting/ "snackbar"
    const [snackbar_open, SetSnackBarOpen] = useState(false);
    const [snackbar_message, SetSnackbarMessage] = useState("whatchu talkin' about, Willis?");

  const [count, setCount] = useState(0);
//   const [my_entries, setMyEntires] = useState(new Array());

  const [my_projects, setMyProjects] = useState(new Array());

//   const { item } = useParams();
    let { state } = useLocation();
    // let challenge = state.item as ChallengeItemInterface;

    //for being able to change route to the drawing page
    const navigate = useNavigate();


    //drawing/ project will either be a new drawing, or an existing one with an id
    const OpenDrawing = async(user:UserData, project:any)=>
    {
        //so have to submit the challenge id, the user id/ token
        //and get the new challenge entry id back, which will be used to go to the next page/ submit an entry



        if(!project.id)//if starting a new one
        {
            //need to create a new drawing on the server, to have a project id
            try{
                let created_at = new Date().toISOString();
                let response = await MyFetchRequest(URL_BACKEND+'images/new', GET, {token:user.token, created_at:created_at});
                console.log("my new project is:", response.data);

                project.id=response.data.id;
                project.created_at= created_at;
                project.updated_at= created_at;
                console.log("project being sent: ", project);

                //just need to open up a new drawing, don't have to tell the server anything:
                navigate(SUB_FOLDER+'Draw', { state: { project:project} });
                
            }
            catch(err){console.error('There was a problem with the fetch operation:', err);}


            
        }
        else
        {
            console.log("Opening existing drawing: ", project);
            //should only do this if default project- if challenge, need to open it differently
            let is_challenge= false
            if(project.challenge_entry)
                is_challenge=true;

            
            

            if(!is_challenge)
                navigate(SUB_FOLDER+'Draw', { state: { project:project} });
            else
            {
                let obj = { state: { project:project, challenge:{...project.challenge_entry.challenge}, entry:{project:project, ...project.challenge_entry }}};
                // console.log("state to pass: ", obj)
                navigate(SUB_FOLDER+'Draw', obj);
            }
        }
    }



    const GetMyProjects = async (user:UserData)=>
    {
        console.log("Going to grab projects for this user", user);

        //so have to submit the project id, the user id/ token
        //and get the new project id back, which will be used to go to the next page/ submit
        
        const url = URL_BACKEND+'images/';
        const params = {
            // limit:'24',
            token: user.token,
        };

        try{
        
            let data = await MyFetchRequest(url, GET, {token:user.token});

            console.log("my projects are: , ", data);

            let projects = data.data;



            //should also grab info from local projects, and use those where applicable- for instance if the project is more updated locally, should show that
            for (let i = 0; i < projects.length; i++) 
                {
                    const project = projects[i];
                
            
                    project.path = URL_BACKEND+project.path;

                    let dexie_project = await MainMenu.GetProjectDexieJS(project.id);
                    if(dexie_project!=undefined)
                    {                        
                        console.log("looking at project: ", project, "dexie proj: ", dexie_project);
                        // console.warn("project not null!!");
                        if(new Date(dexie_project.updated_at)< new Date(project.updated_at))
                        {
                            console.log("Loading image from server, since newer than local, local: ", new Date(dexie_project.updated_at), "server: ", new Date(project.updated_at));
                            
                    
                        }
                        else //load image locally
                        {
                            console.log("going to load image locally, since newer than on server!", project.id, "dexie date: ", new Date(dexie_project.updated_at), "server date: ", new Date(project.updated_at));
                            // console.log("dexie proj: ", dexie_project);
                            console.log("looking at project: ", project, "dexie proj: ", dexie_project);
                            let {image, nsfw, name, desc} = dexie_project;
                            project.path=image;
                            project.nsfw= nsfw;
                            project.name= name;
                            project.desc= desc;

                        }
                    }                   
                }

                console.log("setting my projects");

            setMyProjects(projects);

            
        }
        catch(err)
        {
            console.error('There was a problem with the fetch operation:', err);
        }
    }
    const DeleteClicked = async(project)=>
    {
        SetDeleteModalProject(project);
        SetDeleteModal(true);
    }
    const DeleteConfirmed = async()=>
    {
        console.log("delete confirmed");
        // SetSnackbarMessage("");
        SetSnackbarMessage("Project \""+modal_project.title+ "\" deleted successfully");
        SetSnackBarOpen(true);
        // return;

        // SetDeleteModal(true);
        console.log("going to delete project on server", modal_project);
        let response = await MyFetchRequest(URL_BACKEND+'images/delete', POST, {token:user.token, id_image:modal_project.id, id_entry:modal_project.challenge_entry?modal_project.challenge_entry.id:""});
        console.log("response from deleting: ", response);

        //if response.msg is success, delete it from the my_projects
        if(response.msg=="success")
        {
            //remove from list of projects
            let my_projs_new = my_projects.filter((proj)=>{
                if(proj.id!=modal_project.id)
                    return proj;
                else
                    console.log("removing project: ", proj);
            });

            setMyProjects(my_projs_new);

        }

    }
    const SnackBarClosed = ()=>{SetSnackBarOpen(false);SetSnackbarMessage("");}


  useEffect(() => {
    
    // console.log("item is: ", challenge);

    if(user)
        GetMyProjects(user);
    else
        navigate(SUB_FOLDER, { state: { } });
        // GetAllEntriesForChallenge(item);
    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount



  return (
    <>
    <HeaderAndFooter>
    <div>
        <div>

            {my_projects&&<p>My Projects</p>
            
            }
            <Button variant='contained' style={{ height:"100px"}} onClick={()=>{
                OpenDrawing(user, {type:"project"});
            }}>Create a New Project!</Button>

            <div style={{display:"flex", flexWrap:"wrap"}}>
            {
                
                    my_projects.map((project, i)=>{
                    return <ProjectCard 

                    

                    project={{
                        image_path:project.path, 
                        title:project.name, 
                        desc:project.desc, 
                        author: "", 
                        author_avatar_url:project.path,
                        challenge_entry:project.challenge_entry, /* for if an image also has a project entry*/
                        id:project.id
                    }}


                        
                        data-id={project.id} 
                        key={project.id} 
                        onClick={()=>{console.log("card clicked!"); OpenDrawing(user, project)}}
                        DeleteClicked = {DeleteClicked}
                        >
                                                
                    
                    </ProjectCard>;

                    // return <ProjectCard  key={item.id} project={{image_path:URL_BACKEND+item.image.path, title:"A title", desc:"A description", author: "an author", author_avatar_url:URL_BACKEND+item.image.path}}/>
                })
                
            }
            
            </div>
            <Dialog
                open={delete_open}
                onClose={()=>{SetDeleteModal(false);}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the project "{ modal_project.title}"?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{SetDeleteModal(false);}} color="primary">
                    Cancel
                </Button>
                <Button onClick={()=>{DeleteConfirmed();SetDeleteModal(false);}} color="secondary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>

            <Snackbar 
                open={snackbar_open} 
                autoHideDuration={2000} 
                onClose={SnackBarClosed}
                onClick={SnackBarClosed}

                // have to have a key here, or it will never open a second time- it forces react to re-render it after it auto closes
                key={snackbar_message}

            TransitionComponent={(props)=>{return (<Slide {...props} direction="right" ></Slide>)}}
            >
                <Alert
                    onClose={SnackBarClosed}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar_message}
                </Alert>
            </Snackbar>
        </div>
       
    </div>
    </HeaderAndFooter>
    
    
    </>


  )

  
}




export default MyProjects
