import Storage from "../filesystem/Storage";
import Main from "../main";

/*
For changing the color 
*/
export default class ColorChange
{
    static instance:ColorChange;
    static GetInstance()
    {
        if(ColorChange.instance==undefined)
            new ColorChange();
            // MainMenu.instance  = new MainMenu();

        return ColorChange.instance;
    }
    color_rgba_255_str;

    constructor()
    {
        ColorChange.instance=this;

        //ask the index.html to let us know when color picker has changed- but since typescript doesn't know about what is in index, have to ignore it:
         // @ts-ignore
        RegisterColorChangedFromUI((r,g,b,a)=>{this.ColorChangedFromUI(r,g,b,a)});//have to wrap this in arrow function to preserve "this"

        this.color_rgba_255_str= Storage.GetInstance().LoadValueOrDefault("brush_color", "0,0,0,1");
        console.log("loaded color: ",this.color_rgba_255_str);

        //Set the color in color picker after loading it
        // @ts-ignore
        SetJSColorString("rgba("+this.color_rgba_255_str+")");
        
        //also have to set brush color
        let rgba_obj= this.RGBA255StringToRGBA(this.color_rgba_255_str);
        Main.GetInstance().paint_on_surf.SetBrushColor255(rgba_obj.r,rgba_obj.g,rgba_obj.b,rgba_obj.a);
    }
    ColorChangedFromUI(r,g,b,a)
    {
        console.log("typescript knows about color change! ");
        //now set the brush color

        //now to actually set the brush size
        Main.GetInstance().paint_on_surf.SetBrushColor255(r,g,b,a);


        let rgba_str = this.RGBA255ToString(r,g,b,a)
        console.log("rgba_str: ", rgba_str, "back to rgba: ", this.RGBA255StringToRGBA(rgba_str));
        //should also save it, so it can be re-loaded later
        Storage.GetInstance().SaveValue("brush_color", this.RGBA255ToString(r,g,b,a));
        

    }
    RGBA255ToString(r,g,b,a)
    {
        return r+","+g+","+b+","+a;
    }
    //change rgba to object with rgba properties
    RGBA255StringToRGBA(rgba)
    {
        // let r,g,b,a;
        let [r,g,b,a] = rgba.split(",");
        // let {r,g,b,a} = rgba.split(",");
        return{r:r, g:g, b:b, a:a};
    }


}