export function HideWindows()
{
    (document.querySelector("#windows")  as HTMLElement).style.display = "none";
}
export function ShowWindows()
{
    (document.querySelector("#windows")  as HTMLElement).style.display = "block";
}
//@ts-ignore
window.HideWindows = HideWindows;
//@ts-ignore
window.ShowWindows = ShowWindows;