import { useEffect, useRef, useState } from 'react'

//if you import a css file, it imports it for all components, every where- even if this component isn't loaded, it's styles will get applied to all pages, regardless. 
//so react isn't very smart- you can make it a module, and import it in react ways for everything, or have to import it dynamically after loading
// import './DrawingArea.css'


// import { SketchPicker } from 'react-color';
import { RgbaColorPicker } from "react-colorful";

import Main from "../PaintProgram/3_brush_controls/main";
import { SaveProject } from '../PaintProgram/3_brush_controls/UI/windows/SaveProject';
import { useLocation, useNavigate } from 'react-router-dom';
import { userStore } from '../App';
import { GET, MyFetchRequest, POST } from '../Helpers';
import { SUB_FOLDER, URL_BACKEND } from '../CONSTANTS';
import MainMenu from '../PaintProgram/3_brush_controls/UI/MainMenu';
import { AppBar, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormHelperText, IconButton, Menu, MenuItem, TextField, Toolbar, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { default as MuiLink } from '@mui/material/Link';
import Theme from './Layouts/Theme';

/**
 * Drawing area- basically everything from the previous drawing app / "ArtClashWebBased"
 * @returns 
 */
let scripts_to_load = ["https://cdnjs.cloudflare.com/ajax/libs/three.js/r126/three.js"/* ,"./assets/JS/jscolor.js" */];
let script_tags:Array<any> = [];

enum p_type {drawing=1, challenge_entry}

function DrawingArea(props) 
{

    //for saving project details:
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [nsfw, setNSFW] = useState(true);
    const [modal_save_open, setModalSaveOpen] = useState(false);


  const [count, setCount] = useState(0);
//   const [type_of_project, setTypeOfProject] = useState(p_type.drawing);

  //pretty sure I used useref because the component can get loaded/ "rerendered" multiple times, and useref actually persists instead of getting called multiple times
  const scripts_loaded = useRef(false);


  const [color, setColor] = useState({r:255,g:0,b:0,a:1})
  const [color_picker_open, setColorPickerOpen] = useState(false);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });

  const [image_URL, setImageURL] = useState("");


  const location = useLocation();
  const { state } = location;

  //for being able to change route to the drawing page
  const navigate = useNavigate();

  if(state)
    console.log("state, before oneffect: ", state);

    //zustland
    let user = userStore((state) => state.user);
    console.log("user, before oneffect: ", user);

    //here we can call init
    

    const SubmitToServer = async ()=>{
        if(state.entry)
            await SubmitEntryToServer();
        else
            await SubmitProjectToServer();
    }
    const SubmitProjectToServer = async()=>{
        console.log("project: ", state.project);
        let image_name = state.project.path as string;
        if(image_name) //if we got one from the server
        {
            image_name= image_name.substring(image_name.lastIndexOf("/")+1);
            image_name= image_name.substring(0, image_name.indexOf("."));
            console.log("image name: ", image_name, "path: ", state.project.path);
        }
        else //just use the timestamp
            image_name = new Date(state.project.created_at).getTime().toString();

        let img = SaveProject.GetInstance().GetImage(image_name);

         //send user token, challenge id, entry id, and image to the server
    let response = await MyFetchRequest(URL_BACKEND+"images/upload/", POST, {
        token:user.token, 
        
        timestamp:image_name,

        // name:state.project.name,         
        // desc:state.project.desc, 
        // nsfw:state.project.nsfw, 
        name:title,         
        desc:desc, 
        nsfw:nsfw, 

        image:img, 
        });
console.log("response from server for submitting challange: ", response);

    }
  const SubmitEntryToServer = async ()=>{

    let challenge = state.challenge;
    let entry = state.entry;
    console.log("challenge is: ", challenge, "entry is: ", entry, "user is: ", user);
    
    let name="challenge_"+user.id+"_"+challenge.id+"_"+entry.id;
    let img = SaveProject.GetInstance().GetImage(name);

    let time_elapsed_s=0;//just 0 for now / not tracking time yet
    console.log("img is: ", img);

    let timestamp= Date.now();

    //send user token, challenge id, entry id, and image to the server
    let response = await MyFetchRequest(URL_BACKEND+"challenges/submit", POST, {
                                        token:user.token, 
                                        image:img, 
                                        name:name, 
                                        timestamp:timestamp, 
                                        id_challenge:challenge.id, 
                                        id_entry:entry.id,
                                        time_elapsed_s:time_elapsed_s,
                                        finished:true});
    console.log("response from server for submitting challange: ", response);


  }
  //mainly just for refreshing- get the entry from the server, so we can check the updated_at prop, and see if it's newer or older
  const GetProjectFromServer = async ()=>{
    let response;
    let data =undefined as any;

    console.log("GetProjectFromServer, project:",state.project, "entry: ",state.entry );

    if(state.entry)
        response = await MyFetchRequest(URL_BACKEND+"challenges/entry", GET, {
            token:user.token, 
            id_entry:state.entry.id,
            });
     
    else if(state.project.id)// for new projects, no id
        response = await MyFetchRequest(URL_BACKEND+"images/", GET, {
            token:user.token, 
            id:state.project.id,
            });
    

    if(response)
        data=response.data;

    console.log("Project fetched from server: ", data);

    return data;

  }
  const SaveLocally = ()=>
  {
    if(state.entry)
    {
        let challenge = state.challenge;
        let entry = state.entry;
        let updated_at = new Date().toISOString();
        MainMenu.GetInstance().SaveDexieJSEntry(entry.id, challenge.id, updated_at, /* entry.image.id */state.project.id, title, desc, nsfw.toString()
            /* entry.updated_at */, );
    }
    else
    {
        let project = state.project;
        // let {name, nsfw, desc}=project;
        let updated_at = new Date().toISOString();
        console.log("saving project: ", project)
        MainMenu.GetInstance().SaveDexieJSProject(updated_at, project.id, title, desc, nsfw.toString()/* entry.updated_at */, );
    }
  }
  const LoadNewestImage = ()=>
  {
    if(state.entry)
    /* let entry_local =  */MainMenu.GetInstance().GetEntryDexieJS(state.entry.id);
    else
        MainMenu.GetProjectDexieJS(state.project.id);
    //if local image timestamp is newer than remote, load local, otherwise load remote
    //LoadImage
  }

  const GetImageFromServer = async ()=>{

    if(!props.state.project.path||props.state.project.path=="null") //for a new project/ no image path
    {
        console.log("no image path for project, so not fetching image from url");
        return;
    }

    try
    {
        // let url = URL_BACKEND+props.state.project.path;
        let url = props.state.project.path;// dont need backend url anymore, included in it
        console.log("fetching image: ", url);
        let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'image/*' // Specify the expected image format
        },
      });
      if (!response.ok) 
      {
        throw new Error('Network response for fetching image was not ok');
      }
      let blob = await response.blob();
      // Convert blob to object URL
      console.log("Imageblob: ", blob);
      const imageUrl = URL.createObjectURL(blob);
      console.log("ImageURL: ", imageUrl);
      return imageUrl;
    //   setImageURL(imageUrl) //does seem to work

      //now just have to set the image to it, in the same way load works

      //it uses canvas.toDataURL to get the image as a dataURL
      //it saves as "ImageDataURL", but in the format: data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAYAAAD0eNT6AAAgAElEQVR4Xu2dh5dUxbpHy6skQXIQyTkjOQdRvO+PNjyQJDknAclRomRQee66t+Yd2gHmQJ+erj77rNVrenr6hNpVUL/66gsfvfr7CB4SkIAEJCABCdSKwEcKgFr1t42VgAQkIAEJRAIKAAeCBCQgAQlIoIYEFAA17HSbLAEJSEACElAAOAYkIAEJSEACNSSgAKhhp9tkCUhAAhKQgALAMSABCUhAAhKoIQEFQA073SZLQAISkIAEFACOAQlIQAISkEANCSgAatjpNlkCEpCABCSgAHAMSEACEpCABGpIQAFQw063yRKQgAQkIAEFgGNAAhKQgAQkUEMCCoAadrpNloAEJCABCSgAHAMSkIAEJCCBGhJQANSw022yBCQgAQlIQAHgGJCABCQgAQnUkIACoIadbpMlIAEJSEACCgDHgAQkIAEJSKCGBBQANex0mywBCUhAAhJQADgGJCABCUhAAjUkoACoYafbZAlIQAISkIACwDEgAQlIQAISqCEBBUANO90mS0ACEpCABBQAjgEJSEACEpBADQkoAGrY6TZZAhKQgAQkoABwDEhAAhKQgARqSEABUMNOt8kSkIAEJCABBYBjQAISkIAEJFBDAgqAGna6TZaABCQgAQkoABwDEpCABCQggRoSUADUsNNtsgQkIAEJSEAB4BiQgAQkIAEJ1JCAAqCGnW6TJSABCUhAAgoAx4AEJCABCUighgQUADXsdJssAQlIQAISUAA4BiQgAQlIQAI1JKAAqGGn22QJSEACEpCAAsAxIAEJSEACEqghAQVADTvdJktAAhKQgAQUAI4BCUhAAhKQQA0JKABq2Ok2WQISkIAEJKAAcAxIQAISkIAEakhAAVDDTrfJEpCABCQgAQWAY0ACEpCABCRQQwIKgBp2uk2WgAQkIAEJKAAcAxKQgAQkIIEaEl
    }
    catch(err)
    {
        console.log("Error getting image from server: ", err);
    }

  };

    const Exit = async ()=>
    {
        // navigate(SUB_FOLDER+'Draw', { state: { project:project} });
        //can just go back using react router:
        navigate(-1);

    }

  const PreInit = async ()=>{
    SetupColorPicker(setColor,color, setColorPickerOpen, color_picker_open);

    //try to force it to set the color for the mainmenu to detect the inital color
    console.log("setting color to current color!");
    setColor(color);


    MainMenu.AUTO_LOAD=false; //don't auto load until we check the date on the server
    new Main();

    //check image date locally and on server, and load from server if newer than local one, or load local one if same date


    //should fetch the image from the server

    //should first check local storage for image
    
    // let img = await GetImageFromServer();
    // console.log("setting main menu load image to: ", img);
    // MainMenu.GetInstance().LoadImage(img);//main menu gets setup from Main, has "LoadLocallyClicked", which we can use to send it the image url

    console.log("user is from zustland: ", user)
    if(user)//when saving, it refreshes, but doesn't run the zustand user command again/ it becomes null
        await Init();
    else
        console.warn("Zustand seems to not keep its stores when hot refresh works from zustand, and user becomes null/ never loads.");
  }
  

  const Init = async ()=>{

    let project = await GetProjectFromServer();
    console.log("project from server: ", project);

    let dexie_project;

    if(project)//for new projects, won't have ids
    {
        if(state.entry)
        {
            console.log("INIT: state.entry set to: ", state.entry);
            console.warn("Project is an entry");
            state.entry.updated_at =project.updated_at;
            //load image from dexiejs, and compare updated date
            dexie_project = await MainMenu.GetInstance().GetEntryDexieJS(project.id);
        }
        else 
        {
            console.warn("Project is normal/ not an entry");
            
            state.project.updated_at =project.updated_at;

            
            dexie_project = await MainMenu.GetProjectDexieJS(project.id);
            console.log("trying to load dexie project with id: ", project.id)
        }
    }
    

    
    console.log("dexie_project: ", dexie_project);

    let load_from_server=true;
    if(!dexie_project)
    {
        console.log("Loading image, not at all, since dexie project not found");
    }
    else if(new Date(dexie_project.updated_at)< new Date(project.updated_at))
    {
        console.log("Loading image from server, since newer than local, local: ", new Date(dexie_project.updated_at), "server: ", new Date(project.updated_at));

    }
    else //load image locally
    {
       
        load_from_server=false;
        //load locally if server is older, or if same date
        console.log("Loading image locally, since local is same or newer. Local:  ", new Date(dexie_project.updated_at), "server: ", new Date(project.updated_at));
        let img = dexie_project.image;

        MainMenu.GetInstance().LoadImage(img);//main menu gets setup from Main, has "LoadLocallyClicked", which we can use to send it the image url

        //also load name, desc, and nsfw locally
        setTitle(dexie_project.name);
        setDesc(dexie_project.desc);
        let nsfw = true;
        if(dexie_project.nsfw)
            nsfw = dexie_project.nsfw=== "true"
        console.log("nsfw = ", nsfw);
        setNSFW(nsfw);//should come in as a boolean, but also checking if string
    }


    //compare dates
    if(load_from_server)
    {
        //load from server if server is newer
        
        let img = await GetImageFromServer();
        
        if(img)
            MainMenu.GetInstance().LoadImage(img);//main menu gets setup from Main, has "LoadLocallyClicked", which we can use to send it the image url
        else
        {
            console.log("didn't find an image on server");
            //new image, create one
            // MainMenu.GetInstance().NewClicked();
        }

        console.log("Load from server, project: ",project)

        //also load name, desc, and nsfw from server
        setTitle(project.name||(state.challenge&&state.challenge.name));
        setDesc(project.desc||(state.challenge&&state.challenge.desc));
        setNSFW(project.nsfw|| project.nsfw=== "true");
        // console.log("nsfw: ", project.nsfw)
    }
  };

  const HandleProjectType=()=>{

    let image;

    
    /* if(state.entry)//for challenges/ entries opened in challenge area/ not through my projects
    {
        console.log("setting to entry"); */
        image = state.project;
        /* setTypeOfProject(p_type.challenge_entry);
    }
    else
    {
        console.log("setting to drawing");
        image=state.project;
        setTypeOfProject(p_type.drawing);
    }
    console.log("HandleProjectType: state: ", state, "type of project: ", type_of_project) */

     //crap- when I refresh the page, it'll just show the old state- so old entry time- so won't work with refreshing the page, have to go back, then forward
     if(image)
    {
        let updated_at_server= image.updated_at;
        console.log("updated_at_server: ", updated_at_server, "in local time: ", new Date(updated_at_server).toLocaleString());
    }
    
        
    
       
    
        // have to have the entire function async, so it will load the scripts into the head before continuing
        (async ()=>{
    
            PreInit();
    
            // await LoadScripts(scripts_loaded);
    
            try {
                //it errors out when refreshing/ element already has color picker on it, so complains- even though everything should be reloaded
                // InitJSPicker();
                
            } catch (error) {}
    
            //@ts-ignore
            // jscolor.install();
    
           
          
            
    
        // Cleanup function to remove the script element when the component unmounts
        return () => {
    
            RemoveScripts();
        };
        })();

  }


  useEffect(() => {

    console.log("mounting css");
    //to load a css file like normal, have to load it dynamically, otherwise react loads it on initial creation, and applies it to all pages and components
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = './assets/DrawingArea.css';
    document.head.appendChild(link);


    // console.log("Drawing area, props: ", props);
    console.log("Drawing area, state: ", state);//state is inside of props

    HandleProjectType();
    

   

    return () => {
        document.head.removeChild(link);
      };


  }, 
  // Empty dependency array ensures the effect runs only once
  //changed to watch user, otherwise when vite refreshes the page, it runs useeffect, then later actually runs the zustand to get user, whereas on first load, it does the opposite
  [user]); 



  return (
    <>
    
    <Theme>
    <canvas id="c"></canvas>
    <p id="fps">120</p>

    <div id="UI">
        {/* <p>user: 
        {JSON.stringify(user)}
        </p> */}

        {/* Old save UI on top left before material UI */}
        {/* <button type="button" id="submit_to_server" onClick={SubmitToServer}>Submit to server</button>
        <button type="button"  onClick={SaveLocally}>Save Locally</button> */}


        {/* <button type="button"  onClick={LoadNewestImage}>Load Locally</button> */}
        
        {
            // image_URL&&<img src={image_URL}/>
        }


        {/* <button type="button" id="new">New</button> */}
        {/* <button type="button" id="save">Save</button> */}{/* MainMenu.ts #56 is where event handler is added */}
    
        {/* <button type="button" id="load">Load</button> */}

        <button type="button" id="brush_size" style={{marginLeft:"100px"}}>10px</button>

        <div id="js_color_picker_stuff" style={{display: "inline-block"}} >
            <button id="color_picker" 
                style={{backgroundColor:`rgb(${color.r}, ${color.g}, ${color.b})`}} 
                onClick={()=>{setColorPickerOpen(!color_picker_open)}}>
                    &nbsp;
            </button>

            {
                color_picker_open&&<RgbaColorPicker color={color} onChange={(color)=>{setColor(color);ColorOnChanged(color);}} />
            }
            {/* {
                <p style={{color:"white", position:"absolute", left:"0"}}>
                    Color picker open: {JSON.stringify(color_picker_open)}
                </p>
            } */}
            
        </div>
        
    </div>


    <div id="material_ui_ui">
      <AppBar position="static" sx={{paddingTop:"0px"}}>
        <Toolbar style={{minWidth:"auto", maxWidth:"max-content"}}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={(event)=>{
                const rect = event.currentTarget.getBoundingClientRect();
                setAnchorPosition({
                  top: rect.bottom,
                  left: rect.left,
                });
                setMenuAnchorEl(event.currentTarget); // Sets the anchor element to the button that was clicked
            }
            /* handleMenuClick */}
          >
            <MenuIcon />
          </IconButton>
          
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={()=>{setMenuAnchorEl(null); // Resets the anchor element to null to close the menu}/* handleMenuClose */
            }}

            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={()=>{
                setMenuAnchorEl(null);//close menu
                setModalSaveOpen(true);}/* handleSaveLocally */}>Save</MenuItem>
            <MenuItem onClick={()=>{
                setMenuAnchorEl(null);//close menu
                
                //should have modal to confirm exit, especially if not saved

                Exit();

            }/* handleUploadToServer */}>Exit</MenuItem>
            {/* <MenuItem><MuiLink href='#' onClick={()=>{}}>Logout</MuiLink></MenuItem> */}

          </Menu>
        </Toolbar>
      </AppBar>

      {/* Modal for saving a project */}
      <Dialog open={modal_save_open} onClose={()=>{setModalSaveOpen(false)}}>
      <DialogTitle>Enter Details</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => {setTitle(e.target.value)}}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={desc}
          onChange={(e) => {setDesc(e.target.value)}}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={nsfw}
              onChange={(e) => {setNSFW(e.target.checked)}}
              name="nsfw"
            />
          }
          label="Mature Content? (nudity, violence, not kid friendly)"
        />
        {/* {error && <FormHelperText error>{error}</FormHelperText>} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{setModalSaveOpen(false)}} color="primary">
          Cancel
        </Button>
        <Button onClick={()=>{
            SubmitToServer();
            
            //should have modal that shows they saved it

            setModalSaveOpen(false);
            }} color="primary">
          Upload to Server and exit
        </Button>
        <Button onClick={()=>{
            /* handleSave */
            SaveLocally();

            //should have a new modal that shows they saved it up for a second

            setModalSaveOpen(false);
            }} color="primary">
          Save On Device
        </Button>
      </DialogActions>
    </Dialog>
    </div>

    <div id="windows">
        <div className="overlay"></div>


        <div id="register" className="window">
            <p>User Name</p><input type="text" className="user_name"/>
            <p>Email </p><input type="text" className="email"/>
            <p>Password </p><input type="password" className="password"/>
            <button onClick={()=>{Register();}}>Create Account</button>
            <p> Or if you already have an account, <a href="#" onClick={()=>{ShowLogin();}}>click here to login</a></p>
        </div>


        <div id="login" className="window">
            <p>User Name or email</p><input type="text" className="user_name"/>
            <p>Password </p><input type="password" className="password"/>
            <button onClick={()=>{Login();}}>Login</button>
            <p> Don't have an account?  <a href="#" onClick={()=>{ShowRegister();}}>click here to register</a></p>
        </div>


        <div id="modal" className="window">
            <p>Do you really want to exit and destroy everything? </p>
            
            <div className="btns">
                <button className="no">No</button>
                <button className="yes">Yes</button>
            </div>
        </div>



        <div id="SaveProject" className="window" style={{}}>
            <div className="title_bar">
                <div className="btn_back btn"> &#8249; </div>
                <h3 className="title">Save Project</h3>
            </div>

            <div className="content">
                <input type="text" placeholder="Title..."/>
                <textarea placeholder="Description…"></textarea>
                <p>Mature Content? (nudity, violence, not kid friendly) </p>

                
                <label><span>Yes</span><input type="radio" name="nsfw" value="true" checked onChange={()=>{}}/></label>
                <label><span>No</span><input type="radio" name="nsfw" value="false"/></label>

                <p className="error_message">
                    You must enter a title
                </p>
                
                
                
                <button className="btn_save">Save</button>
                <button className="btn_upload">Upload To Server</button> {/* SaveProject.ts #47, calls Upload */}
            </div>            
        </div>


        
    </div>{/* end of windows */}

    </Theme>
    
    </>
  )
}

function Register(){}
function ShowLogin(){}
function Login(){}
function ShowRegister(){}


async function LoadScripts(scripts_loaded)
{
    if(scripts_loaded.current)
        return;
    for (let i = 0; i < scripts_to_load.length; i++) 
    {
        // Set the source attribute to the CDN URL
        //have to be in very particular order to get script tags to load, and fire onload event
        //https://stackoverflow.com/questions/16230886/trying-to-fire-the-onload-event-on-script-tag
        //set the src attribute after the onload event,
        //append the script to the DOM before attaching the onload event
        const src = scripts_to_load[i];
        const script = document.createElement('script');
        document.head.appendChild(script);
        console.log("running func!");
        let promise = new Promise((resolve, reject)=>{
        // Create a new script element

        script_tags.push(script);

        
        script.onload = resolve;
        script.onerror = reject;
        } );
        script.src = src;
        await promise;
        console.log("loaded script" , src);

        // Append the script element to the document's head
    }
    console.log("done loading scripts");

    scripts_loaded.current=true;
}
async function RemoveScripts()
{
    for (let i = 0; i < script_tags.length; i++) 
    {
        const src = script_tags[i];
        document.head.removeChild(src);
    }
}


 /* to have some function register to be called when the color is changed from the UI*/
 let color_changed_from_ui_callbacks = new Array();
 let setColorInReact;
 let current_color;

 let setColorPickerOpenInReact;
function SetupColorPicker(setColor, color, setColorPickerOpen, color_picker_open)
{
    (window as any).color_changed_from_ui_callbacks=color_changed_from_ui_callbacks;
    (window as any).RegisterColorChangedFromUI= RegisterColorChangedFromUI;
    (window as any).SetJSColorString= SetJSColorString;
    (window as any).DrawingOnCanvas= DrawingOnCanvas;

    setColorInReact=setColor;
    current_color=color; //for the paint surface to get the color currently when registering for callbacks
    setColorPickerOpenInReact=setColorPickerOpen;
}
//color picker changed callback
function ColorOnChanged(color)
{
    console.log("ColorOnChanged", color)

    //somehow react-colorful will have alpha be NaN if it's 0, so need to check for it and set it
    if(isNaN(color.a))
        color.a=0;

    color_changed_from_ui_callbacks.forEach(element => {
        element(color.r,color.g,color.b,color.a);
    });

    //now have to let drawing program know the color
}

//called from "colorchange.ts"
 function RegisterColorChangedFromUI(callback)
 {
    // throw new Error("figuring out shit");
     console.log("registering function to run when color is changed!");
     color_changed_from_ui_callbacks.push(callback);

     //should set the current color that we already have- for color picker to get set with what is already there when registering - since brush color isn't being set on load automatically
     callback(current_color.r,current_color.g,current_color.b,current_color.a);
     console.log("call back being called with current color: ", current_color);
 }
 /* called from PaintProgram */
 function SetJSColorString(rgba_str:string)
{
    // myPicker.fromString(rgba_str);
    //need to get what is in the parenthesis, regardless of length- the 2nd paranthesis changes positions
    let para2_i= rgba_str.indexOf(")");
    console.log("setting color, index of 2nd par: ", para2_i)
    let rgba = rgba_str.substring(5, para2_i).split(",").map((val)=>parseFloat(val));
    console.log("setting color2 in react from outside of react with string: ", rgba_str, rgba);
    setColorInReact({r:rgba[0], g:rgba[1], b:rgba[2], a:rgba[3]});

}
  /* called from PaintProgram */
function DrawingOnCanvas()
{
    // console.log("closing color picker!", "color_picker_openInReact: ", color_picker_openInReact)
    // if(color_picker_openInReact) // can't check the value/ gets replaced, but can use the function
    setColorPickerOpenInReact(false);
    // console.log("closing color picker2!", "color_picker_openInReact: ", color_picker_openInReact)
}



/**
 * JSPICKER functions
 */
var myPicker:any;
/* function InitJSPicker()
{
    //@ts-ignore
    myPicker = new JSColor('#color_picker', {format:'rgba'});

    // myPicker.presets.default = {backgroundColor: '#333',};

    let color_picker_width = document.body.clientWidth-88;//the size of the brightness and opacity bars + spacing
    let max_width = 256;
    if(color_picker_width>max_width)
        color_picker_width=max_width;

    myPicker.option({
        value:'rgba(0,0,0,1)',
        onChange: 'JSColorOnChanged(this)',
        onInput: 'JSColorOnInput(this)',
        // preset:'dark',
        width: color_picker_width,
        height:250,
        padding: 10, sliderSize: 25, borderRadius: 5,
        borderWidth: 1, controlBorderWidth: 1, pointerBorderWidth: 2,
        borderColor: '#000', controlBorderColor: '#CCC', backgroundColor: '#333',
        smartPosition: false,
        position: 'bottom',   
    });
    myPicker.fromRGBA(0,0,0,1);

    //@ts-ignore
    jscolor.ready(()=>{console.log("JSCOLOR READY!")}) ;

    (window as any).JSColorOnChanged=JSColorOnChanged;
    (window as any).JSColorOnInput=JSColorOnInput;
    console.log("InitJSPicker finished!");
} */

/* function JSColorOnInput(picker)
{
    console.log("picker: ", picker);
} */


 /* function SetJSColor(r,g,b,a)
 {
     let rgba_str = `rgba(${r},${g},${b},${a})`;
     console.log("SetJSColor: "+rgba_str)
     myPicker.fromString(rgba_str);
 }
 function SetJSColorString(rgba_str)
 {
     myPicker.fromString(rgba_str);
 } */

 /*
 A bunch of react based color pickers:
 https://www.npmjs.com/package/react-color (lots of different style color pickers, no dark mode, 3yrs ago) https://casesandberg.github.io/react-color/
 https://www.npmjs.com/package/react-color-palette (simple, input fields, dark mode - 7 months ago)

 https://www.npmjs.com/package/react-colorful (simple, no input, but can add, small, accessible, mobile based, etc - 2yrs ago)
 */


export default DrawingArea
