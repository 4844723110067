import { useEffect, useState } from 'react'
import "./NavBar.css";

/* 
Gallery for showing thumbnails in a list
*/

//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { Link, useNavigate } from 'react-router-dom';
import GoogleSignIn from '../GoogleSignIn';
import { SUB_FOLDER, URL_BACKEND } from '../../CONSTANTS';
import { userStore } from '../../App';
import UserInterface, { UserData } from '../../User';

import logo from "../../assets/logo.png"
import logo_no_txt from "../../assets/logo_no_txt.png"
import logo_txt from "../../assets/logo_text.png"
import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';

// import {Link as LinkMat} from "@mui/material/Link";
import { default as MuiLink } from '@mui/material/Link';


function NavBar() 
{
    // let {user} = props;

    const user = userStore((state) => state.user);
    const setUser = userStore((state) => state.setUser);

  const [count, setCount] = useState(0);
  const [gallery_items, setGalleryItems] = useState(new Array());

  function SignOut()
  {
    console.log("signing out!");
    UserInterface.SignOut();
    setUser(null);
  }


  useEffect(() => {
    

    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount


    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    //for being able to change route to the drawing page
    //have to declare it here/ in the body of the function, or can't be called subsequently
    const navigate = useNavigate();
    // const Navigate = ()=>{

        
    // }



    //from "myentries.tsx", for joining a challenge + opening up drawing
   

  return (
    <>
        

        <AppBar position="static">
        <Toolbar style={{paddingRight:"0", display:"flex"}}>
        {/* Logo */}
        <Box
        sx={{transition:".2s all", "&:hover":{transform:"scale(1.1)"}}}
        >
            <MuiLink component={Link} to={SUB_FOLDER}
            
            ><img src={logo_no_txt} style={{maxHeight:"40px"}}/><img src={logo_txt} style={{maxHeight:"40px"}}/></MuiLink>
            {/* Menu icon for mobile */}
        {/* <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton> */}
        </Box>

        {
        user&&user.token?
        (
            // box for when logged in/ create new drawing and account
            <Box 
            sx={{display:"flex", flex:1, alignItems: "center"}}
            >
                <Button variant='contained' sx={{marginLeft:"auto", marginRight:"auto"}}
                //should start a new drawing
                onClick={()=>{
                    //won't let us call usenavigation directly/ counts as a hook that needs to be used in one of the main functions...
                    // Navigate();
                    navigate(SUB_FOLDER+'MyProjects', { state: { project:"don't need, just sending over state"} });
                }}
                >
                    View or Create a Drawing
                </Button>

                <Box
                sx={{}}
                >
                    {/* User picture or icon */}
                    <IconButton color="inherit" onClick={handleMenuOpen} style={{/* paddingRight:"0" */}}>
                    <AccountCircle style={{ fontSize: '3rem' }} />
                    </IconButton>

                    {/* Menu for portrait*/}
                    <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    >
                    <MenuItem><MuiLink component={Link}  to={SUB_FOLDER+"Profile"} state={{ user: user }}>Profile</MuiLink></MenuItem>
                    <MenuItem><MuiLink href='#' onClick={()=>{SignOut();}}>Logout</MuiLink></MenuItem>
                    </Menu>
                </Box>
            </Box>
            ):
            (
                // <li><Link to="/Login">Login</Link></li>
                <GoogleSignIn/>
            )
        }
        
        
        

        </Toolbar>
        </AppBar>

    
        {/* <nav>
            <ul>
                <li><MuiLink component={Link} to={SUB_FOLDER}><img src={logo_no_txt} style={{maxHeight:"40px"}}/><img src={logo_txt} style={{maxHeight:"40px"}}/></MuiLink></li>
                {
                    user&&user.token?
                    (
                        <li className='drop_down'>
                            <img className='avatar' src={URL_BACKEND+user.avatar}/>
                            <ul >
                                <li><MuiLink component={Link} to={SUB_FOLDER+"Profile"} state={{ user: user }}>Profile</MuiLink></li>
                                <li><a href='#' onClick={()=>{SignOut();}}>Logout</a></li>
                            </ul>
                            </li>
                    ):
                    (
                        // <li><Link to="/Login">Login</Link></li>
                        <li><GoogleSignIn/></li>
                    )
                }
                
            </ul>
        </nav> */}
    
    
    
    </>
  )
}

export default NavBar
