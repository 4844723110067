import { useEffect, useState } from 'react'
import reactLogo from './assets/react.svg'
import viteLogo from '/vite.svg'
// import './App.css'
import GoogleSignIn from './components/GoogleSignIn'
import DrawingArea from './components/DrawingArea'
import Gallery from './components/Gallery'
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'

//router 6 renamed ' switch' to 'routes'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './routes/Home'
import Profile from './routes/Profile'


import { create } from 'zustand'
import UserInterface, { UserData } from './User'
import Login from './routes/Login'
import AChallenge from './components/Challenges/AChallenge'
import HeaderAndFooter from './components/Layouts/HeaderAndFooter'
import Blank from './components/Layouts/Blank'
import Draw from './routes/Draw'
import MyEntries from './components/Challenges/MyEntries'
import { SUB_FOLDER } from './CONSTANTS'
import { CssBaseline, GlobalStyles, ThemeProvider, createTheme } from '@mui/material'
import MyProjects from './components/MyProjects'
import TestZoomPan from './PaintProgram/3_1_test_zoom_pan/test_zoom_pan_threejs'




/*
Zustand Stores

have to do it more complicated with typescript:
https://github.com/pmndrs/zustand/issues/656
(defining the type first)
*/

/* interface UserInfo
{
    token:string;
    user_name:string,
    id:string
} */

type UserState = {
    user:UserData,
    setUser: (user) => void,


    // user_interface:UserInterface,
    // setUserInterface: (user_interface) => void,
}

export const userStore = create<UserState>((set) => ({
    user: null,
    setUser: (user) => set({ user }),

    // user_interface: null,
    // setUserInterface: (user_interface) => set({ user_interface }),
  }));

// end of zustand stores


function App() 
{
  const [count, setCount] = useState(0);
  

  // const [signedin, setSignedIn] = useState(false);
//   const [signedin, setSignedIn] = useState(false);
  const [mounted, setMounted] = useState(false);

//   const [user_interface, setUserInterface] = useState(null);

  //zustand
  const setUserZust = userStore((state) => state.setUser);

//   const setUserInterfaceZust = userStore((state) => state.setUserInterface);

  useEffect(() => {

    
    //1st get if they are signed in

    //WARNING- USEstate is async- so setting variables with it will skip/ be null until some time in the future, so have to await
    let user_interface = new UserInterface();
    // setUserInterfaceZust(user_interface);
    
    // setUserInterface(user_interface);

    //set the user info if logged in
    user_interface.LoadUser().then(vals=>{
        

        setUserZust(vals);
        console.log("set user to", vals);
        

        // console.log("mounted set to true")
        setMounted(true);

    });
    
    
    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount


  

  return (
    <>
    

    <Router>

{/* Have to put in all the possible routes for the entire app here */}

{mounted&&(
    <Routes>

        
                <Route path={SUB_FOLDER} element={<Home />} />
                {/* <Route path="/Profile/" element={<Profile />} /> */}
                <Route path={SUB_FOLDER+"Login"} element={<Login />} />


                <Route path={SUB_FOLDER+"Challenge/:id"} element={<AChallenge />} />
                

                {/* my entries are after you go into a challenge, then edit your own entries. Achallenge is just the challenge, and everyones entries */}
                <Route path={SUB_FOLDER+"Challenge/:id/MyEntries"} element={<MyEntries />} />

                <Route path={SUB_FOLDER+"MyProjects"} element={<MyProjects />} />

                <Route path="*" element={<div>Page Not found, catch all!</div>} />
            
                     
                <Route path={SUB_FOLDER+"Profile/"} element={<Profile />} />
                <Route path={SUB_FOLDER+"Draw/"} element={<Draw />} />

                {/* For testing */}
                <Route path={SUB_FOLDER+"TestZoomPan/"} element={<TestZoomPan />} />

                
            
          
          
            
      </Routes>
)
}


    {
        
    }
    


      {/* <ul>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/Profile">Profile</Link></li>
      <li><Link to="/Profile" state={{data:{ id: 1, name: 'Example' }}}>Profile with info</Link></li>
      </ul>   */}



      
    </Router>

    {/* <Gallery/>
    <DrawingArea/> */}

    {
      // wait for the parent to mount before checking the url to see if they should sign in
    //   !signedin&&mounted?
    //   (<GoogleSignIn SignedIn={user_interface.SignedIn}/>)
    //   :

    }
    
      {/* <div>
        <a href="https://vitejs.dev" target="_blank">
          <img src={viteLogo} className="logo" alt="Vite logo" />
        </a>
        <a href="https://react.dev" target="_blank">
          <img src={reactLogo} className="logo react" alt="React logo" />
        </a>
      </div>
      <h1>Vite + React</h1>
      <div className="card">
        <button onClick={() => setCount((count) => count + 1)}>
          count is {count}
        </button>
        <p>
          Edit <code>src/App.tsx</code> and save to test HMR
        </p>
      </div>
      <p className="read-the-docs">
        Click on the Vite and React logos to learn more
      </p> */}
      
    </>
  )
}

export default App
