import RenderSurface from "./RenderSurface";
import MouseAndTouch from "./screen_interaction/MouseAndTouch";
import PaintOnSurfGeneral from "./screen_interaction/PaintOnSurfGeneral";
import StartThreeJS from "./StartThreeJS";
import * as THREE from 'three';
import { Texture, Vector2, WebGLRenderTarget } from "three";
import MainMenu from "./UI/MainMenu";

import "./UI/windows/Register"; //to force it to import
import "./UI/windows/Login"; //to force it to import
import UserData from "./UserData";
import ColorChange from "./UI/ColorChange";
import ZoomPanGeneral from "./screen_interaction/ZoomPanGeneral";


/**
 * This class is based on Assets/Scenes/DrawingScene.cs from the unity project. Have to keep filling it in
 */
export default class Main
{
    start_three_js:StartThreeJS;
    render_surface:RenderSurface;
    mouse_and_touch:MouseAndTouch;
    zoom_pan:ZoomPanGeneral;

    paint_on_surf:PaintOnSurfGeneral;

    brush_texture:Texture;

    //for fps
    fps_txt_element = document.getElementById("fps") as HTMLElement;

    user_data:UserData;

    images_loaded:boolean=false;//for knowing when everything is ready to go/ images loaded- for other classes to be able to set things on the brush/ images 


    static instance:Main;
    static GetInstance():Main
    {
        return Main.instance;
    }

    constructor()
    {
        Main.instance=this;
        this.user_data = UserData.GetInstance();

        Vector2.prototype.toString = function(){return (this as any).x+", "+(this as any).y};

        // @ts-ignore:
        Vector2.prototype.setV = function(vec:Vector2){this.x=vec.x;this.y=vec.y;};
        let test:Vector2 = new Vector2(20, 30);
        console.log("test vector2: ", test);



        this.start_three_js = new StartThreeJS(document.querySelector('#c') as HTMLElement);
        this.render_surface = new RenderSurface(this.start_three_js, this.start_three_js.scene);

        this.start_three_js.quad_to_keep_onscreen=this.render_surface.quad;
        // console.log("quad_to_keep_onscreen: ", this.start_three_js.quad_to_keep_onscreen);

        // this.mouse_and_touch = new MouseAndTouch(this.start_three_js.canvas, this.start_three_js.scene, this.start_three_js.camera);
        this.mouse_and_touch = new MouseAndTouch(this.start_three_js);

        this.zoom_pan = new ZoomPanGeneral();
        this.zoom_pan.AddScaleChangedDelegate(this.ScaleChanged, this);
        this.zoom_pan.AddOffsetChangedDelegate(this.OffsetChanged, this);

        this.paint_on_surf = new PaintOnSurfGeneral();


        //TODO: put this into InitDrawing, based on DrawingScene.cs 
        //TODO: this should get passed in to the InitDrawing function. For now just using the same size we have set in render surface, 512x512:
        let canvas_size_x=512;let canvas_size_y=512;
        this.zoom_pan.New(canvas_size_x, canvas_size_y);



        //initialize UI components
        new MainMenu();
        

        const loader = new THREE.TextureLoader();
        loader.load(
            // resource URL
            './assets/images/circle_soft256.png',
            // './images/cat_brush.png',
            // './images/brush_test.jpg',
        
            // onLoad callback
            (texture)=> {
                this.brush_texture=texture;
                console.log("loaded brush texture");

                this.paint_on_surf.SetBrushTex(texture,  this.render_surface);

                this.paint_on_surf.SetBrushSize(15);
                MainMenu.instance.SetBrushSizeText(15);
                
                MainMenu.instance.ThreeJSInitialized();

                this.ImagesLoaded();

                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                    transparent: true,
                  });

                // const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
            // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00, map: this.bufferMaterial.uniforms._BrushTex.value } );
            // const material = new THREE.MeshBasicMaterial( {  map: texture } );

            // const geometry = new THREE.BoxGeometry( 100, 100, 1 );
            
            // const cube = new THREE.Mesh( geometry, material );
            // cube.translateX(-500);
            // this.start_three_js.scene.add( cube );
            // console.log("added test material!");

                // in this example we create the material when the texture is loaded
                // const material = new THREE.MeshBasicMaterial( {
                //     map: texture
                //  } );
            },
        
            // onProgress callback currently not supported
            undefined,
        
            // onError callback
            function ( err ) {
                console.error( 'An error happened. when trying to load brush texture. Maybe missing brush texture?',err );
            }
        );


        
        
    }
    GetCurrentImageDataURL():string
    {
        return this.render_surface.GetImageDataURL();
    }
    GetCurrentImagePNG():File
    {
        return this.render_surface.GetImagePNG();
    }
    GetCurrentRenderSurface():RenderSurface
    {
        return this.render_surface;
    }
    //from main menu- to send over when the texture has been loaded from file, for us to have the render surface overwrite with this
    OverwriteWithTexture(texture)
    {
        this.render_surface.OverwriteWithTexture(texture);
    }

    MouseTest()
    {
        if(this.count_frames==10)
        {
            const array = this.mouse_and_touch.getMousePositionPercent( this.mouse_and_touch.canvas, 464, 267);
            const onClickPosition = new THREE.Vector2();
            onClickPosition.fromArray( array );
            this.mouse_and_touch.onMouseDown2(onClickPosition);
        }
        // else if(this.count_frames==11)
        // {

        //     this.mouse_and_touch.onMouseUp2(null);
        // }
        // else if(this.count_frames==12)
        // {
        //     const array = this.mouse_and_touch.getMousePosition( this.mouse_and_touch.canvas, 795, 714);
        //     const onClickPosition = new THREE.Vector2();
        //     onClickPosition.fromArray( array );
        //     this.mouse_and_touch.onMouseDown2(onClickPosition);
        // }
        // else if(this.count_frames==13)
        // {

        //     this.mouse_and_touch.onMouseUp2(null);
        // }
        else if(this.count_frames==11)
        {
            const array = this.mouse_and_touch.getMousePositionPercent( this.mouse_and_touch.canvas, 360, 500);
            const onClickPosition = new THREE.Vector2();
            onClickPosition.fromArray( array );
            this.mouse_and_touch.onMouseMove2(onClickPosition);
        }
        else if(this.count_frames==12)
        {
            const array = this.mouse_and_touch.getMousePositionPercent( this.mouse_and_touch.canvas, 795, 300);
            const onClickPosition = new THREE.Vector2();
            onClickPosition.fromArray( array );
            this.mouse_and_touch.onMouseMove2(onClickPosition);
        }
        else if(this.count_frames==13)
        {
            const array = this.mouse_and_touch.getMousePositionPercent( this.mouse_and_touch.canvas, 700, 714);
            const onClickPosition = new THREE.Vector2();
            onClickPosition.fromArray( array );
            this.mouse_and_touch.onMouseMove2(onClickPosition);
        }
        
        else if(this.count_frames==14)
        {

            this.mouse_and_touch.onMouseUp2(null);
        }
    }

    count_mouse_steps=0;
    // mouse_pts:Array<Vector2> = [new Vector2(737, 383), new Vector2(730, 402), new Vector2(708, 440), new Vector2(691, 466), new Vector2(671, 499), new Vector2(658, 522), new Vector2(652, 534), new Vector2(652, 536), new Vector2(652, 536), new Vector2(653, 532), new Vector2(658, 510), new Vector2(656, 477), new Vector2(635, 440), new Vector2(591, 397), new Vector2(530, 356), ];
    // mouse_pts:Array<Vector2> = [  new Vector2(652, 534), new Vector2(652, 536), new Vector2(652, 536), new Vector2(653, 532), new Vector2(658, 510), new Vector2(656, 477), new Vector2(635, 440), new Vector2(591, 397), new Vector2(530, 356), ];
    
    mouse_array_num:number=0;
    //odd spacing for 2 of them
    mouse_pts_array:Array<Array<Vector2>> =[
    [new Vector2(497, 727), new Vector2(497, 727), new Vector2(497, 725), new Vector2(497, 719), new Vector2(498, 705), new Vector2(500, 671), new Vector2(500, 664), new Vector2(503, 639), new Vector2(508, 615), new Vector2(513, 593), new Vector2(515, 578), new Vector2(515, 567), new Vector2(515, 562), new Vector2(515, 561), ],
    [new Vector2(635, 726), new Vector2(635, 726), new Vector2(635, 723), new Vector2(633, 711), new Vector2(631, 667), new Vector2(631, 654), new Vector2(630, 620), new Vector2(630, 592), new Vector2(630, 573), ]
    ];
    // mouse_pts:Array<Vector2> = [new Vector2(497, 727), new Vector2(497, 727), new Vector2(497, 725), new Vector2(497, 719), new Vector2(498, 705), new Vector2(500, 671), new Vector2(500, 664), new Vector2(503, 639), new Vector2(508, 615), new Vector2(513, 593), new Vector2(515, 578), new Vector2(515, 567), new Vector2(515, 562), new Vector2(515, 561), /*Second set */new Vector2(635, 726), new Vector2(635, 726), new Vector2(635, 723), new Vector2(633, 711), new Vector2(631, 667), new Vector2(631, 654), new Vector2(630, 620), new Vector2(630, 592), new Vector2(630, 573), ];
    MouseTestGaps()
    {
        if(this.mouse_array_num<this.mouse_pts_array.length)
        {
            let mouse_pts = this.mouse_pts_array[this.mouse_array_num];
            console.log("testing mouse array: "+this.mouse_array_num);
            if(this.count_mouse_steps==0)
            {
                const array = this.mouse_and_touch.getMousePositionPercent( this.mouse_and_touch.canvas, mouse_pts[this.count_mouse_steps].x, mouse_pts[this.count_mouse_steps].y);
                const onClickPosition = new THREE.Vector2();
                onClickPosition.fromArray( array );
                this.mouse_and_touch.onMouseDown2(onClickPosition);

                
            }
            else if(this.count_mouse_steps<mouse_pts.length-1)
            {
                const array = this.mouse_and_touch.getMousePositionPercent( this.mouse_and_touch.canvas, mouse_pts[this.count_mouse_steps].x, mouse_pts[this.count_mouse_steps].y);
                const onClickPosition = new THREE.Vector2();
                onClickPosition.fromArray( array );
                this.mouse_and_touch.onMouseMove2(onClickPosition);
            }

            this.count_mouse_steps++;
            
            if(this.count_mouse_steps==mouse_pts.length-1)
            {
                this.mouse_and_touch.onMouseUp2(null);
                this.mouse_array_num++;
                this.count_mouse_steps=0
            }
            
        }

    }
    RecordMouseSteps()
    {
        if (this.mouse_and_touch.mouse_down)
        {
            if(this.mouse_and_touch.mouse_over_quad)
                this.mouse_and_touch.mouse_recorded_pos.push(new Vector2(this.mouse_and_touch.client_pos_mouse.x, this.mouse_and_touch.client_pos_mouse.y));
        } 
        else if(this.mouse_and_touch.mouse_recorded_pos.length!=0)
        {
            console.log("mouse recorded points: ")
        let str = "mouse_pts:Array<Vector2> = [";
        this.mouse_and_touch.mouse_recorded_pos.forEach( (ele)=>{str+="new Vector2("+ele.x+", "+ele.y+"), "});

        console.log(str+"];")
        this.mouse_and_touch.mouse_recorded_pos = [];
        }
    }

    count_frames=0;
    ImagesLoaded()
    {
        this.start_three_js.AddRenderCallBack(this.Update, this);
        this.images_loaded=true;

        this.funcs_to_run_if_ready.forEach(func => {
            func();
            console.log("Running functions because images loaded!");
        });
    }
    //for having a function run if ready/images loaded, or pushed onto queue until they are
    //mainly for brush size to be applied after it is loaded
    funcs_to_run_if_ready = new Array();
    RunIfReady(func)
    {
        if(this.images_loaded)
            func();        
        else
            this.funcs_to_run_if_ready.push(func);
    }

    // count_update=0;
    Update(frame_delta_ms)
    {
        // if(this.count_update<1)
        // {
        //     console.log("Main Update ran!");
        //     this.count_update++;
        // }


        // this.MouseTestGaps();
        // this.RecordMouseSteps();

        if(!this.paint_on_surf.drawing)
            this.zoom_pan.Update();
        
        this.paint_on_surf.Update(this.mouse_and_touch, this.zoom_pan, null, null, null, this.render_surface);

        

        this.mouse_and_touch.Update();//should be updated after paintonsurface- because resets mouse to not down first

        //update any callbacks (such as modal needing to animate waiting on something)
        this.update_callbacks.forEach(callback => {
            callback(frame_delta_ms);
        });
        // for (let i = 0; i < this.update_callbacks.length; i++) {
        //     const callback = this.update_callbacks[i];
        //     console.log("calling callback, with this: ", this);
        //     callback(frame_delta_ms);
        // }

        //fill in test mouse actions
        // this.MouseTest();
        
        this.UpdateFPS(frame_delta_ms);
        


        this.count_frames++;

        

        //for drawing with any spacing
        // render_surface.mouse_pos=mouse_and_touch.mouse_pos;


        // if(mouse_and_touch.mouse_dots.length>0)
        // {
        //     // mouse_and_touch.mouse_dots.forEach( (ele, i)=> {console.log("["+i+"] = "+ele.x+", "+ele.y)})
        //     render_surface.mouse_pos=mouse_and_touch.mouse_dots.pop();
        //     console.log("drawing point: ", render_surface.mouse_pos, "with length: "+mouse_and_touch.mouse_dots.length);
            
        // }
        // else
        //     render_surface.mouse_pos.x=render_surface.mouse_pos.y=-1;
    }

    fps_array:Array<number> = [];
    fps_array_curr=0;
    fps_array_length_max=60;
    UpdateFPS(frame_delta_ms)
    {
        
        let delta_s = frame_delta_ms / 1000; //since time is in ms (1 /1000 s), to convert to seconds, divide by 1000, exp: 2000ms/ 1000 = 2s
        let fps= 1 / delta_s; //we can convert from seconds/ frame (delta_s) to frames/ second by flipping it dividing 1 by it- 1/ (seconds/frame) = frame/ second= fps

        // frame_delta_ms = frame_delta_ms.toFixed(2); //going to round to just 2 decimal places using toFixed(2)
        // fps = Math.round(fps);

        this.fps_array[this.fps_array_curr]=fps;

        this.fps_array_curr++;
        if(this.fps_array_curr>this.fps_array_length_max-1)
            this.fps_array_curr=0;

        //get the average of the last few frames
        let fps_total=1;

        this.fps_array.forEach(element => {
            fps_total+=element;
        });
        // console.log(this.fps_array.length)


        // this.fps_txt_element.innerHTML=Math.round(fps).toString();
        let fps_rounded= (fps_total/this.fps_array.length);
        if(fps_rounded>666)fps_rounded=69;
        
        if(this.fps_array.length>0)//otherwise it shows infinity
            this.fps_txt_element.innerHTML=Math.round(fps_rounded).toString();

            
    }

    update_callbacks:Array<any> = new Array();
    AddUpdateCallback(callback)
    {
        this.update_callbacks.push(callback);
        console.log("Added Update Callback, with callback length: "+this.update_callbacks.length, callback);
    }
    RemoveUpdateCallback(callback)
    {
        //from here: https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array
        let index = this.update_callbacks.indexOf(callback);
        if(index!=-1)
            this.update_callbacks.splice(index,1);
        else
            console.error("Error: Could not find callback to remove", callback);

        console.log("RemoveUpdateCallback, with callback length: "+this.update_callbacks.length);
    }

    ScaleChanged(scale:number)
    {
        console.error("Main: ScaleChanged: need to uncomming below lines, after we get in layers, change_canvas_size, cursor, layers_ui", scale);

        //so, need to change the size of the canvas- for now, have to go back, and just change the scale of the texture, but need to reference how we are doing it in unity

        //Debug.Log("Scale changed!!!: " + scale);
        // this.render_surface.scale = scale;



        //from unity:
        /*
        layers.SetScale(scale);

        //need to also let change canvas size know so it can update the offset
        change_canvas_size.CanvasMovedOrScaledExternally(layers);

        if(cursor!=null)
            cursor.SetCanvasScale(scale);

        layers_ui.UpdateLayerNameAboveCanvas();
        */
    }
    OffsetChanged(tex_offset:Vector2)
    {
        // console.error("Main: OffsetChanged: need to uncomming below lines, after we get in layers, change_canvas_size, cursor, layers_ui");

        /* layers.SetTexOffset(tex_offset);
        //need to also let change canvas size know so it can update the offset
        change_canvas_size.CanvasMovedOrScaledExternally(layers);

        layers_ui.UpdateLayerNameAboveCanvas(); */
    }

}
