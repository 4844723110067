import * as THREE from 'three';
import { OrthographicCamera, Raycaster, Scene, Vector, Vector2 } from 'three';
import BrushSacing from './BrushSpacing';

/**
 * Based on the Unity version- and based on ZoomDisplay.cs
 * 
 * Pretty sure I just used this to display what the zoom level was whenever they zoomed in/ out
 */
export default class ZoomDisplay
{
    private static instance:ZoomDisplay;
    public static GetInstance()
    {
        if(!ZoomDisplay.instance)
            ZoomDisplay.instance =  new ZoomDisplay();
        return ZoomDisplay.instance;
    }

    constructor()
    {
        //TODO: fill in class
        console.warn("ZoomDisplay.ts isn't filled in, but isn't required yet");
    }
    //todo: need to fill out:
    AddZoomChangedDelegate(zoom)
    {
        console.warn("ZoomDisplay.ts need to add: AddZoomChangedDelegate");
    }
    Open(message)
    {
        console.warn("ZoomDisplay.ts need to add: Open");
    }

    // Text text;
    /* TMP_InputField text;
    float display_time = 1;
    float fade_speed = 2;
    float timer = 0;
    bool visible = false;

    float alpha = 1;
    bool editing=false;

    CanvasGroup canvas_group;

    //for letting the zoompangeneral know when we change the zoom from the input box
    public delegate void ZoomChanged(float zoom);
    public ZoomChanged zoomChangedDelegate;
    public void AddZoomChangedDelegate(ZoomChanged function)
    {zoomChangedDelegate=null; zoomChangedDelegate += function;}



    private static ZoomDisplay instance;
    public static ZoomDisplay GetInstance()
    {
        if (instance == null)
            Debug.LogError("Error: Trying to get aan instance of ZoomDisplay before unity has initialized it in awake!");
        return instance;
    }
    private void Awake()
    {
        instance = this;
    }

    // Use this for initialization
    void Start()
    {
        this.gameObject.SetActive(false);
        this.canvas_group = this.GetComponent<CanvasGroup>();
        // this.text = GetComponentInChildren<Text>();
        this.text = GetComponentInChildren<TMP_InputField>();
        text.onEndEdit.AddListener(TextEdited);



        EventTrigger trigger = text.gameObject.AddComponent<EventTrigger>();

        var pointerDown = new EventTrigger.Entry();
        pointerDown.eventID = EventTriggerType.PointerDown;
        pointerDown.callback.AddListener(OnSelect);
        trigger.triggers.Add(pointerDown);
        
    }
    void TextEdited(string arg)
    {
        Debug.Log("text finished being edited!");
        //set the zoom
        editing=false;

        //try to parse it
        float num=0;
        if(float.TryParse(text.text, out num))
        {
            if(num<1)
                num=1;
            
            if(zoomChangedDelegate!=null)
                zoomChangedDelegate(num);
        }

    }
    void OnSelect(BaseEventData be)
    {
        Debug.Log("Input field clicked!!!");
        editing=true;
        timer = 0;
        alpha = 1;
        this.canvas_group.alpha = alpha;
    }
    

    public void Open(string message)
    {
        this.text.text = message;
        this.gameObject.SetActive(true);
        timer = 0;
        alpha = 1;
        this.canvas_group.alpha = alpha;
        this.visible = true;
    }

    // Update is called once per frame
    void Update()
    {
        if (visible)
        if(!editing)
        {
            this.timer += Time.deltaTime;
            if (this.timer > display_time)
            {
                alpha -= fade_speed * Time.deltaTime;
                if (alpha <= 0)
                {
                    alpha = 0;
                    this.visible = false;
                    this.gameObject.SetActive(false);
                }
                this.canvas_group.alpha = alpha;
            }
        }
    } */

}