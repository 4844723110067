/*
Storage system- for storing token, image, etc
*/

import LocalStorageInterFace from "./LocalStorage";

export default class Storage
{
    static instance;
    static GetInstance():Storage
    {
        if(Storage.instance==undefined)
            Storage.instance  = new Storage();

        return Storage.instance;
    }
    local_storage:LocalStorageInterFace;
    constructor()
    {
        Storage.instance = this;
        this.local_storage = LocalStorageInterFace.GetInstance();
    }


    SaveValue(name, value)
    {
        //save the value
        this.local_storage.SaveValue(name, value);
    }
    LoadValue(name)
    {
        //return the value
        return this.local_storage.LoadValue(name);
    }

    //to have it load a value, but if one isn't saved, to provide a default value instead
    LoadValueOrDefault(name, default_value)
    {
        //return the value
        let val = this.local_storage.LoadValue(name);
        if(val==undefined)
            val = default_value;
        return val;
    }

    SaveImageDataURLToLocalStorage(data_url)
    {
        this.local_storage.SaveImageDataURLToLocalStorage(data_url);
    }
    LoadImageDataURLFromLocalStorage()
    {
        return this.local_storage.LoadImageDataURLFromLocalStorage()
    }


    SaveImageLocally(data_url, title, desc, nsfw, timestamp)
    {
        this.local_storage.SaveImageDataURLToLocalStorage(data_url);

        if(timestamp==undefined)
            timestamp = Date.now();
        this.local_storage.SaveValues({"title": title, "desc": desc, "nsfw": nsfw, "timestamp":timestamp})
    }
    LoadImageLocally()
    {
        let image_obj = this.local_storage.LoadValues(["title", "desc","nsfw", "timestamp"]);
        
        if(image_obj["timestamp"]==undefined)
            image_obj["timestamp"] = Date.now();

        //if there is no image to load/ nothing has ever been saved
        if(image_obj["title"]==undefined)
        {
            image_obj=null;
        }
        else
            image_obj["image"] = this.local_storage.LoadImageDataURLFromLocalStorage();

        
        return image_obj;
    }
}