/* 
A helper class to take over for the built in Unity "Screen.cs" class, that has the current screen width, height, etc
*/

import * as THREE from 'three';
import { OrthographicCamera, Raycaster, Scene, Vector, Vector2 } from 'three';
import BrushSacing from './BrushSpacing';

/**
 * Based on the Unity version- and based on ZoomDisplay.cs
 * 
 * Pretty sure I just used this to display what the zoom level was whenever they zoomed in/ out
 */
export default class Screen
{

    static def=507.6;
    static w=Screen.def;
    static h=Screen.def;

    //TODO: Get actual values set from somewhere
    static get width(){ 
        // Screen.w= screen.width;
        Screen.w = window.innerWidth;
        // console.log("Screen width and height: ", screen.width, screen.height)
        // console.log("Screen width and height: ", window.innerWidth, window.innerHeight)
        // if(Screen.w==Screen.def){console.error("Need to get the actual width and height set from somewhere else, currently set to: ", Screen.w);}
        return Screen.w;}
    static set width(value){Screen.w = value;}

    static get height(){ 
        // Screen.h= screen.height;
        Screen.h = window.innerHeight;

        // if(Screen.w==Screen.def){console.error("Need to get the actual width and height set from somewhere else, currently set to: ", Screen.w);}
        return Screen.w; }
    static set height(value){Screen.w = value;}
}