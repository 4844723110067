import Dexie from "dexie";



export default class DexieJSInterface
{
    static instance;
    static async GetInstance ():Promise<DexieJSInterface>
    {
        if(DexieJSInterface.instance==undefined)
        {
            DexieJSInterface.instance  = new DexieJSInterface();
            await DexieJSInterface.instance.Init();
        }

        return DexieJSInterface.instance;
    }
    db;
    constructor()
    {
        DexieJSInterface.instance = this;
        
    }
    //init the database/ dexiejs
    async Init()
    {
       // Define your Dexie.js database
        this.db = new Dexie('Entries');
        await this.db.version(1).stores({
        entries: '++id, id_entry, id_challenge, id_image, updated_at, image, name, desc, nsfw', // Define schema for the images table
        projects: '++id, id_project, updated_at, image, name, desc, nsfw, is_local_id', // Define schema for the images table
        });

        //note, later on it said I could have a seperate table for images in dexie, if we add layers, and have them point to the entry id in the main table
    }
    async GetLocalEntry(id_entry)
    {
        return await this.db.entries.where('id_entry').equals(id_entry).first();
    }
    async GetLocalProject(id_project)
    {
        return await this.db.projects.where('id_project').equals(id_project).first();
    }
    async SaveLocalEntry(id_entry, id_challenge, updated_at, image, id_image=null, name="", desc="", nsfw="")
    {
        let entry_obj = { id_entry:id_entry, id_challenge:id_challenge, id_image:id_image, updated_at:updated_at, image:image, name:name, desc:desc, nsfw:nsfw};
        // Insert image object into the images table
        // await this.db.entries.add({ id_entry:id_entry, id_challenge:id_challenge, updated_at:updated_at, image:image, name:name, desc:desc, nsfw:nsfw});

        //need to update it if it already exists with the same id_entry:

        this.db.entries.where('id_entry').equals(id_entry).count().then(count => {
            if (count > 0) 
            {
                // Entry exists, so update it
                console.log("dexiejs: entry exists, updating. updated_at: ", updated_at);
                this.db.entries.where('id_entry').equals(id_entry).modify(entry_obj);
            }
             else 
             {
                console.log("dexiejs: entry doesn't exist, updating");
                // Entry doesn't exist, so add it
                this.db.entries.add(entry_obj);
             }
            
        }).catch(error => {
            // Handle error
            console.error('DexieJSInterface: Error:', error);
        });
    }

    //for saving projects locally instead of entires:
    async SaveLocalProject(updated_at, image, id_project=null, name="", desc="", nsfw="")
    {
        let proj_obj = {id_project:id_project, updated_at:updated_at, image:image, name:name, desc:desc, nsfw:nsfw, is_local_id:false};
        // Insert image object into the images table
        // await this.db.entries.add({ id_entry:id_entry, id_challenge:id_challenge, updated_at:updated_at, image:image, name:name, desc:desc, nsfw:nsfw});

        //need to update it if it already exists with the same id_entry:
        if(!id_project)
        {
            proj_obj.id_project=Date.now();
            proj_obj.is_local_id=true;
        }



        this.db.projects.where('id_project').equals(id_project).count().then(count => {
            if (count > 0) 
            {
                // Entry exists, so update it
                console.log("dexiejs: project exists, updating. updated_at: ", updated_at);
                this.db.projects.where('id_project').equals(id_project).modify(proj_obj);
            }
             else 
             {
                console.log("dexiejs: project doesn't exist, adding new one to local storage");
                // project doesn't exist, so add it
                this.db.projects.add(proj_obj);
             }
            
        }).catch(error => {
            // Handle error
            console.error('DexieJSInterface: Error:', error);
        });
    }

}