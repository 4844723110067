import * as THREE from 'three';
import { OrthographicCamera, Raycaster, Scene, Texture, Vector, Vector2, Vector4 } from 'three';
import RenderSurface from '../RenderSurface';
import Color from './Color';
import ConnectTheDots from './ConnectTheDots';
import MouseAndTouch from './MouseAndTouch';



export default class PaintOnSurfGeneral
{
    drawing:boolean = false;
    over_canvas:boolean = false;//for undo/ redo- just so we know if the state has been stored- mainly so if they start drawing, but it's off the canvas, we don't store the state until they are on the canvas- otherwise we're storing the state/ drawing even if they aren't actually drawing on the canvas
    changed:boolean = false; //mainly just for thumbnail to know if the drawing is done drawing, but had changed

    //for locking the x or y direction for drawing- like holding down shift to draw
    lock_x:boolean = false;
    lock_y:boolean = false;
    locked_x:number = 20;
    locked_y:number = 20;

    draw_surf_last:RenderSurface;//just to easily remember what draw surf we were using, so we can set things like using the eraser, brush type, etc

    using_eraser:number=0;// to set on all the drawing surfaces, since they each individually have it set - 0 for no ,1 for yes

    brush_tex:Texture;
    color:Vector4 = new Vector4(.109, .109, .109, 1);

    BRUSH_SIZE_LARGEST:number=256;
    brush_size_current:number = 10;
    brush_type:number = RenderSurface.BRUSH_SQUARE;
    brush_hardness:number = 1;

    //for having seperate brush size for eraser
    brush_size_brush:number= 10;
    brush_size_eraser:number = 10;

    last_mouse_pos:Vector2 = new Vector2(-1000, -1000);

    total_distance_moved:number=0;//for knowing how far to move before allowing the next "stamp" to be drawn
    last_mouse_pos_for_distance:Vector2 = new Vector2(-1000, -1000);

    spacing_percent:number = 0.2;

    spacing_px:number = 10;


    brush_size_UV:number=.015;
    spacing_uv:number = .1;//how much space to move in relation to UV texture coordinates/ 0-1

    // brush_sizes_recent:BrushSizesRecent;//for recording what brush size we're using when drawing on the canvas  the first time

    // brush_colors_recent:BrushColorsRecent;

    /*
    * to not draw when using multi touch-
    * a buffer for when using touch- 
    * when touched, start putting points into buffer, instead of drawing directly.
    * If a second touch point is found, clear buffer, and count it as mouse up/ don't need for drawing. 
    * If touch up, should immediately use buffer to draw.
    * If moved a certain distance from first point to next point, should count it as drawing/ use points, and no longer buffer
    */
   buffered_points:Array<Vector2> = new Array();
   buffering_points=false;
   mouse_px_first:Vector2= new Vector2();//for recording where the mouse is first down, so we can see how far it has moved
   buffer_max_dist_in:number=.15;//how far in inches to move before stopping buffering


    SetBrushTex(brush_tex, render_surface:RenderSurface)
    {
        this.draw_surf_last = render_surface;

        this.brush_tex=brush_tex;
        render_surface.SetBrushTexture(brush_tex);        
    }

    SetBrushColor255(r,g,b,a)
    {
        r=r/255;
        g=g/255;
        b=b/255;
        //a is already 0-1

        this.color.set(r,g,b,a);
    }


    SetBrushSize(size)
    {
        this.brush_size_current = size;
        // SetBrushSpacing(this.spacing_percent);
        this.SetBrushSizePercentUV(size/this.draw_surf_last.width);

        this.draw_surf_last.SetSize(size);


        console.log("Calling to add test data points from PaintOnSurfGeneral")
        // this.draw_surf_last.AddTestData();
        // this.draw_surf_last.FillTexture(new Vector4(1,0,0,1));
    }
    SetBrushSizePercentUV(brush_size_UV)
    {
        this.brush_size_UV=brush_size_UV;
        this.spacing_uv=this.spacing_percent*this.brush_size_UV;
        
    }
    GetBrushSize()
    {
        return this.brush_size_current;
    }


    // public void Update(MouseAndTouch mouse_touch, ZoomPanGeneral zoom_pan, LayersNew layers, LayersNewUI layers_ui, UndoRedo2 undo_redo = null)
    Update(mouse_touch:MouseAndTouch, zoom_pan:any, layers:any, layers_ui:any, undo_redo:any = null, render_surface:RenderSurface)
    {
        //should return if touch count>1, and should clear buffered points if buffering
        if(mouse_touch.touch_count>1)
        {
            console.log("touch count greater than 1, returning");
            if(this.drawing)
                this.ClearBufferedPoints();
            return;
        }

        this.changed=false;

        if(zoom_pan!=null)
            if(zoom_pan.zooming_or_panning)
            {
                //should also delete any points captured o far that haven't been drawn
                return;
            }

        //TODO: Bring back from Unity with layers
        // SurfaceRenderTex draw_surf = layers.GetSelectedLayer();
        let draw_surf:RenderSurface = render_surface;


        if (mouse_touch.mouse_down)
        {
            // console.log("paint on surface mouse down");
            

            //if we're interacting with a UI elemnt, don't draw
            if (mouse_touch.mouse_down_first)
            {
                
                if (mouse_touch.mouse_over_ui)
                    return;
                // else if (isUIOpenDelegate != null)//don't draw if 
                //     if (isUIOpenDelegate())
                //         return;

                //Debug.Log("mouse_down");

                //if the draw surface is not visible, and trying to draw, select the highest visible draw surface
                if (!draw_surf.visible)
                {
                    draw_surf = layers.GetHighestVisibleLayer();
                    if (draw_surf == null)
                        return;

                    //layers.SetSelectedLayer(draw_surf.id);
                    layers_ui.SelectLayer(draw_surf.id);
                }
                


                let mouse_tex_cord = mouse_touch.mouse_uv_coordinates;
                // let mouse_tex_cord = zoom_pan.GetTextureCoordsFromScreenCoords(mouse_touch);

                //add in offset of layer, for being able to move layers around
                // mouse_tex_cord.x += draw_surf.offset_x;
                // mouse_tex_cord.y += draw_surf.offset_y;

                //need to also offset for textures that are smaller than the canvas size - since they are drawn from the top left
                //but the mouse coordinates are from bottom left
                // mouse_tex_cord.y -= layers.GetLayers()[0].height - draw_surf.height;
                //Debug.Log("moving mouse_tex_cord.y up by: " + (layers.GetLayers()[0].height - draw_surf.height));

                this.locked_x = mouse_tex_cord.x;
                this.locked_y = mouse_tex_cord.y;

                //mouse down first, we'll store the first mouse position for figuring out distance for touches
                this.mouse_px_first.x = mouse_touch.client_pos_mouse.x;
                this.mouse_px_first.y = mouse_touch.client_pos_mouse.y;
                console.log("mouse down first at: ", this.mouse_px_first);
            }

            if (mouse_touch.mouse_down_first || this.drawing)
            {
                //repeat the stuff we did outside all the area:
                this.draw_surf_last = draw_surf;
                draw_surf.using_eraser = this.using_eraser;
                draw_surf.SetBrushTexture(this.brush_tex);

                //Debug.Log("mouse_touch.mouse_down_first || drawing");
                //so we take the mouse_touch points- which are in screen coordinates. Have to transfer to texture coordinates
                // Vector2 mouse_tex_cord = zoom_pan.GetTextureCoordsFromScreenCoords(mouse_touch);
                let mouse_tex_cord = mouse_touch.mouse_uv_coordinates;

                //add in offset of layer, for being able to move layers around
                // mouse_tex_cord.x -= draw_surf.offset_x;
                // mouse_tex_cord.y += draw_surf.offset_y;

                // mouse_tex_cord.y -= layers.GetLayers()[0].height - draw_surf.height;
                //Debug.Log("moving mouse_tex_cord.y up by: " + (layers.GetLayers()[0].height - draw_surf.height));

                //Debug.Log("mouse_tex_cord: "+mouse_tex_cord+ " BrushIntersectsCanvas(mouse_tex_cord): "+BrushIntersectsCanvas(mouse_tex_cord)+ ", draw_surf_last.width: "+ draw_surf_last.width + ", draw_surf_last.height: " + draw_surf_last.height);

                //if locked, should change the mouse coordinate to the first value recorded
                //-switched what you think they would be
                if (this.lock_y)
                    mouse_tex_cord.x = this.locked_x;
                if (this.lock_x)
                    mouse_tex_cord.y = this.locked_y;

                //Debug.LogWarning("CLicked on screen, mouse tex coords: " + mouse_tex_cord);
                //if (mouse_tex_cord.x != -1)
                let max_distance_x:number = draw_surf.width * .25;//max difference to still record points off the canvas- for undo and for recording
                let max_distance_y:number = draw_surf.height * .25;//max difference to still record points off the canvas

                //should see if the mouse point + brush radius is inside the canvas
                
                

                // if ((mouse_tex_cord.x+ max_distance_x > 0 && mouse_tex_cord.x< draw_surf.width+ max_distance_x)||drawing)
                //     if ((mouse_tex_cord.y + max_distance_y > 0 && mouse_tex_cord.y < draw_surf.height + max_distance_y)||drawing)
                    // if(BrushIntersectsCanvas(mouse_tex_cord))
                    if(mouse_touch.mouse_over_quad)
                    {
                    //Debug.Log("BrushIntersectsCanvas");
                    if (!this.drawing)
                    {

                        draw_surf.StartRecordingPoints(this.color, this.brush_size_current, this.brush_type, this.brush_hardness);
                        if (undo_redo != null)
                        {
                            // Debug.Log("WARNING: also only stored change here before if the first draw only, for some reason- and only counted over canvas the first time");
                            // if (draw_surf.num_draws == 0)
                            {
                                
                                undo_redo.StoreChange(draw_surf);
                                undo_redo.DrawingStarted();
                                draw_surf.modified=true;
                                // layers.last_layer_drawn_on=draw_surf;//for undo/ redo to know which surface to store, which should also clear it

                                this.over_canvas=true;
                                draw_surf.num_draws++;
                            }
                            // else
                            //     draw_surf.num_draws++;//just to keep track of how many times it's been drawn on
                            //if (undo_redo.first)
                            //{
                            //    undo_redo.StoreChange(((SurfaceRenderTex)draw_surf));
                            //    undo_redo.first = false;
                            //}
                        }
                    }


                    if (this.last_mouse_pos.x == -1000)//first click
                    {
                        this.CheckIfShouldBuffer(mouse_touch);

                        if(this.buffering_points)
                        {
                            this.BufferPoints(mouse_tex_cord);
                        }
                        else
                        {
                            //else record points directly- 
                            draw_surf.RecordPoint(mouse_tex_cord.x, mouse_tex_cord.y);
                        }

                        // @ts-ignore:
                        this.last_mouse_pos.setV(mouse_tex_cord);

                        // @ts-ignore:
                        this.last_mouse_pos_for_distance.setV(mouse_tex_cord);
                    }
                    else
                    {
                        // console.log("not first click");
                        
                        let distance:number = mouse_tex_cord.distanceTo(this.last_mouse_pos_for_distance);
                        this.total_distance_moved+=distance;

                        


                        // @ts-ignore:
                        this.last_mouse_pos_for_distance.setV(mouse_tex_cord);

                        // console.log("mouse_tex_cord: \n",mouse_tex_cord+", last mouse coord: \n"+this.last_mouse_pos_for_distance+"\ndistance: "+distance)
                        // console.log("distance moved: "+distance+", total distance moved: "+this.total_distance_moved+", required distance: "+this.spacing_uv);

                        if (this.total_distance_moved > this.spacing_uv)
                        {
                            // console.log("distance moved enough("+this.total_distance_moved+")!"+", spacing percent: "+this.spacing_percent+", brush sizxe UV: "+this.brush_size_UV);
                            // console.log(" last: "+this.last_mouse_pos+", current: "+mouse_tex_cord);
                            
                            //interpolate between this pt and the last, and record all those points
                            ConnectTheDots.SetSpacingPercent(this.spacing_percent, this.brush_size_UV);
                            let pts:Array<Vector2> = ConnectTheDots.GetPointsV(this.last_mouse_pos, mouse_tex_cord);

                            // console.log("PainOnSurfGeneral: Connect the dots returned array size: "+pts.length+", distance moved: "+this.total_distance_moved+", spacing uv: "+this.spacing_uv+", distance 2: "+mouse_tex_cord.distanceTo(this.last_mouse_pos));

                            for (let i = 0; i < pts.length; i++)
                            {

                                if(this.buffering_points)
                                {
                                    this.BufferPoints(new Vector2(pts[i].x, pts[i].y));
                                }
                                else
                                {                            
                                    draw_surf.RecordPoint(pts[i].x, pts[i].y);
                                }
                                // @ts-ignore:
                                this.last_mouse_pos.setV(pts[i]);
                            }
                            //FOR TESTING JUST LAST POINT
                            // draw_surf.RecordPoint(mouse_tex_cord.x, mouse_tex_cord.y);

                            this.total_distance_moved =0; //reset the total distance moved



                            //for buffering points for not drawing with touchscreen, we'll check distance too- if moved a certain distance, stop buffering points, and draw to the screen
                            if(this.buffering_points)
                            {
                                // let distance_from_first_pos:number = mouse_touch.client_pos_mouse.distanceTo(this.mouse_px_first);
                                // console.log("total distance on mouse is: ", distance_from_first_pos);
                                let distance_from_first_pos_in:number =  mouse_touch.GetDistanceInInches(mouse_touch.client_pos_mouse, this.mouse_px_first)
                                console.log("total distance distance_from_first_pos_in: ", distance_from_first_pos_in);
                                if(distance_from_first_pos_in>this.buffer_max_dist_in)
                                {
                                    console.log("distance moved enough to not be considered zooming/ panning, so drawing to screen");
                                    this.DrawBufferedPointsToScreen(draw_surf);
                                    this.buffering_points=false;
                                }
                            }
                        }

                        
                    }
                    this.drawing = true;
                    if (mouse_touch.mouse_down_first)
                    {
                        //do things that are only done the first mouse down, but knowing we're drawing now
                        // this.brush_sizes_recent.BrushDownFirst(this.brush_size_current);

                        // //don't need to call if not using color picker
                        // if(StaticToDrawing.constraint_colors==null)//if using constraint colors- we're not using the color picker
                        //     this.brush_colors_recent.BrushDownFirst(this.color);
                    }
                }
            }
        }
        else if (this.drawing)
        {
            //this stores if mouse up- I think I was doing this to store when finished drawing- in case we try to do undo, we'll have the current state stored to restore to. but going to try storing it when we hit undo
            //- but only on first undo? since if we store on each undo, it doesn't make sense- since it will already be stored
            //so should store state if first undo in between drawing- if we start drawing again, should disable needing to save the current state when undoing
            // Debug.Log("WARNING: we were storing state on mouse up, for possibly doing undo- should store now if we hit undo- should store the state, so we can redo");
            /* if (undo_redo != null)
                undo_redo.StoreChange(((SurfaceRenderTex)draw_surf)); */

            //also update Current Layer
            //if (layers != null)
            //    layers_ui.UpdateLayerChange(layers);

            this.drawing = false;
            this.over_canvas=false;
            this.last_mouse_pos.x = -1000;
            this.last_mouse_pos.y = -1000;

            //FIX FOR UNEVEN SPACING
            this.total_distance_moved =0;

            // @ts-ignore:
            this.last_mouse_pos_for_distance.set(-1000, -1000);
            this.changed=true;



            //this.DrawBufferedPointsToScreen(draw_surf);
            this.ClearBufferedPoints();
            this.buffering_points=false;
            
        }
    }

    //should only be run on first down- should return true if using touch
    CheckIfShouldBuffer(mouse_touch:MouseAndTouch)
    {
        console.log("checking if we should buffer, touchcount: ",mouse_touch.touch_count)
        //first set whether we should buffer- which should be if touching- should buffer
        //if we're using touch, should buffer
        if(mouse_touch.touch_count>0)        
            this.buffering_points=true;
    }
    //should set it to needing to buffer if needed, and buffer them up, but also draw when no longer buffering
    //mainly used for multi-touch- so we don't draw if they are starting a multi-touch gesture, - since it will record the first finger, then eventually the 2nd
    BufferPoints(mouse_tex_cord)
    {
        //but when they move their finger a certain distance, should draw to the screen, and turn off buffering
        this.buffered_points.push(new Vector2(mouse_tex_cord.x, mouse_tex_cord.y))
        console.log("buffering points: ", this.buffered_points[this.buffered_points.length-1]);

    }
    DrawBufferedPointsToScreen(draw_surf)
    {
        console.log("drawing buffered points to screen!");
        for (let i = 0; i < this.buffered_points.length; i++) 
        {
            const pt = this.buffered_points[i];
            draw_surf.RecordPoint(pt.x, pt.y);
            //@ts-ignore
            // this.last_mouse_pos.setV(pt);
        }
        this.ClearBufferedPoints();
    }
    ClearBufferedPoints()
    {
        this.buffered_points=[];
    }

}

