import React, { useState } from 'react';
import NavBar from '../NavBar/NavBar';
import { Routes } from 'react-router-dom';
import { Box, CssBaseline, GlobalStyles, Paper, ThemeProvider, createTheme } from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import Theme from './Theme';




const HeaderAndFooter = ({ children }) => {


  return (
   
   

    <Theme>
    <Paper
    sx={{
        // display: 'flex',
        justifyContent: 'center',
        maxWidth: '1200px', // Adjust the maximum width as needed
        margin: '0 auto', // Center the box horizontally
        padding: '0 16px', // Optional: Add padding to the sides
        width: '100%',
      }}
      elevation={5}
    >
      {/* <div className='wrapper'>         */}

        <NavBar/>

        <div style={{ padding:"20px"}}>
            
            {children}
           
      </div>
      {/* </div> */}
      </Paper>
      </Theme>
    

    
  );
};

export default HeaderAndFooter;