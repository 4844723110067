/* 
A helper class to take over for the built in Unity "Input.cs" class
*/

import * as THREE from 'three';
import { OrthographicCamera, Raycaster, Scene, Vector, Vector2 } from 'three';
import BrushSacing from './BrushSpacing';

/**
 * Based on the Unity version- and based on ZoomDisplay.cs
 * 
 * Pretty sure I just used this to display what the zoom level was whenever they zoomed in/ out
 */

export class Touch
{
     //
    // Summary:
    //     The position delta since last change in pixel coordinates.
    

    down:boolean=false;
    def= new Vector2(-.1, -.1);
    delta_position:Vector2= new Vector2(this.def.x, this.def.y);
    get deltaPosition()
    {
        if(this.delta_position.equals(this.def))
            console.error("Need to get the actual delta_position set from somewhere else, currently set to: ", this.delta_position);
        return this.delta_position;
    }
    set deltaPosition(value)
        {this.delta_position = value;}


    m_position:Vector2= new Vector2(this.def.x, this.def.y);
    get position()
    {
        if(this.m_position.equals(this.def))
            console.error("Need to get the actual position set from somewhere else, currently set to: ", this.m_position);
        return this.m_position;
    }
    set position(value)
        {this.m_position = value;}
    
}

export default class Input
{

    // static def_set=new Vector2(0, 0);// default for things we already have setup
    static def=new Vector2(0, .01);//default for vars we haven't filled in yet/ not being updated properly
    static mouse_scroll_delta=new Vector2(0, 0);
    static mouse_position=new Vector2(0, 0);

    // static tc_def=76;

    //both set from MouseAndTouch
    static touch_count=0;
    static touches:Array<Touch>;

    static mouse_btns_down=[false, false, false];//to store if the 3 mouse btns are down- set from MouseAndTouch - usually 0 left, 1, right, 2, middle, but javascript has last 2 flipped for some reason
    static set LeftMouseDown(value){ Input.mouse_btns_down[0]=value;}
    static set RightMouseDown(value){ Input.mouse_btns_down[1]=value;}
    static set MiddleMouseDown(value){ Input.mouse_btns_down[2]=value;}

    static get LeftMouseDown(){return Input.mouse_btns_down[0]}
    static get RightMouseDown(){return Input.mouse_btns_down[1]}
    static get MiddleMouseDown(){return Input.mouse_btns_down[2]}

    static MouseBtnDownJS(num, is_down)
    {
        if(num=0)
            Input.LeftMouseDown=is_down;
        else if(num=1)
            Input.MiddleMouseDown=is_down;
        else if(num=2)
            Input.RightMouseDown=is_down;
    }
    

    //Should be getting set my MouseAndTouch.Wheel() (#101)
    static get mouseScrollDelta()
    {  
        // if(Input.mouse_scroll_delta==Input.def)
        //     console.error("Need to get the actual mouse_scroll_delta set from somewhere else, currently set to: ", Input.mouse_scroll_delta);

        return Input.mouse_scroll_delta; 
    }
    static set mouseScrollDelta(value){Input.mouse_scroll_delta = value;}

    static get mousePosition()
    { 
        // if(Input.mouse_position==Input.def)
        // {console.error("Need to get the actual mouse_position set from somewhere else, currently set to: ", Input.mouse_position);}
        return Input.mouse_position; 
    }

    static set mousePosition(value){Input.mouse_position = value;}

    static get touchCount()
    { 
        // if(Input.touch_count==Input.tc_def)
        // {console.error("Need to get the actual touch_count set from somewhere else, currently set to: ", Input.touch_count);}

        return Input.touch_count; 
    }
    static set touchCount(value){Input.touch_count = value;}

    


    
    static GetTouch(finger_num):Touch
    {
        return Input.touches[finger_num];
        // console.error("Need to get the actual touch object set from somewhere else");
        // return new Touch();
    }

    static GetMouseButtonDown(btn:number):boolean
    {
        // console.error("Need to get the actual mouse object set from somewhere else");
        // return false;
        return this.mouse_btns_down[btn];
    }
    static GetMouseButton(btn:number):boolean
    {
        // console.error("Need to get the actual mouse object set from somewhere else");
        // return false;
        return this.mouse_btns_down[btn];
    }

}