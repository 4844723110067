import { useEffect, useState } from 'react'
import styles from "./Challenges.module.css";

/* 
Gallery for showing thumbnails in a list
*/

//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { URL_BACKEND } from '../../CONSTANTS';
import ChallengeItem from './ChallengeItem';
import HeaderAndFooter from '../Layouts/HeaderAndFooter';
import { GET, MyFetchRequest } from '../../Helpers';
import { Typography } from '@mui/material';


async function GetChallengesFromServer(setChallengeItems)
{
    const url = URL_BACKEND+'challenges/';
    const params = {
        limit:'24',
    // param1: 'value1',
    // param2: 'value2',
    };

    let data = await MyFetchRequest(url, GET, params);

    console.log(data);
    console.log("setting gallery items to: ",data.data)
    setChallengeItems(data.data);

    /* // Construct the URL with query parameters
    const queryParams = new URLSearchParams(params);
    const fullUrl = `${url}?${queryParams}`;
    // const fullUrl = `${url}`;
    console.log("fetching from: ", fullUrl)

    // Perform the fetch request
    fetch(fullUrl)
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        // Handle response data
        console.log(data);
        console.log("setting gallery items to: ",data.data)
        setChallengeItems(data.data);
    })
    .catch(error => {
        // Handle fetch errors
        console.error('There was a problem with the fetch operation:', error);
    }); */
}

function Challenges() {
  const [count, setCount] = useState(0);
  const [challenge_items, setChallengeItems] = useState(new Array());


  useEffect(() => {
    GetChallengesFromServer(setChallengeItems)

    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount



  return (
    <>
    {/* <HeaderAndFooter> */}
    <div>
    
        <Typography variant='h4' gutterBottom>Challenges</Typography>
        {
        challenge_items.length>0&&
        (
            <div className={styles.challenges}>
                
            {
                
            challenge_items.map(item =>
            {
                // return <p>hello</p>
                return <ChallengeItem item={item} key={item.id}/>;
            })

            }
            </div>
        )
        }
    </div>
    {/* </HeaderAndFooter> */}
    
    
    </>
  )
}

export default Challenges
