import { URL_BACKEND } from "../../../CONSTANTS";
import Storage from "../filesystem/Storage";
import Modal from "../UI/Modal";
import UserData from "../UserData";

export default class Server
{
    static SERVER_URL = URL_BACKEND;
    static instance;
    static GetInstance():Server
    {
        if(Server.instance==undefined)
        Server.instance  = new Server();

        return Server.instance;
    }

    constructor()
    {
        Server.instance = this;
    }
    SendImageToServer(image:File)
    {
        let token = UserData.GetInstance().token;
        // this.SendImageFormData(token, image);

        //need to send:
        // let token = fields.token;
        
        // let image_title = fields.name;
        // let image_timestamp = fields.timestamp;//timestamp of when the image was created, used for the name we store, so it's a safe filename
        // let desc = fields.desc;
        // let nsfw = fields.nsfw.toLowerCase()=='true';
        // let image_file = files.image;
        // let game_type = parseInt(fields.game_type); //0 being no game /portfolio

        // let cats_picked = fields.cats_picked;  
    }
    SendImageFormData(image, title, timestamp, desc, nsfw/*, game_type, cats_picked*/)
    {
        let token = UserData.GetInstance().token;
        //from: https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
        console.log(`going to send data to server: token: ${token}, image: ${image}, title: ${title}, timestamp: ${timestamp}, desc: ${desc}, nsfw: ${nsfw}`);
        var formData = new FormData();

        formData.append("token", token);
        formData.append("name", title);
        formData.append("timestamp", timestamp);
        formData.append("desc", desc);
        formData.append("nsfw", nsfw);
        formData.append("image", image);
        formData.append("game_type", "0");//0 for no game type

        // formData.append("game_type", game_type);
        // formData.append("cats_picked", cats_picked);

        // formData.append("email", email); 

        // HTML file input, chosen by user
        // formData.append("userfile", fileInputElement.files[0]);

        // JavaScript file-like object
        // var content = '<a id="a"><b id="b">hey!</b></a>'; // the body of the new file...
        // var blob = new Blob([content], { type: "text/xml"});

        // formData.append("webmasterfile", blob);
        console.log("sending SendImageFormData info to server!");

        var request = new XMLHttpRequest();
        request.open("POST", Server.SERVER_URL+"images/upload");
        
        request.onload = function(oEvent) 
        {
            if (request.status == 200) 
            {
                console.log("response: ", request.responseText);

                let response_txt = JSON.parse(request.responseText);
                let code = response_txt.code;
                if(parseInt(code) == 400)
                {
                    alert(response_txt.errors[0]);
                }
                else
                {
                    if(response_txt.msg == "success")
                    {
                        let token = response_txt.token;
                        console.log("token found to be: ", token);

                        //@ts-ignore

                        //LoginSucessful(token);
                        Modal.GetInstance().Prompt("Image uploaded to server successfully!");
                    }
                    //alert(JSON.parse(request.responseText).client_msg);
                }
            //     console.log("request.responseText!", request.responseText, code);
            //   console.log("Uploaded!", request);
            
            } 
            else 
            {
                console.log("Error " + request.status + " occurred when trying to upload your file.<br \/>");

            }
        };
        request.send(formData);
    }
    LoginSucessful(token)
    {
        //@ts-ignore
        HideLogin();
        //@ts-ignore
        HideWindows();
        
        Storage.GetInstance().SaveValue("token", token);
        console.log(" token found: ", Storage.GetInstance().LoadValue("token"));
    }
}