import { URL_BACKEND } from "../../../../CONSTANTS";
import {ShowLogin, HideLogin} from "./Login"
import { ShowWindows } from "./Windows";

export function Register()
{
    console.log("going to register from button click!");
    //so should grab all the input fields (username, pwd, email)
    let user_name = (document.querySelector("#register .user_name") as HTMLInputElement).value;
    let password = (document.querySelector("#register .password") as HTMLInputElement).value;
    let email = (document.querySelector("#register .email") as HTMLInputElement).value;

    console.log("username: "+user_name+", pwd: "+password+", email: "+email);

    //now just need to send it to the server, and get the response
    RegisterSendFormData(user_name, password, email);
}


function RegisterSendFormData(user_name, password, email)
{
    //from: https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
    var formData = new FormData();

    formData.append("user_name", user_name);
    formData.append("password", password); // number 123456 is immediately converted to a string "123456"
    formData.append("email", email); 

    // HTML file input, chosen by user
    // formData.append("userfile", fileInputElement.files[0]);

    // JavaScript file-like object
    // var content = '<a id="a"><b id="b">hey!</b></a>'; // the body of the new file...
    // var blob = new Blob([content], { type: "text/xml"});

    // formData.append("webmasterfile", blob);
    console.log("sending register info to server!");

    var request = new XMLHttpRequest();
    request.open("POST", URL_BACKEND+"/users/register");
    
    request.onload = function(oEvent) {
        if (request.status == 200) 
        {
            let response_txt = JSON.parse(request.responseText);
            let code = response_txt.code;
            if(parseInt(code) == 400)
            {
                alert(response_txt.errors[0]);
            }
            else
            {
                alert(JSON.parse(request.responseText).client_msg+"\nPlease Login to continue");
                HideRegister();
                ShowLogin();
            }
        //     console.log("request.responseText!", request.responseText, code);
        //   console.log("Uploaded!", request);
          
        } 
        else 
        {
            console.log("Error " + request.status + " occurred when trying to upload your file.<br \/>");
        }
      };
    request.send(formData);
}

function ShowRegister()
{
    console.log("show register clicked!");
    //@ts-ignore
    ShowWindows();
    HideLogin();
    (document.querySelector("#register")  as HTMLElement).style.display = "block";
}

function HideRegister()
{
    (document.querySelector("#register")  as HTMLElement).style.display = "none";
}


//@ts-ignore
window.ShowRegister = ShowRegister;
//@ts-ignore
window.Register = Register;
//@ts-ignore
window.HideRegister = HideRegister;