import RenderSurface from "./RenderSurface";
import MouseAndTouch from "./screen_interaction/MouseAndTouch";
import PaintOnSurfGeneral from "./screen_interaction/PaintOnSurfGeneral";
import StartThreeJS from "./StartThreeJS";
import * as THREE from 'three';
import { Texture, Vector2, WebGLRenderTarget } from "three";
import MainMenu from "./UI/MainMenu";

import "./UI/windows/Register"; //to force it to import
import "./UI/windows/Login"; //to force it to import
import UserData from "./UserData";
import ColorChange from "./UI/ColorChange";



export default class Helpers
{
    constructor()
    {
        let a = new Vector2();
        
    }
    static Magnitude(vec:Vector2)
    {
        return Math.sqrt(vec.x*vec.x + vec.y*vec.y)
    }
}