/**
 * A specific challenge page
 * Gotte through with a link, should provide challenge info in routing/ state
 */

import { useEffect, useState } from 'react'
import styles from "./Challenges.module.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';



//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { SUB_FOLDER, URL_BACKEND } from '../../CONSTANTS';
import ChallengeItem, { ChallengeItemInterface } from './ChallengeItem';
import HeaderAndFooter from '../Layouts/HeaderAndFooter';
import { userStore } from '../../App';
import { UserData } from '../../User';
import { GET, MyFetchRequest, POST } from '../../Helpers';
import ProjectCard from '../ProjectCard';
import { Button } from '@mui/material';



function MyEntries() 
{
    //from zustand
    const user = userStore((state) => state.user);

  const [count, setCount] = useState(0);
//   const [my_entries, setMyEntires] = useState(new Array());

  const [my_entries, setMyEntries] = useState(new Array());

//   const { item } = useParams();
    let { state } = useLocation();
    let challenge = state.item as ChallengeItemInterface;

    //for being able to change route to the drawing page
    const navigate = useNavigate();


    const  JoinChallenge = async(user:UserData, challenge:ChallengeItemInterface, entry?:any)=>
    {
        //so have to submit the challenge id, the user id/ token
        //and get the new challenge entry id back, which will be used to go to the next page/ submit an entry

        if(!entry)//if starting a new one
        {
            try
            {
                const url = URL_BACKEND+'challenges/join/';

                console.log("Creating new entry for challenge: ", challenge);

                let created_at = new Date().toISOString();
                
                let result = await MyFetchRequest(url, POST, {token:user.token, id_challenge:challenge.id, created_at:created_at});

                //need to get back the entry, and project/ entry image

                console.log("my new entry is: , ", result);
                let data =result.data;

                // let id_entry= data.data.id;
                // let id_challenge = challenge.id;

                navigate(SUB_FOLDER+'Draw', { state: { /* id_entry: id_entry, */ challenge:challenge, entry:{id:data.id, finished: false, created_at:data.image.updated_at}, project:{...data.image, name:challenge.name, desc:challenge.desc}} });
                //navigate(SUB_FOLDER+'Draw', { state: { challenge:challenge, entry:entry, project:entry.image} });
            }
            catch(err)
            {
                console.error('There was a problem with the fetch operation:', err);
            }
        }
        else
        {
            console.log("Opening existing entry for challenge: ", challenge);
            navigate(SUB_FOLDER+'Draw', { state: { challenge:challenge, entry:entry, project:entry.image} });
            //lets change this around to be an object with a project, a challenge, and an entry- project being image for now
        }
    }



    const GetMyEntries = async (user:UserData, item:ChallengeItemInterface)=>
    {
        console.log("Going to grab entries for challenge for this user", user, item);

        //so have to submit the challenge id, the user id/ token
        //and get the new challenge entry id back, which will be used to go to the next page/ submit an entry
        
        const url = URL_BACKEND+'challenges/getmyentries/';
        const params = {
            // limit:'24',
            token: user.token,
            id_challenge:item.id,
        };

        try{
            let data = await MyFetchRequest(url, GET, {token:user.token, id_challenge:item.id});

            console.log("my entries are: , ", data);

            setMyEntries(data.data);

            /* let id_entry= data.data.id;
            let id_challenge = item.id; */
        }
        catch(err)
        {
            console.error('There was a problem with the fetch operation:', err);
        }
    }


  useEffect(() => {
    
    console.log("item is: ", challenge);

    if(user)
        GetMyEntries(user, challenge);
    else
        navigate(SUB_FOLDER, { state: { } });
        // GetAllEntriesForChallenge(item);
    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount



  return (
    <>
    <HeaderAndFooter>
    <div>
        <h1>Challenge: {challenge.name}</h1>
        <p>{challenge.desc}</p>
        <div>

            {my_entries&&<p>My Entries</p>
            
            }
            <Button variant='contained' style={{ height:"100px"}} onClick={()=>{JoinChallenge(user, challenge)}}>Create a New Entry!</Button>
            <div style={{display:"flex", flexWrap:"wrap"}}>
            {
                
                    my_entries.map((entry, i)=>{

                        if(!entry.image)
                            entry.image={path:"null"};

                    // return <button 
                    //     style={{width:"200px"}} 
                    //     data-id={entry.id} 
                    //     key={entry.id} 
                    //     onClick={()=>{JoinChallenge(user,challenge, entry)}}>
                    //         Entry {i}                    
                    // {entry.image&&
                    //     <img 
                    //         style={{maxHeight:"100%", maxWidth:"100%"}} 
                    //         src={URL_BACKEND+entry.image.path}/>
                    //     }
                    // </button>;

                    return <ProjectCard 

                    

                    project={{
                        image_path:URL_BACKEND+entry.image.path, 
                        title:`Entryee ${i}`, 
                        desc:"A description2", 
                        author: "an author", 
                        author_avatar_url:URL_BACKEND+entry.image.path}}


                        
                        data-id={entry.id} 
                        key={entry.id} 
                        onClick={()=>{console.log("card clicked!");JoinChallenge(user,challenge, entry)}}>
                                                
                    
                    </ProjectCard>;

                    // return <ProjectCard  key={item.id} project={{image_path:URL_BACKEND+item.image.path, title:"A title", desc:"A description", author: "an author", author_avatar_url:URL_BACKEND+item.image.path}}/>
                })
                
            }
            
            </div>
        </div>
       
    </div>
    </HeaderAndFooter>
    
    
    </>


  )

  
}




export default MyEntries
