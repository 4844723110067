import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'


//from material UI on using roboto font: https://mui.com/material-ui/getting-started/installation/
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './index.css'
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';




ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode> /* react mounts components twice in strict mode */
  
    <App />
    
  // </React.StrictMode>,
)
