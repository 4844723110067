import { useEffect, useState } from 'react'

/* 
Gallery for showing thumbnails in a list
*/

//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import NavBar from '../components/NavBar/NavBar';
import { userStore } from '../App';
import { URL_BACKEND } from '../CONSTANTS';
import Challenges from '../components/Challenges/Challenges';
import HeaderAndFooter from '../components/Layouts/HeaderAndFooter';
import { Typography } from '@mui/material';




/* function GetChallengesFromServer(setChallenges)
{
    const url = URL_BACKEND+'challenges/';
    const params = {
        limit:'24',
    // param1: 'value1',
    // param2: 'value2',
    };

    // Construct the URL with query parameters
    const queryParams = new URLSearchParams(params);
    const fullUrl = `${url}?${queryParams}`;
    console.log("querying full url: ", fullUrl)
    // const fullUrl = `${url}`;

    // Perform the fetch request
    fetch(fullUrl)
    .then(response => {
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        // Handle response data
        console.log(data);
        console.log("setting challenges items to: ",data.data)
        setChallenges(data.data);
    })
    .catch(error => {
        // Handle fetch errors
        console.error('There was a problem with the fetch operation:', error);
    });
} */

/* class Challenge
{
    id:string;
    name:string;
    desc:string;
    created_at:string;
    updated_at:string;
} */

function Home(props:any) {

    let { SignedIn } = props;


  const [count, setCount] = useState(0);
//   const [challenges, setChallenges] = useState(new Array<Challenge>());

  const user = userStore((state) => state.user);


  useEffect(() => {

    //from zustand
    
    console.log("Home has user as: ", user);
    // GetChallengesFromServer(setChallenges);

    //fetch challenges
    

    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount



  return (
    <>

    <HeaderAndFooter>
    {/* <div className='wrapper'>
        
        <NavBar/>

        <div style={{backgroundColor:"#AAA", padding:"20px"}}> */}

        <Typography variant='h3'>Join a challenge!</Typography> 

            <Challenges/>

        {/* {
            challenges.length>0&&
        (
            <div className='challenges'>
                
            {
                
            challenges.map(item =>
            {
                // return <p>hello</p>
                return <div key={item.id}>{item.name}</div>;
            })

            }
            </div>
        )
        } */}
            
            
            {/* should get and print out challenges */}
        
        {/* So need to add everything onto the homepage
        
        Nav Bar with login or profile button
        */}
       
    
    
       </HeaderAndFooter>
    </>
  )
}

export default Home
