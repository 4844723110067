// export const URL_BACKEND = 'http://localhost:5000/';
// export const URL_FRONTEND = 'http://localhost:5173/artclash/';

export const URL_BACKEND = 'https://ackmi.com:5000/';
export const URL_FRONTEND = 'https://ackmi.com/ArtClash/';

//for testing using ngrok to have a public url for testing on the phone, while being hosted locally
// export const URL_FRONTEND = 'https://ruling-goblin-lightly.ngrok-free.app/artclash/';

export const SUB_FOLDER = '/artclash/';//just for react route on server
// export const SUB_FOLDER_NO_F_SLASH = SUB_FOLDER.slice(1, SUB_FOLDER.length);