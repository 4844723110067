import * as THREE from 'three';
import { OrthographicCamera, Raycaster, Scene, Vector, Vector2 } from 'three';
import ConnectTheDots from './ConnectTheDots';
/*
Will be given 2 points, and create an array of points from the start to the end, up to a certain number of max points
will randomly given exra points, and will have to fill in from the last point to the next point
*/
export default class BrushSacing
{
    max_points:number=10;//max number of points to record before sending to shader- set to 200 in unity- MAX 224 or doesn't work!!!!!
    spacing:number;
    brush_size:number;
    last_pt:Vector2=null;
    // points:Array<Vector2>;
    connect_the_dots:ConnectTheDots;
    constructor()
    {
        this.connect_the_dots = new ConnectTheDots();

        //brush size in shader
        //float brush_size=.015;
        let brush_radius=0.015;//brush radius/ half width on shader
        this.brush_size=brush_radius*2;
        ConnectTheDots.SetSpacingPercent(2,this.brush_size);
    }
    AddPoint(pt:Vector2)
    {
        if(pt.x==-1)
            return;
        // //if this is the first point, just add to the array
        // if(this.points.length==0)
        // {
        //     this.points.push(pt);            
        // }
        // else
        // {
        // }

        //lets add the point if it is the first one, if it is the second, return the array of points
        let points:Array<Vector2>=null;
        if(this.last_pt!=null)
            points= ConnectTheDots.GetPointsV( pt, this.last_pt);
        this.last_pt=new Vector2(pt.x, pt.y);
        return points;
    }
}
