export const POST=0;
export const GET=1;

/**
 * function to allow me to do easy fetch requetss- since post needs form data, but get needs url params, this can handle both
 * @param type_of_req get or post
 * @param params what to send to the server, such as: {token: user.token, id_challenge:item.id}
 */
export async function MyFetchRequest(url:string, type_of_req:Number, params:any)
{
    let fullUrl = `${url}`;
    let formData;
    let fetch_info={}

    if(type_of_req==POST)
    {
        formData = new FormData();
        for (const key in params) 
        {
            if (params.hasOwnProperty(key)) 
            { // Check if the property belongs to the object itself (not inherited)
            //   console.log(key + ': ' + obj[key]);
                formData.append(key, params[key]);
                // formData.append('token', user.token);
                // formData.append('id_challenge', item.id);
            }
        }

          fetch_info = { 
            method: "POST", 
            // headers: {'Content-Type': 'application/json'},
            body: formData
            }
    }
    else if(type_of_req==GET)
    {
        const queryParams = new URLSearchParams(params);
        fullUrl = `${url}?${queryParams}`;
    }

    //now for the actual request

    try
    {
        let response = await fetch(fullUrl, fetch_info);
        if (!response.ok)
            throw new Error('Network response was not ok');
        
        let data = await response.json();
        console.log("data, ", data);
        return data;
    }
    catch(error) 
    {
        // Handle fetch errors
        console.error('There was a problem with the fetch operation:', error);
    }


}