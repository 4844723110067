import * as THREE from 'three';

//@ts-ignore
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'; //have to tsignore, since three js and three js types don't match up, it finds orbit controls, but thinks it's somewhere else

import { OrbitControls } from "./OrbitControlsNonBlocking.js"


/* class OrbitControls2 extends OrbitControls {
    constructor(camera, domElement) {
      super(camera, domElement);
  
      // Override event listeners
      this.addEventListener('start', this.onStart);
      this.addEventListener('end', this.onEnd);
    }
  
    onStart = (event) => {
      // Allow event propagation
      event.stopImmediatePropagation = () => {};
      event.stopPropagation = () => {};
      event.preventDefault = () => {};
    };
  
    onEnd = (event) => {
      // Allow event propagation
      event.stopImmediatePropagation = () => {};
      event.stopPropagation = () => {};
      event.preventDefault = () => {};
    };
  } */
  

export default class StartThreeJS
{
    scene:THREE.Scene;
    camera:THREE.OrthographicCamera;
    renderer:THREE.Renderer;
    canvas:HTMLElement;

    width:number;
    height:number;

    callbacks:Array<any>;

    //for delta ms/ fps
    last_time_ms = 0; 

    controls:OrbitControls;
    orbit_controls_active:boolean=false; // for knowing if it is panning/ zooming

    quad_to_keep_onscreen:THREE.Object3D=undefined;

    constructor(canvas_tag?:HTMLElement)//have to add question mark if we want it optional for typescript
    {
        console.log("canvas_tag2: ",canvas_tag)
        this.SetupScene(canvas_tag);        
        this.callbacks = new Array();
        requestAnimationFrame( this.Render.bind(this) );
    }

    SetupScene(canvas_tag?:HTMLElement)
    {

        //This is the basic scene setup
        this.scene = new THREE.Scene();
        console.log("setting 3JS background color");
        this.scene.background = new THREE.Color(.109,.109,.109);
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        //Note that we're using an orthographic camera here rather than a prespective
        this.camera = new THREE.OrthographicCamera( this.width / - 2, this.width / 2, this.height / 2, this.height / - 2, 1, 1000 );
        this.camera.position.z = 2;

        
        

        if(canvas_tag==undefined)
        {
            this.renderer = new THREE.WebGLRenderer();
            this.renderer.setSize( window.innerWidth, window.innerHeight );
            this.canvas=this.renderer.domElement;
            document.body.appendChild( this.canvas );
            
        }
        else
        {
            this.canvas=canvas_tag;
            let canvas:any = canvas_tag;//have to do this or typescript complains about canvas_tag being a tag...
            this.renderer = new THREE.WebGLRenderer({canvas});
        }

        //lets also add the orbit controls
        this.AddZoomPan();
    }
    AddZoomPan()
    {
        // Stop the browser from using its own zoom/ pan which messes up the camera in three js
        document.addEventListener('wheel', e=>e.preventDefault(), { passive: false });
        document.addEventListener('touchmove', e=>e.preventDefault(), { passive: false });
        document.addEventListener('gesturestart', e=>e.preventDefault());
        document.addEventListener('gesturechange', e=>e.preventDefault());


        this.renderer.domElement.addEventListener( 'mousedown', ()=>{console.log("on mouse down")} );
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableRotate = false; // Disable rotation
        this.controls.enableZoom = true;
        this.controls.enablePan = true;


        
        // this.controls.enabled=false;
         // Listen for start and end events to track activity
         this.controls.addEventListener('start', () => {this.orbit_controls_active=true;console.log("orbit controls active")});
         this.controls.addEventListener('end', () => {this.orbit_controls_active=false;console.log("orbit controls not active")});

          // Stop panning from taking the quad offscreen
          this.controls.addEventListener('change', this.ConstrainPan.bind(this));

    }

    /*
    keep the quad onscreen/ don't allow them to lose the quad
    */
    ConstrainPan() 
    {
        // console.log("ConstrainPan: quad", this.quad_to_keep_onscreen);
        let quad = this.quad_to_keep_onscreen;
        if(!quad)
            return;

        let controls = this.controls;
        let camera = this.camera;
        
        const boundingBox = new THREE.Box3().setFromObject(quad);
  
    // Get the corners of the bounding box in world space
    const worldCorners = [
        new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z),
        new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z),
    ];
  
    // Convert world space corners to screen space
    const screenCorners = worldCorners.map(corner => {
        const vector = corner.clone().project(this.camera);
        return new THREE.Vector2(
            (vector.x * 0.5 + 0.5) * window.innerWidth,
            (-vector.y * 0.5 + 0.5) * window.innerHeight
        );
    });

    // Check each side to see if it's offscreen and log the result
    // if (screenCorners[0].x < 0) {
    //     console.log("Left side is offscreen");
    // }
    // if (screenCorners[1].x > window.innerWidth) {
    //     console.log("Right side is offscreen");

    // }
    // if (screenCorners[0].y > window.innerHeight) {
    //     console.log("Bottom side is offscreen");
    // }
    // if (screenCorners[1].y < 0) {
    //     console.log("Top side is offscreen");
    // }
        
      }

    
    Render(time_ms) 
    {
        
        let frame_delta_ms = time_ms - this.last_time_ms; // #2 going to find the delta/difference in time between the last frame (initially 0) and the current time
        // console.log("frame_delta_ms: ",frame_delta_ms)

        let scene=this.scene;
        let camera=this.camera;
        let renderer=this.renderer;

        if (this.ResizeRendererToDisplaySize(renderer)) 
        {
            //possibly a resource for keeping it centered:
            //https://discourse.threejs.org/t/solved-how-to-resize-window-correctly-when-using-orthographiccamera/19729
            //for orthographic camera:
            //https://stackoverflow.com/questions/39373113/three-js-resize-window-not-scaling-properly

            // camera.aspect = this.canvas.clientWidth / this.canvas.clientHeight;
            const newAspect = this.canvas.clientWidth / this.canvas.clientHeight;

            // camera.left = (size * newAspect) / -2;
            // camera.right = (size * newAspect) / 2;

            //for orthographic camera resizing: 
            let w = this.canvas.clientWidth ;
            let h = this.canvas.clientHeight;
            camera.left = w / - 2 ;
            camera.right = w / 2 ;
            camera.top = h / 2 ;
            camera.bottom = h / - 2 ;

            // console.log("updated camera projection matrix");
            camera.updateProjectionMatrix();
        }

        

        this.callbacks.forEach((callback)=>{callback(frame_delta_ms);});

        renderer.render( scene, camera );


        this.last_time_ms = time_ms;

        requestAnimationFrame( this.Render.bind(this) );
    }
    ResizeRendererToDisplaySize(renderer) 
    {
        const canvas = renderer.domElement;
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) 
        {
            // console.log("need resize true!", width, height);
            //setting the size changes the renderer size- like an image blown up, it will look blurry if we just set canvas size, without setting the renderer size- changes canvas's drawingBuffer/ makes it more clear
            renderer.setSize(width, height, false);
        }
        return needResize;
    }
    AddRenderCallBack(callback:any, parent_this:any)
    {
        //have to bind, or it loses context of the class that called it....
        this.callbacks.push(callback.bind(parent_this));
        console.log("added callback!");
    }
          
}