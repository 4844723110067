import Storage from "../../filesystem/Storage";
import { HideWindows, ShowWindows } from "./Windows";
import "./Windows"; //to force it to import
import UserData from "../../UserData";
import Modal from "../Modal";
import { URL_BACKEND } from "../../../../CONSTANTS";

export function Login()
{
    console.log("going to login from button click!");
    //so should grab all the input fields (username, pwd, email)
    let user_name = (document.querySelector("#login .user_name") as HTMLInputElement).value;
    let password = (document.querySelector("#login .password") as HTMLInputElement).value;
    // let email = (document.querySelector("#login .password") as HTMLInputElement).value;

    console.log("username: "+user_name+", pwd: "+password);

    //now just need to send it to the server, and get the response
    LoginSendFormData(user_name, password);
}

function LoginSendFormData(user_name, password)
{
    //from: https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
    var formData = new FormData();

    formData.append("user_name", user_name);
    formData.append("password", password); // number 123456 is immediately converted to a string "123456"
    // formData.append("email", email); 

    // HTML file input, chosen by user
    // formData.append("userfile", fileInputElement.files[0]);

    // JavaScript file-like object
    // var content = '<a id="a"><b id="b">hey!</b></a>'; // the body of the new file...
    // var blob = new Blob([content], { type: "text/xml"});

    // formData.append("webmasterfile", blob);
    console.log("sending login info to server!");

    var request = new XMLHttpRequest();
    request.open("POST", URL_BACKEND+"users/login");
    
    request.onload = function(oEvent) 
    {
        if (request.status == 200) 
        {
            console.log("response: ", request.responseText);

            let response_txt = JSON.parse(request.responseText);
            let code = response_txt.code;
            if(parseInt(code) == 400)
            {
                alert(response_txt.errors[0]);
            }
            else
            {
                if(response_txt.msg == "success")
                {
                    let token = response_txt.token;
                    console.log("token found to be: ", token);
                    LoginSucessful(token);
                }
                //"from backend: "you have logged in successfully"
                Modal.GetInstance().ShowOneOption(JSON.parse(request.responseText).client_msg);
            }
        //     console.log("request.responseText!", request.responseText, code);
        //   console.log("Uploaded!", request);
          
        } 
        else 
        {
            console.log("Error " + request.status + " occurred when trying to upload your file.<br \/>");
        }
      };
    request.send(formData);
}
function LoginSucessful(token)
{
    HideLogin();
    HideWindows();
    
    UserData.GetInstance().Login(token);

    console.log(" token found: ", Storage.GetInstance().LoadValue("token"));
}

export function ShowLogin()
{
    console.log("show login clicked!");
    ShowWindows();

    //@ts-ignore
    HideRegister();
    (document.querySelector("#login")  as HTMLElement).style.display = "block";
}

export function HideLogin()
{
    (document.querySelector("#login")  as HTMLElement).style.display = "none";
}


//@ts-ignore
window.ShowLogin = ShowLogin;
//@ts-ignore
window.Login = Login;
//@ts-ignore
window.HideLogin = HideLogin;

