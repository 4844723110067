export default class Rect
{
    x=0;
    y=0;
    width=10;
    height=10
    constructor(x,y,width, height)
    {
        this.x=x;
        this.y=y;
        this.width=width;
        this.height=height;
    }

}