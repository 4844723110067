/**
 * File to handle the basic User Stuff- getting the user logged in from the server, etc, from App
 */

import { userStore } from "./App";
import { URL_BACKEND } from "./CONSTANTS";
import LocalStorageInterFace from "./PaintProgram/3_brush_controls/filesystem/LocalStorage";

// export interface UserDataInterface
// {
//     token:string;
//     user_name:string;
//     id:string;
// }
export class UserData
{
    
    token:string="";
    user_name:string="";
    id:string="";
    avatar:string="";
    show_nsfw:boolean=false;

    gold:string="";
    gems:string="";
    experience:string="";
    constructor(obj:any)
    {
         // Loop through the properties of the input object
        for (const key in obj) 
        {
            // Check if the current object has the property
            if (this.hasOwnProperty(key)) 
                // Copy the property from the input object to the current object
                this[key] = obj[key];
            
        }

        return this;
    }
    GetPropertyNames()
    {
        return Object.keys(this);
    }
}

export default class UserInterface
{
    // setSignedIn:any;
    signed_in:boolean=false;
    // setUserZust:(user:UserData)=>void;
    constructor()
    {
        // this.setSignedIn=setSignedIn;
        console.log("userinterface, returning this: ", this);

        // this.setUserZust= userStore((state) => state.setUser);

        return this;
    }
    
    async LoadUser():Promise<UserData>
    {
         //check if They are already logged in
        console.log("User: checking if already logged in...");



        // let vals = LocalStorageInterFace.GetInstance().LoadValues(["token", "user_name", "id"]) as any;
        let vals = LocalStorageInterFace.GetInstance().LoadValues(new UserData(null).GetPropertyNames()) as any;
        console.log("User: vals loaded from local storage are: ", vals);
        if(vals.token)
        {
            // console.log("User is logged in from local storage: vals are: ", vals);
            // this.setSignedIn(true);
        }
        else
        {
            //from google sign in- we'll see if there is a get response in the URL from google, and use that to sign in
            return await this.CheckGetResponseFromGoogle();
        }

        return vals;
    }
    SignedIn(user:UserData)
    {
        console.log("User: signed in called!", user);
        // this.setSignedIn(true);
        this.signed_in=true;

        // this.setUserZust(data);

        // LocalStorageInterFace.GetInstance().SaveValues({token:data.token, user_name:data.user_name, id:data.id})
        LocalStorageInterFace.GetInstance().SaveValues(user)

    }

    /**
     * Delete user from local storage
     */
    static SignOut()
    {
        LocalStorageInterFace.GetInstance().DeleteValues(new UserData(null).GetPropertyNames());
    }





    // server = "http://localhost:5000";
    server = URL_BACKEND;
    //create a function that gets called when this component gets mounted
//handles get response from google- which gets placed into the url after we login
private async CheckGetResponseFromGoogle()
{
  var fragment = window.location.hash.substring(1); // Exclude leading '#'
  console.log("fragment: ",fragment);
  if(!fragment)
    return;

  var params = {};
  var pairs = fragment.split('&');
  
  for (var i = 0; i < pairs.length; i++) 
  {
      var pair = pairs[i].split('=');
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  let access_token = params["access_token"];
  console.log("access_token is: ", access_token);

  //lets send the access token to our server to create/ login the user
  return await this.SendTokenToServer(access_token);

// Print fragment parameters to console
  /* for (var key in params) 
  {
    if (params.hasOwnProperty(key)) 
    {
        console.log(key + ': ' + params[key]);
    }
  } */
}
private async SendTokenToServer(access_token)
{
    console.log("sending token to server: ", this.server+"GoogleSignIn/");
	let response = await fetch(this.server+"GoogleSignIn/", 
	{
		method: 'POST',
		headers: {
			'Accept': 'application/json',
      		'Content-Type': 'application/json',
			},
		body: JSON.stringify({ JWT:access_token, test:"some test"})
	})
	// .then(response => 
	// {
		console.log("response: ", response);
    // Handle response from the backend
    let data = await response.json();/* .then(data=>{ */
      console.log("json response from server: ", data);

      if(data.code==200) //only login if they got the go ahead
      {

        //only grab the variables specified in the user interface
        let user = new UserData(data);
        this.SignedIn(user);
        console.log("returning user back to app: ", user);

        //also wipe out the get URL that google responded with

        console.log("clearing googles response from the URL");
        const newURL = window.location.pathname + window.location.search;
        window.history.replaceState({}, document.title, newURL);


        return user;
      }
    // });
	// })
	// .catch(error => 
	// {
	// // Handle error
	// 	console.log("error: ", error);
	// });
}

}