/**
 * A specific challenge page
 * Gotte through with a link, should provide challenge info in routing/ state
 */

import { useEffect, useState } from 'react'
import styles from "./Challenges.module.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';



//@ts-ignore
import LocalStorageInterFace from './PaintProgram/3_brush_controls/filesystem/LocalStorage'
import { SUB_FOLDER, URL_BACKEND } from '../../CONSTANTS';
import ChallengeItem, { ChallengeItemInterface } from './ChallengeItem';
import HeaderAndFooter from '../Layouts/HeaderAndFooter';
import { userStore } from '../../App';
import { UserData } from '../../User';
import { GET, MyFetchRequest, POST } from '../../Helpers';
import { Link } from 'react-router-dom';
import { Button, Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { default as MuiLink } from '@mui/material/Link';
import ProjectCard from '../ProjectCard';



function AChallenge() 
{
    //from zustand
    const user = userStore((state) => state.user);

  const [count, setCount] = useState(0);
  const [challenge_items, setChallengeItems] = useState(new Array());

  const [all_entries, setAllEntries] = useState(new Array());

  const [sortby, setSortBy] = useState("newest");

//   const { item } = useParams();
    let { state } = useLocation();
    let item = state.item as ChallengeItemInterface;

    //for being able to change route to the drawing page
    const navigate = useNavigate();


    const  JoinChallenge = async(user:UserData, item:ChallengeItemInterface)=>
    {
        console.log("trying to join challenge: ", item);

        //so have to submit the challenge id, the user id/ token
        //and get the new challenge entry id back, which will be used to go to the next page/ submit an entry
        
        const url = URL_BACKEND+'challenges/join/';


        // let data = await MyFetchRequest(url, POST, {token:user.token, id_challenge:item.id});

        // const params = {
        //     // limit:'24',
        //     token: user.token,
        //     id_challenge:item.id,
        // };
        const formData = new FormData();
        formData.append('token', user.token);
        formData.append('id_challenge', item.id);

        // Construct the URL with query parameters
        // const queryParams = new URLSearchParams(params);
        // const fullUrl = `${url}?${queryParams}`;
        const fullUrl = `${url}`;

        // Perform the fetch request
        fetch(fullUrl, { 
            method: "POST", 
            // headers: {'Content-Type': 'application/json'},
        body: formData}
        )
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Handle response data
            console.log("data, ", data);

            let id_entry= data.data.id;
            let id_challenge = item.id;
            // console.log("setting gallery items to: ",data.data)

            // setChallengeItems(data.data);

            //we should go to the drawing page / route, and pass along which challenge it is, which entry it is, etc.
            
            navigate(SUB_FOLDER+'Draw', { state: { id_entry: id_entry, challenge:item } });

        })
        .catch(error => {
            // Handle fetch errors
            console.error('There was a problem with the fetch operation:', error);
        });
    }

    const GetMyEntries = async (user:UserData, item:ChallengeItemInterface)=>
    {
        console.log("Going to grab entries for challenge for this user", user, item);

        //so have to submit the challenge id, the user id/ token
        //and get the new challenge entry id back, which will be used to go to the next page/ submit an entry
        
        const url = URL_BACKEND+'challenges/getmyentries/';
        const params = {
            // limit:'24',
            token: user.token,
            id_challenge:item.id,
        };

        try{
        let data = await MyFetchRequest(url, GET, {token:user.token, id_challenge:item.id});

        console.log("my entries are: , ", data);

            let id_entry= data.data.id;
            let id_challenge = item.id;
        }
        catch(err)
        {
            console.error('There was a problem with the fetch operation:', err);
        }

        // const formData = new FormData();
        // formData.append('token', user.token);
        // formData.append('id_challenge', item.id);

        // Convert FormData to URLSearchParams
        // const urlParams = new URLSearchParams(formData);

        // Construct the URL with query parameters
      
    }
    const GetAllEntriesForChallenge = async ( item:ChallengeItemInterface)=>
    {
        console.log("Going to grab entries for challenge for this user", item);

        //so have to submit the challenge id, the user id/ token
        //and get the new challenge entry id back, which will be used to go to the next page/ submit an entry
        
        const url = URL_BACKEND+'challenges/entries/';
        const params = {
            // limit:'24',
            id_challenge:item.id,
        };

        try{
        let data = await MyFetchRequest(url, GET, {id_challenge:item.id});

            console.log("all entries are: , ", data);

            setAllEntries(data.data);

            let id_entry= data.data.id;
            let id_challenge = item.id;
        }
        catch(err)
        {
            console.error('There was a problem with the fetch operation:', err);
        }

      
    }


  useEffect(() => {
    
    console.log("item is: ", item);

    // if(!user)
    //     navigate('/', { state: { } });
    // else

    /* if(user)
        GetMyEntries(user, item); */
        GetAllEntriesForChallenge(item);
    
    }, []); // Empty array means this effect runs once on mount and clean up on unmount



  return (
    <>
    <HeaderAndFooter>
    <div>
        <h1>Challenge: {item.name}</h1>
        <p>{item.desc}</p>
        <div>{/* tags: */}

            {
                item.challenge_tags.map((tag)=>{

                    // return <button data-id={item.id} key={item.id}>{item.name}</button>;
                    return <Chip variant="outlined" label={tag.name} data-id={tag.id} key={tag.id}  onClick={(e)=>{
                        console.log("clicked");
                        // e.stopPropagation(); 
                        e.preventDefault();
                        // e.bubbles=false;

                        return false; }}
                        // sx={{"&:hover":{color:"red", borderColor}}}
                            />
                })
            }
            {/* <button>Black and White</button>
            <button>One Color</button>
            <button>Challenge</button>
            <button>Challenge{item.id}</button> */}
        </div>
        {/* <p>{JSON.stringify(item)}</p> */}
        <div style={{margin:"20px"}}>
            {/* <button style={{width:"100px", height:"100px"}} onClick={()=>{JoinChallenge(user, item)}}>Create Or Edit My Entry(s)</button> */}
            
            {/* <Link to={SUB_FOLDER+`Challenge/${item.id}/MyEntries`} state={{ item: item }}>Create Or Edit My Entry(s)</Link> */}
            <MuiLink component={Link}  to={SUB_FOLDER+`Challenge/${item.id}/MyEntries`} state={{ item: item  }}><Button variant="contained">Create Or Edit My Entry(s)</Button></MuiLink>
            

        </div>

        <div>
            List of entries:
            <br/>
            {/* <p>Sort by...</p>
            <select>
                <option>Most liked</option>
                <option>Newest</option>
            </select> */}
            <FormControl sx={{marginTop:"20px", marginBottom:"20px"}}>
            <InputLabel id="sort_by_label" >Sort By...</InputLabel>
            <Select
                labelId="sort_by_label"
                id="sort_by_dd"
                value={sortby}
                label="Sort By..."
                onChange={(value)=>{ setSortBy(value.target.value); console.log("Need to do something for when select is changed: ", value.target.value)}}
                
            >
                <MenuItem value={"most_liked"}>Most liked</MenuItem>
                <MenuItem value={"newest"}>Newest</MenuItem>

            </Select>
            </FormControl>

            <div className={styles.entries}>
                {
                    all_entries.map((project)=>{
                        // return <a href="#" className={styles.entry}><div>{JSON.stringify(item)}</div></a>;

                        //return <a href="#" className={styles.entry} key={item.id}><div><img src={URL_BACKEND+item.image.path} style={{maxWidth:"100%", maxHeight:"100%"}}/></div></a>;
                        // return <ProjectCard  key={item.id} project={{image_path:URL_BACKEND+item.image.path, title:"A title", desc:"A description", author: "an author", author_avatar_url:URL_BACKEND+item.image.path}}/>

                        return <ProjectCard 

                    

                        project={{
                            image_path:URL_BACKEND+project.image.path, 
                            title:project.name, 
                            desc:project.desc, 
                            author: "", 
                            author_avatar_url:URL_BACKEND+project.image.path}}
    
    
                            
                            data-id={project.id} 
                            key={project.id} 
                            onClick={()=>{console.log("card clicked- this could be anyones entry, so need to open it in full page to see comments, likes, or more- or edit it afterwards if it is ours!");/* OpenDrawing(user, project) */}}
                            DeleteClicked={()=>{}}  
                            >
                                                  
                        
                        </ProjectCard>;
                    })
                }
                {/* <a href="#" className={styles.entry}><div>Entry 1</div></a>
                <a href="#" className={styles.entry}><div>Entry 2</div></a>
                <a href="#" className={styles.entry}><div>Entry 3</div></a> */}
                
            </div>
        </div>
       
    </div>
    </HeaderAndFooter>
    
    
    </>


  )

  
}




export default AChallenge
