import * as THREE from 'three';
import { OrthographicCamera, Raycaster, Scene, Vector, Vector2 } from 'three';
import BrushSacing from './BrushSpacing';
import Input, {Touch} from './Input';
import StartThreeJS from '../StartThreeJS';
export default class MouseAndTouch
{
    raycaster:Raycaster;
    mouse:Vector2;
    onClickPosition:Vector2;
    mouse_pos:Vector2;

    start_three_js:StartThreeJS
    canvas:HTMLElement;
    scene:Scene;
    camera:OrthographicCamera

    mouse_down:boolean=false;

    brush_spacing:BrushSacing;

    //for connect the dots vector2 pos
    mouse_dots:Array<Vector2>;

    //stuff for paintonSurface
    mouse_down_first:boolean=false;
    mouse_down_on_quad:boolean=false;
    mouse_over_quad:boolean=false;
    mouse_over_ui:boolean=false;
    mouse_uv_coordinates:Vector2;//for knowing where we are on the texture

    //for recording a mouse run- to figure out why spacing isn't working correctly
    mouse_recorded_pos:Array<Vector2>;

    //new stuff for recording multiple fingers/ touch points
    touches:Array<Touch> = new Array<Touch>();
    touch_count=0;

    //going to use for storing the px coordinates for both mouse and touch 0
    client_pos_mouse:Vector2 = new Vector2();// just for testing/ storing the original x and y of the mouse- for being able to use for testing spacing


    // constructor(canvas:HTMLElement, scene:Scene, camera:OrthographicCamera)
    constructor(start_three_js:StartThreeJS)
    {
        // let canvas=start_three_js.canvas;
        // let scene=start_three_js.scene;
        // let camera=start_three_js.camera;
        

        for (let i = 0; i < 10; i++) 
        {
            this.touches.push(new Touch());
        }

        this.start_three_js = start_three_js;

        this.canvas=start_three_js.canvas;
        this.scene=start_three_js.scene;
        this.camera=start_three_js.camera;


        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();
        this.onClickPosition = new THREE.Vector2();    
        this.mouse_pos = new THREE.Vector2(-1,-1);
        this.mouse_uv_coordinates = new Vector2(-1,-1);

        this.brush_spacing = new BrushSacing();

        this.mouse_dots = new Array<Vector2>();

        this.mouse_recorded_pos= new Array<Vector2>();

        
        let canvas=this.canvas;
		canvas.addEventListener( 'mousedown', this.onMouseDown.bind(this) );
        canvas.addEventListener( 'mousemove', this.onMouseMove.bind(this) );
		canvas.addEventListener( 'mouseup', this.onMouseUp.bind(this) );
        
		canvas.addEventListener( 'touchstart', this.OnTouchDown.bind(this) );
        canvas.addEventListener( 'touchmove', this.OnTouchMove.bind(this) );
		// canvas.addEventListener( 'touchcancel', this.OnTouchUp.bind(this) );
        canvas.addEventListener( 'touchend', this.OnTouchUp.bind(this) );

        canvas.addEventListener( 'wheel', this.Wheel.bind(this) );
        

        // canvas.addEventListener( 'touchmove', ()=>{console.log("touchmove")} );
		// canvas.addEventListener( 'touchstart', ()=>{console.log("touchstart")} );
		// canvas.addEventListener( 'touchcancel', ()=>{console.log("touchcancel")} );

        document.addEventListener('contextmenu', event => {event.preventDefault();event.stopPropagation});
        document.oncontextmenu = (event)=>{event.preventDefault();event.stopPropagation};
    }
    OnTouchDown(event:TouchEvent)
    {
        console.log("touch down, event: ", event);

        //so if touches.length= 2, should remove the previous point
        //have to run this first
        //works- but need to have a slight pause while waiting for the 2nd touch point before we begin drawing
        // if(event.touches.length==2)
        // {
        //     //should also do mouse up/ turn off mouse move
        //     console.log("getting rid of mouse down");
        //     this.onMouseUp2(null);
        //     return;
        // }

        if(this.start_three_js.orbit_controls_active)//don't draw if panning/ zooming
            return;
        

    
        

        //touches seem complicated- not sure if the number stay consistant- might randomly change- so we'll just assume they don't for now
        for (let i = 0; i < event.changedTouches.length; i++) 
        {
            const touch = event.changedTouches[i];
            const array = this.getMousePositionPercent( this.canvas, event.changedTouches[i].clientX, event.changedTouches[0].clientY );
            const onClickPosition = new THREE.Vector2();
            onClickPosition.fromArray( array );
            this.touches[i].down=true;
            this.touches[i].position = onClickPosition;

            this.TouchCount(event);

        }


        //what we had before for just one touchpoint
        const array = this.getMousePositionPercent( this.canvas, event.changedTouches[0].clientX, event.changedTouches[0].clientY );
        const onClickPosition = new THREE.Vector2();
        onClickPosition.fromArray( array );

        this.onMouseDown2(onClickPosition);


        //for storing 1 vector2 in px on screen for mouse or touch:
        this.client_pos_mouse.x=event.changedTouches[0].clientX;
        this.client_pos_mouse.y=event.changedTouches[0].clientY;
        
        //update Input
        // Input.
    }
    OnTouchMove(event:TouchEvent)
    { //touches seem complicated- not sure if the number stay consistant- might randomly change- so we'll just assume they don't for now
        
        for (let i = 0; i < event.changedTouches.length; i++) 
        {
            const touch = event.changedTouches[i];
            const array = this.getMousePositionPercent( this.canvas, event.changedTouches[i].clientX, event.changedTouches[i].clientY );
            const onClickPosition = new THREE.Vector2();
            onClickPosition.fromArray( array );
            this.touches[i].down=true;
            this.touches[i].position = onClickPosition;

            this.TouchCount(event);
        }
        

        //what we had before for just one touchpoint
        const array = this.getMousePositionPercent( this.canvas, event.changedTouches[0].clientX, event.changedTouches[0].clientY );
        const onClickPosition = new THREE.Vector2();
        onClickPosition.fromArray( array );

        // console.log("touchmove",x,y)
        this.onMouseMove2(onClickPosition);

        //for storing 1 vector2 in px on screen for mouse or touch:
        this.client_pos_mouse.x=event.changedTouches[0].clientX;
        this.client_pos_mouse.y=event.changedTouches[0].clientY;
    }
    OnTouchUp(event:TouchEvent)
    { 
        
        
        
        //touches seem complicated- not sure if the number stay consistant- might randomly change- so we'll just assume they don't for now
        for (let i = 0; i < event.changedTouches.length; i++) 
        {
            const touch = event.changedTouches[i];
            const array = this.getMousePositionPercent( this.canvas, event.changedTouches[i].clientX, event.changedTouches[i].clientY );
            const onClickPosition = new THREE.Vector2();
            onClickPosition.fromArray( array );
            this.touches[i].down=false;
            this.touches[i].position = onClickPosition;

            this.TouchCount(event);
        }
        

        //what we had before for just one touchpoint        
        const array = this.getMousePositionPercent( this.canvas, event.changedTouches[0].clientX, event.changedTouches[0].clientY );
        const onClickPosition = new THREE.Vector2();
        onClickPosition.fromArray( array );

        this.onMouseUp2(onClickPosition);

        //for storing 1 vector2 in px on screen for mouse or touch:
        this.client_pos_mouse.x=event.changedTouches[0].clientX;
        this.client_pos_mouse.y=event.changedTouches[0].clientY;
    }
    Wheel(event:WheelEvent)
    {
        // console.log("wheel event", event);
        // let wheel_delta= event.deltaY;
        //deltaY on the browser is +/-108
        // console.log("event.deltaY", event.deltaY);

        let deltaX=0;
        let deltaY=0;
        deltaX = (event.deltaX>0)?1:-1;
        deltaY = (event.deltaY>0)?1:-1;

        Input.mouseScrollDelta = new Vector2(deltaX, deltaY);
    }
    /* 
    to call to reset wheen to 0 in update
     */
    WheelReset()
    {
        Input.mouseScrollDelta.x=0;
        Input.mouseScrollDelta.y=0;
    }

    /*
    To store touch counts- should be changed touches, or non changed ones, whatever is greater
    */
    TouchCount(event:TouchEvent)
    {
        Input.touch_count=event.changedTouches.length;
        if(event.touches.length>Input.touch_count)
            Input.touch_count=event.touches.length;

        Input.touches=this.touches;
        this.touch_count=Input.touch_count;
    }



    

    onMouseDown(event:MouseEvent)
    {
        console.log("on mouse down", event)

        if(this.start_three_js.orbit_controls_active)//don't draw if panning/ zooming
            return;
        
        //going to make it only work on mouse down 0
        if(event.button!=0)
            return;

        this.client_pos_mouse.x=event.clientX;
        this.client_pos_mouse.y=event.clientY;

        //set input mouse button down
        Input.MouseBtnDownJS(event.button, true);
        
        

        console.log("1mouse down at: ", event.clientX, event.clientY, "btn: ", event.button);
        const array = this.getMousePositionPercent( this.canvas, event.clientX, event.clientY );
        // console.log("2mouse down at: ", this.getMousePositionFromTL( this.canvas, event.clientX, event.clientY ));
        const onClickPosition = new THREE.Vector2();
        onClickPosition.fromArray( array );

        this.onMouseDown2(onClickPosition);

        //Let UI Know drawing, so it can close color picker:
        //@ts-ignore
        if(window.DrawingOnCanvas)
        //@ts-ignore
            window.DrawingOnCanvas()
    }
    onMouseMove(event:MouseEvent)
    {
        this.client_pos_mouse.x=event.clientX;
        this.client_pos_mouse.y=event.clientY;

        //set input mouse button down
        Input.MouseBtnDownJS(event.button, false);


        const array = this.getMousePositionPercent( this.canvas, event.clientX, event.clientY );
        const onClickPosition = new THREE.Vector2();
        onClickPosition.fromArray( array );

        this.onMouseMove2(onClickPosition);
    }
    onMouseUp(event:MouseEvent)
    {
        this.client_pos_mouse.x=event.clientX;
        this.client_pos_mouse.y=event.clientY;

        const array = this.getMousePositionPercent( this.canvas, event.clientX, event.clientY );
        const onClickPosition = new THREE.Vector2();
        onClickPosition.fromArray( array );
        this.onMouseUp2(onClickPosition);
    }


    onMouseDown2(mouse_pos:Vector2)
    {
        console.log("mouse down at: ",mouse_pos);
        const intersects = this.getIntersects( mouse_pos, this.scene.children );
        if ( intersects.length > 0 && intersects[ 0 ].uv ) 
        {
            console.log("mouse down", intersects[ 0 ])
            this.mouse_down=true;

            this.mouse_down_first=true;
            this.mouse_down_on_quad=true;

            

            this.onMouseMove2(mouse_pos);
        }
    }
    onMouseUp2(mouse_pos:Vector2|null)
    {
        console.log("mouse up at", mouse_pos);


        // console.log("mouse recorded points: ")
        // let str = "let mouse_pts:Array<Vector2> = [";
        // this.mouse_recorded_pos.forEach( (ele)=>{str+="new Vector2("+ele.x+", "+ele.y+"), "});

        // console.log(str+"];")
        // this.mouse_recorded_pos = [];
        

        // const intersects = this.getIntersects( mouse_pos, this.scene.children );
        
        // if ( intersects.length > 0 && intersects[ 0 ].uv ) 
        // {

        //     const uv = intersects[ 0 ].uv;
        //     // console.log("interesects: ", uv)
        //     this.mouse_pos.x=uv.x;
        //     this.mouse_pos.y=uv.y;

        //     console.log("mouse up adding point", this.mouse_pos);

        //     //for dots: 
        //     let points = this.brush_spacing.AddPoint(this.mouse_pos);
        //     if(points!=null)
        //     {
        //         this.mouse_dots=points;
        //         console.log("mouse dots: ",this.mouse_dots );
        //     }

        //     // intersects[ 0 ].object.material.map.transformUv( uv );
        //     // canvas.setCrossPosition( uv.x, uv.y );
        // }
        

        this.mouse_pos.x=-1;
        this.mouse_pos.y=-1;

        this.mouse_down=false;
        this.mouse_down_on_quad=false;
        this.mouse_down_first=false;
        this.mouse_over_quad=false;
        
    }

    //from https://github.com/mrdoob/three.js/blob/master/examples/webgl_raycast_texture.html
    onMouseMove2(mouse_pos:Vector2)
    {

        //X: setting Input.mouse position based on the position in the UV, not the absolute mouse position- but might have to change?
        //right now just setting it to absolute mouse position when moved
        Input.mousePosition = mouse_pos;

        if(!this.mouse_down)
            return;
        // console.log("on mouse move, mouse pos: ",mouse_pos);
        

        const intersects = this.getIntersects( mouse_pos, this.scene.children );
        this.mouse_pos.x=-1;
        this.mouse_pos.y=-1;
        if ( intersects.length > 0 && intersects[ 0 ].uv ) 
        {
            const uv = intersects[ 0 ].uv;
            // console.log("interesects: ", uv)
            this.mouse_pos.x=uv.x;
            this.mouse_pos.y=uv.y;

            this.mouse_uv_coordinates.x=uv.x;
            this.mouse_uv_coordinates.y=uv.y;

            // console.log("Mouse UV coordinates: "+this.mouse_uv_coordinates.x+", "+this.mouse_uv_coordinates.y)

            this.mouse_over_quad=true;

            //FOR TESTING MOUSE DEBUG POINTS
            // this.mouse_recorded_pos.push(new Vector2(this.client_pos_mouse.x, this.client_pos_mouse.y));

            // intersects[ 0 ].object.material.map.transformUv( uv );
            // canvas.setCrossPosition( uv.x, uv.y );
        }

        
    }
    getMousePositionPercent( dom, x, y ) 
    {
        const rect = dom.getBoundingClientRect();
        return [ ( x - rect.left ) / rect.width, ( y - rect.top ) / rect.height ];
    }
    getMousePositionFromTL( dom, x, y ) 
    {
        const rect = dom.getBoundingClientRect();
        return  new Vector2(( x - rect.left ) , ( y - rect.top ))  ;
    }
    getIntersects( point, objects ) 
    {
        this.mouse.set( ( point.x * 2 ) - 1, - ( point.y * 2 ) + 1 );
        this.raycaster.setFromCamera( this.mouse, this.camera );

        return this.raycaster.intersectObjects( objects );
    }
    //just to run at the end of everything- to get rid of mouse down first
    Update()
    {
        this.mouse_down_first=false;
        
        this.WheelReset();
    }


    //for outside functions- for ZoomPanGeneral to use to get mouse pos info:
    GetMouseorTouchX()
    {
        return this.mouse.x;
    }
    GetMouseorTouchY()
    {
        return this.mouse.y;
    }

    GetDistanceInInches(vec1:Vector2, vec2:Vector2)
    {
        const dpi = window.devicePixelRatio * 96;
        let dist = vec1.distanceTo(vec2);
        let inches = dist/dpi;
        console.log("distance (px): ", dist, "dist inches: ", inches);
        return inches;
    }
}